import React, { useEffect, useState } from "react";
// Customizable Area Start
import { Box, Typography, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  workSheetIcon,
  playIcon,
  playWhiteIcon,
  documentsIcon
} from "../assets";
import Skeleton from '@material-ui/lab/Skeleton';

const configJSON = require('../config')
const config = require('../../../../framework/src/config')
// Customizable Area End

// Customizable Area Start
interface Attributes {
  title: string;
  trainer_name: string;
  uploaded_on: any;
  duration: string;
  attachment_type: string;
  thumbnail_url: string;
  attachment_url: string;
}
interface ListItemProps {
  attributes: Attributes;
  goTo: (pathBlock: string, params: any) => void;
  courseId?: number;
  sessionId?: number;
  id: any;
}

const useStyles = makeStyles<Theme, ListItemProps>((theme) => ({
  ListItemCard: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    border: "0.2px solid #e3e3e3",
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    borderRadius: "8px",
    cursor: "pointer",
    padding: "8px 13px",
    marginBottom: "13px",
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: "0 0 10px 0 ",
      marginBottom: "30px",
    }
  },
  ListItemImgWrapper: {
    height: props => `${props.attributes && props.attributes.attachment_type === 'video' ? "72px" : " 90px"}`,
    width: props => `${props.attributes && props.attributes.attachment_type === 'video' ? "162px" : " 90px"}`,
    marginRight: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '30px',
      marginRight: '0',
      height: props => `${props.attributes && props.attributes.attachment_type === 'video' ? "100%" : "90px"}`,
      width: props => `${props.attributes && props.attributes.attachment_type === 'video' ? "100%" : "90px"}`,
    }
  },
  thumbnailImage: {
    height: props => `${props.attributes && props.attributes.attachment_type === 'video' ? "72px" : "auto"}`,
    width: props => `${props.attributes && props.attributes.attachment_type === 'video' ? "162px" : "auto"}`,
    borderRadius: '8px',
    [theme.breakpoints.down('xs')]: {
      height: props => `${props.attributes && props.attributes.attachment_type === 'video' ? "100%" : "auto"}`,
      width: props => `${props.attributes && props.attributes.attachment_type === 'video' ? "100%" : "auto"}`,
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px'
    }
  },
  VideoPlayIcon: {
    position: 'absolute',
    top: '50%',
    bottom: '50%',
    width: '20px',
    transform: 'translate(-2%, -50%)'
  },
  ListItemCardTitle: {
    fontWeight: 800,
    fontSize: "18px",
    lineHeight: "21px",
    color: "#0056A3",
    fontFamily: "Poppins",
    marginTop: "-18px",
    marginBottom: "10px",
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  ListItemCardSubtitle: {
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "10px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
    opacity: 0.64,
    marginBottom: "5px",
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  cardContentWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  actionIcon: {
    marginLeft: "auto",
    marginRight: "8px",
    "& img": {
      width: "20px"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  videoHide: {
    display: 'none'
  }
}
));

const ListItem = (props: ListItemProps) => {
  const { attributes } = props;
  const classes = useStyles(props);
  const attachmentType = attributes?.attachment_type;
  let url = attributes?.attachment_url;

  const onClick = () => {
    if (attachmentType === "video") {
        props.goTo(configJSON.MindMasteryTexts.VideoPageMindMastery, {
            courseId: props.courseId,
            sessionId: props.sessionId,
            videoId: props.id
        });
    }
    else if (attachmentType === "worksheet") {
        if (url.includes('.docx')) {
            const newTab = window.open(
              `https://docs.google.com/viewer?url=${encodeURIComponent(url)}`,
              "_self"
            );
            newTab?.focus();
        }
        else {
            window.open(url, "_blank");
        }
    }
    else {
        if (url.includes('.docx')) {
            const docUrl = `http://view.officeapps.live.com/op/embed.aspx?src=${url}`;
            const newTab = window.open(
                `/MaterialDetails?pdfUrl=${encodeURIComponent(
                    docUrl
                )}`,
                "_self"
            );
            newTab?.focus();
        }
        else {
            const newTab = window.open(
                `/MaterialDetails?pdfUrl=${url
                }`
                ,
                "_self"
            );
            newTab?.focus();
        }
    }
};

  const renderThumbnail = (attachmentType: string) => {
    switch (attachmentType) {
      case configJSON.MindMasteryTexts.IceBreaker:
        return documentsIcon;
      case configJSON.MindMasteryTexts.WorkSheet:
        return workSheetIcon;
      default:
        return attributes?.thumbnail_url;
    }
  };

  const [duration, setDuration] = useState('');
  useEffect(() => {
    const videoElement = document.querySelector('video') as HTMLVideoElement;
    videoElement.addEventListener('loadedmetadata', () => {
      const formattedDuration = configJSON.formatDuration(videoElement.duration);
      setDuration(formattedDuration)
    });
  }, [])

  return (
    <Box
      className={classes.ListItemCard}
      data-test-id="list-item"
      onClick={onClick}
    >
      <video src={attributes.attachment_url} className={classes.videoHide}></video>
      <Box className={classes.ListItemImgWrapper}>
        {
          <img
            className={classes.thumbnailImage}
            src={renderThumbnail(attributes?.attachment_type)}
            alt="thumb nail"
          />
        }
        {attributes?.attachment_type === configJSON.MindMasteryTexts.Video && (
          <img className={classes.VideoPlayIcon} src={playWhiteIcon} />
        )}
      </Box>
      <Box className={classes.cardContentWrapper}>
        <Typography className={classes.ListItemCardTitle}>
          {attributes?.title}
        </Typography>
        <Typography className={classes.ListItemCardSubtitle}>
          By {attributes?.trainer_name} |{" "}
          {attributes?.uploaded_on}
        </Typography>
        <Typography className={classes.ListItemCardSubtitle}>
          {duration || (attributes?.attachment_type === configJSON.MindMasteryTexts.Video && <Skeleton />)}
        </Typography>
      </Box>
      <Box className={classes.actionIcon}>
        <img
          src={
            attributes?.attachment_type === configJSON.MindMasteryTexts.Video
              ? playIcon
              : workSheetIcon
          }
        />
      </Box>
    </Box>
  );
};
// Customizable Area End
export default ListItem;

// Customizable Area Start
// Customizable Area End