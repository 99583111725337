import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
// Customizable Area Start
import { CometChatUserList } from "../../Users";
import { CometChatGroupList } from "../../Groups";

import { CometChatConversationList } from "../../Chats";

import * as enums from "../../../util/enums.js";
import { CometChatContext } from "../../../util/CometChatContext";
import Translator from "../../../resources/localization/translator";

import { theme } from "../../../resources/theme";
import tabs from "../../../resources/tabs.json";
import { Avatar } from "@material-ui/core"

import {
	footerStyle,
	navbarStyle,
	itemStyle,
	navFooterOption,
} from "./style";
// Customizable Area End
export class CometChatNavBar extends React.Component {
	// Customizable Area Start
	static contextType = CometChatContext;
	tabListKeys = [];
	// Customizable Area End
	constructor(props) {
		super(props);
		// Customizable Area Start
		this.state = {
			activeTab: null,
			tabList: [],
			// admin:false
		};
		// Customizable Area End
	}

	// Customizable Area Start
	componentDidMount() {

		// let search = window.location.search;
		// let params = new URLSearchParams(search);
		// let admin_id = params.get('admin_id');
		// console.log("----",admin_id)
		// console.log("----",search)

		// this.setState({admin:!!admin_id})

		let tabList = this.context.UIKitSettings.tabs;
		tabList.forEach((tabName) => {
			for (const t in tabs) {
				if (tabName === tabs[t]) {
					this.tabListKeys.push(t);
				}
			}
		});

		this.selectActiveTab()
		console.log("this.context.item.name", this.context?.item?.name)
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.userRole !== this.props.userRole ){
			this.selectActiveTab()
		}
	}

	selectActiveTab = () => {
		this.getFilteredTabs().then((filteredTabs) => {
			if (this.props.userRole === 'Trainer') {
				this.setState({ tabList: filteredTabs, activeTab: filteredTabs[0] });
			} else if (this.props.userRole === 'Admin') {
				this.setState({ tabList: filteredTabs, activeTab: filteredTabs[2] });
			}else{
				if(this.props.isAdmin){
					this.setState({ tabList: filteredTabs, activeTab: filteredTabs[2] });
				}else{
				this.setState({ tabList: filteredTabs, activeTab: filteredTabs[1] });
						}		}
		});
	}

	getFilteredTabs = () => {
		return new Promise((resolve) => {
			const filteredTabs = [];
			const promises = [
				this.enableChats(),
				this.enableUsers(),
				this.enableGroups(),
				this.enableSettings(),
			];
			Promise.allSettled(promises).then((results) => {
				this.tabListKeys.forEach((eachTabKey) => {
					results.forEach((result) => {
						const tabKey = result.value[0];
						const tabEnabled = result.value[1];

						if (eachTabKey === tabKey && tabEnabled === true) {
							filteredTabs.push(eachTabKey);
						}
					});
				});
				resolve(filteredTabs);
			});
		});
	};

	enableChats = () => {
		return new Promise((resolve) => {
			this.context.FeatureRestriction.isRecentChatListEnabled()
				.then((response) => resolve(["SIDEBAR_CHATS", response]))
				.catch((error) => resolve(["SIDEBAR_CHATS", false]));
		});
	};

	enableUsers = () => {
		return new Promise((resolve) => {
			this.context.FeatureRestriction.isUserListEnabled()
				.then((response) => resolve(["SIDEBAR_USERS", response]))
				.catch((error) => resolve(["SIDEBAR_USERS", false]));
		});
	};

	enableGroups = () => {
		return new Promise((resolve) => {
			this.context.FeatureRestriction.isGroupListEnabled()
				.then((response) => resolve(["SIDEBAR_GROUPS", response]))
				.catch((error) => resolve(["SIDEBAR_GROUPS", false]));
		});
	};

	enableSettings = () => {
		return new Promise((resolve) => {
			this.context.FeatureRestriction.isUserSettingsEnabled()
				.then((response) => resolve(["SIDEBAR_MOREINFO", response]))
				.catch((error) => resolve(["SIDEBAR_MOREINFO", false]));
		});
	};

	tabChanged = (tab) => {
		this.setState({ activeTab: tab });
	};

	getActiveTab = () => {
		switch (this.state.activeTab) {
			case "SIDEBAR_USERS":
				return (
					<CometChatUserList
						clearCount={this.props.clearCount}
						clearUnreadMessage={this.props.clearUnreadMessage}
						selectUserForChat={this.props.selectUserForChat}
						selectedUser={this.props.selectedUser}
						theme={this.props.theme}
						lang={this.context.language}
						_parent='unified'
						actionGenerated={this.props.actionGenerated}
						onItemClick={(item, type) =>
							this.props.actionGenerated(
								enums.ACTIONS["ITEM_CLICKED"],
								type,
								item
							)
						}
					/>
				);
			case "SIDEBAR_CALLS":
				return null;
			case "SIDEBAR_CHATS":
				return (
					<CometChatConversationList
						selectUserForChat={this.props.selectUserForChat}
						theme={this.props.theme}
						lang={this.context.language}
						_parent='unified'
						actionGenerated={this.props.actionGenerated}
						onItemClick={(item, type) =>
							this.props.actionGenerated(
								enums.ACTIONS["ITEM_CLICKED"],
								type,
								item
							)
						}
					/>
				);
			case "SIDEBAR_GROUPS":
				return (
					<CometChatGroupList
						clearCount={this.props.clearCount}
						clearUnreadMessage={this.props.clearUnreadMessage}
						selectUserForChat={this.props.selectUserForChat}
						theme={this.props.theme}
						lang={this.context.language}
						_parent='unified'
						actionGenerated={this.props.actionGenerated}
						onItemClick={(item, type) => 
							this.props.actionGenerated(
								enums.ACTIONS["ITEM_CLICKED"],
								type,
								item
							)
						}
					/>
				);
			// case "SIDEBAR_MOREINFO":
			// 	return (
			// 		<CometChatUserProfile
			// 			theme={this.props.theme}
			// 			lang={this.context.language}
			// 			onItemClick={(item, type) =>
			// 				this.props.actionGenerated(
			// 					enums.ACTIONS["ITEM_CLICKED"],
			// 					type,
			// 					item
			// 				)
			// 			}
			// 		/>
			// 	);
			default:
				return null;
		}
	};

	getTabList = () => {
		const chatsTabActive =
			this.state.activeTab === "SIDEBAR_CHATS" ? true : false;
		const userTabActive =
			this.state.activeTab === "SIDEBAR_USERS" ? true : false;
		const groupsTabActive =
			this.state.activeTab === "SIDEBAR_GROUPS" ? true : false;
		const moreTabActive =
			this.state.activeTab === "SIDEBAR_MOREINFO" ? true : false;

		const tabList = [...this.state.tabList];

		return tabList.map((tab) => {
			switch (tab) {
				case "SIDEBAR_CHATS":
					return (
						<div
							key={tab}
							// css={itemStyle(this.props)}
							// className='navbar__item'
							onClick={() => this.tabChanged("SIDEBAR_CHATS")}
						>
							{/* <div
								css={itemLinkStyle(chatGreyIcon, chatsTabActive, this.context)}
								className='item__link item__link__chats'
								title={Translator.translate("CHATS", this.context.language)}
							></div> */}
							<div
							// css={itemLinkTextStyle(chatsTabActive, this.context)}
							// className='item__label'
							>
							</div>
						</div>
					);
				case "SIDEBAR_USERS":
					return (
						<div
							key={tab}
							// css={itemStyle(this.props)}
							// className='navbar__item'
							onClick={() => this.tabChanged("SIDEBAR_USERS")}
						>
							{/* <div
								css={itemLinkStyle(
									contactGreyIcon,
									userTabActive,
									this.context
								)}
								className='item__link item__link__contacts'
								title={Translator.translate("USERS", this.context.language)}
							></div> */}
							{/* <div
								css={itemLinkTextStyle(userTabActive, this.context)}
								className='item__label'
							>
								{Translator.translate("USERS", this.context.language)}
							</div> */}
						</div>
					);
				case "SIDEBAR_GROUPS":
					return (
						<div
							key={tab}
							css={itemStyle(this.props)}
							className='navbar__item'
							onClick={() => this.tabChanged("SIDEBAR_GROUPS")}
						>
							{/* <div
								css={itemLinkStyle(
									groupGreyIcon,
									groupsTabActive,
									this.context
								)}
								className='item__link item__link__groups'
								title={Translator.translate("GROUPS", this.context.language)}
							></div> */}
							{/* <div
								css={itemLinkTextStyle(groupsTabActive, this.context)}
								className='item__label'
							>
								{Translator.translate("GROUPS", this.context.language)}
							</div> */}
						</div>
					);
				case "SIDEBAR_MOREINFO":
					return (
						<div
							key={tab}
							// css={itemStyle(this.props)}
							// className='navbar__item'
							onClick={() => this.tabChanged("SIDEBAR_MOREINFO")}
						>
							{/* <div
								css={itemLinkStyle(moreGreyIcon, moreTabActive, this.context)}
								className='item__link item__link__info'
								title={Translator.translate("MORE", this.context.language)}
							></div>
							<div
								css={itemLinkTextStyle(moreTabActive, this.context)}
								className='item__label'
							>
								{Translator.translate("MORE", this.context.language)}
							</div> */}
						</div>
					);
				default:
					return null;
			}
		});
	};

	// Customizable Area End



	render() {
		// Customizable Area Start
		const userDetails = localStorage.getItem("userDetails")
		const userData = userDetails ? JSON.parse(userDetails) : null
		const name = this.context?.item?.name
		localStorage.setItem("currentName", JSON.stringify({ name: name }))
		return (
			<React.Fragment>
				{this.getActiveTab()}
				<div css={footerStyle()} className='sidebar__footer'>
					<div css={navbarStyle((theme))} className='footer__navbar'>
						{/* {this.getTabList()} */}
						<div css={navFooterOption((theme))}>
							<div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"15px"}}>
							<Avatar style={{ width: "35px", height: "35px", borderRadius: "50%", backgroundColor: "gray" }}>{userData?.attributes?.image_url && !this.props.isAdmin ? <img src={userData?.attributes?.image_url} /> : this.props.isAdmin ?"A": userData?.attributes?.first_name.charAt(0)}
							</Avatar>
								<div style={{ width: "10px", height: "9px", borderRadius: "50%", position: "absolute", left: "42px", top: "32px", background: "#90E688", border: "none" }}></div>
								<div>{this.props.isAdmin ? "Admin" :`${userData?.attributes?.first_name} ${userData?.attributes?.last_name}`}<div style={{ fontSize: "10px", marginTop: "3px" }}>{this.props.isAdmin?"Admin":userData?.attributes?.role}</div></div>
							</div>
							<div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
		// Customizable Area End
	}
}

// Customizable Area Start
// Specifies the default values for props:
CometChatNavBar.defaultProps = {
	theme: theme,
};

CometChatNavBar.propTypes = {
	theme: PropTypes.object,
};
// Customizable Area End
