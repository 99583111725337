import React, { useState, useEffect } from 'react';
// Customizable Area Start
import { Grid, Box, Typography, Button, IconButton, Grow, Modal, styled } from "@material-ui/core";
import Dropzone from 'react-dropzone';
import DashboardController from './DashboardController';
import PageContentLayout from "./PageContentLayout.web";
import SidebarLayout from "./SidebarLayout.web";
import { makeStyles } from "@material-ui/core/styles";
import { profile, editIcon, uploadIcon, avatar1, avatar2, avatar3, avatar4, avatar5, cross } from './assets';
import CommonTextField from './CommonComponents/CommonTextField.web';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import commonStyles from './commonStyles.web';
import CommonSnackBar from './CommonComponents/CommonSnackBar.web';
// Customizable Area End
const configJSON = require("./config.js");
// Customizable Area Start
const useStyles = makeStyles(() => ({
    imageCross: {
        height: 15,
        width: 15,
        marginTop: 10
    },
    uploadBox: {
        display: "flex",
        flexDirection: "column",
        borderColor: "#bfbfbf94",
        borderRadius: 8,
        borderStyle: "solid"
    },
    flex: {
        display: "flex"
    },
    adharImageName: {
        color: "#b2b3b3",
        paddingLeft: 24,
        marginTop: -16

    },
    imageBox: {
        padding: 22,
        width: 300,
        height: 200
    },
    fieldLabel: {
        color: '#3B3B3B',
        fontSize: '20px',
        fontFamily: 'Poppins',
        fontWeight: 600,
        marginBottom: '7px'
    },
    gridItem: {
        "&.MuiGrid-item": {
            // flexBasis: "auto"
        }
    },
    profileImg: {
        height: 120,
        width: 120,
        borderRadius: "50%"
    },
    btnGrp: {
        marginTop: 8,
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '10px'
    },
    instructionBox: {
        color: '#4F4F4F',
        padding: '3px',
        fontSize: '9px',
    },
    selectText: {
        color: '#0156A2',
        fontFamily: 'Poppins',
        fontSize: '20px',
        fontWeight: 600
    },
    imgDropZoneWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dropzoneBtn: {
        backgroundColor: '#F3F3F3',
        height: 64,
        width: 64,
        marginLeft: 20,
        borderRadius: '8px',
        background: '#F4F4F4',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)'
    },
    avatarList: {
        display: 'flex',
        flexDirection: 'row',
        padding: '7px 14px',
        gap: 8,
        borderRadius: '8px',
        background: '#F4F4F4',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
        "@media(max-width:720px)": {
            flexWrap: 'wrap'
        }
    },
    profileImgWrapper: {
        minWidth: 168,
        minHeight: 168,
        position: 'relative',
        backgroundColor: '#F4F4F4',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
        "@media(max-width:720px)": {
            marginBottom: '20px'
        }
    },
    editIcon: {
        position: 'absolute',
        bottom: '25px',
        right: '34px',
        height: '32px',
        width: '32px',
        background: 'linear-gradient(45deg, rgba(0, 86, 163, 1), rgba(9, 83, 158, 1), rgba(0, 4, 40, 1))',
        color: 'rgb(255, 255, 255)'
    },
    menuWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
        gap: 1,
        "@media(max-width:720px)": {
            marginLeft: 0,
        }
    },
    gridProfileWrapper: {
        '&.MuiGrid-item': {
            display: 'flex',
            alignItems: 'end',
            marginBottom: '10px',
            "@media(max-width:720px)": {
                flexDirection: 'column',
                alignItems: 'start'
            }
        }
    },
    childBtnActive: {
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontSize: '14px',
            fontWeight: "500",
            lineHeight: '24px',
            color: "white",
            fontFamily: "Poppins",
        },
        "&.MuiButton-root": {
            backgroundColor: '#0056A3',
            border: '1px solid #0056A3',
            color: 'white',
            marginRight: '10px',
            marginBottom: '20px'
        }
    },
    childBtn: {
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontSize: '14px',
            fontWeight: "500",
            lineHeight: '24px',
            color: "#0056A3",
            fontFamily: "Poppins",
        },
        "&.MuiButton-root": {
            marginRight: '10px',
            border: '1px solid #0056A3',
            backgroundColor: 'white',
            marginBottom: '20px'
        }
    },
    childBtnWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '20px'
    }
}))

const CancleButtonModalStyle = styled(Box)({
    maxWidth: "50%",
    width: "calc(100% - 40px)",
    marginRight: "40px",
    backgroundColor: "#f2f8ff",
    overflowY: "scroll",
    position: "relative",
    padding: "20px",
    borderRadius: "15px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "30%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    "& .buttons": {
      marginTop: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: "40px"
    },
    "& .cancel":{
        "&.MuiButton-root": {
            height: '48px',
            width: '175px',
            borderRadius: "8px",
            border: '1px solid #0056A3',
            backgroundColor: "white",
            display: 'flex',
            '@media(max-width:575px)': {
                width: '100px'
            }
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: "500",
            lineHeight: '24px',
            color: "#0056A3",
            fontFamily: "Poppins",
            '& img': {
                marginRight: '10px'
            }
        },
        '&.MuiButton-root.Mui-disabled': {
            border: '1px solid gray',
            backgroundColor: '#e3e3e3'
        }
    },
    
    "& .comfirm":{
        "&.MuiButton-root": {
            height: '48px',
            width: '175px',
            borderRadius: "8px",
            backgroundColor: "#0056A3",
            '@media(max-width:575px)': {
                width: '100px'
            }
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            color: 'white',
            fontFamily: "Poppins",
        },
        '&.MuiButton-root.Mui-disabled': {
            backgroundColor: '#0056a380'
        }
    },
  })

export const EditIcon = () => {
    return (<img style={{ width: 20, height: 20 }} src={editIcon} />)
}

const avatarList = [{ key: "avatar1", img: avatar1 }, { key: "avatar2", img: avatar2 }, { key: "avatar3", img: avatar3 }, { key: "avatar4", img: avatar4 }, { key: "avatar5", img: avatar5 }];

const cancelDisable = (props: any) => {
    return !props.tempPwd && !props.confirmPwd && !props.profileIcon
}

const submitDisable = (isAllTrue: boolean, props: any, pwdVal: boolean, isProfileSelected: boolean, checkBio: boolean) => {
    return (!isAllTrue || props.rules.notEqual !== '' || pwdVal) && !isProfileSelected && checkBio;
}

const isPassworValid = (props: any) => {
    return !(props.tempPwd && props.confirmPwd) || (props.tempPwd !== props.confirmPwd)
}

export const AddressFields = (props: any) => {
    const classes = useStyles()
    return <Grid container data-test-id='grid' spacing={2} style={{ padding: '60px 8px 0 8px' }} >
        <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
            <CommonTextField
                name={configJSON.Profile.AddressLine1}
                value={props.userDetails?.address_line1}
                disabled={true}
                label={`${configJSON.Profile.AddressLine1Ph}*`}
                placeholder={configJSON.Profile.AddressLine1Ph}
            />
        </Grid>
        <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
            <CommonTextField
                name={configJSON.Profile.AddressLine2}
                value={props.userDetails?.address_line2}
                disabled={true}
                label={`${configJSON.Profile.AddressLine2Ph}*`}
                placeholder={configJSON.Profile.AddressLine2Ph}
            />
        </Grid>
        <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
            <CommonTextField
                name={configJSON.Profile.State}
                label={`${configJSON.Profile.StatePh}*`}
                value={props.userDetails?.state}
                disabled={true}
                placeholder={configJSON.Profile.StatePh}
            />
        </Grid>
        <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
            <CommonTextField
                name={configJSON.Profile.City}
                label={`${configJSON.Profile.CityPh}*`}
                value={props.userDetails?.city}
                disabled={true}
                placeholder={configJSON.Profile.CityPh}
            />
        </Grid>
        <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
            <CommonTextField
                name={configJSON.Profile.Pincode}
                label={`${configJSON.Profile.PincodePh}*`}
                value={props.userDetails?.pincode}
                disabled={true}
                placeholder={configJSON.Profile.PincodePh}
            />
        </Grid>
        {props.role == configJSON.UserRole.Trainer &&
            <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                <CommonTextField
                    name={configJSON.Profile.Bio}
                    label={`${configJSON.Profile.BioPh}*`}
                    handleChange={props.handleChange}
                    value={props.states?.bio}
                    placeholder={configJSON.Profile.BioPh}
                />
            </Grid>}
    </Grid>
}

export const PWDFields = (props: any) => {
    const classes = useStyles();
    return <Grid container spacing={2} data-test-id='grid' style={{ padding: '0 8px' }} >
        <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
            <CommonTextField
                name={configJSON.Profile.TempPwd}
                data-test-id='pwd'
                value={props.states.tempPwd}
                handleChange={props.handlePwdChange}
                type={props.states.showPwd ? 'text' : 'password'}
                inputProps={{
                    minLength: 8,
                    maxLength: 30
                }}
                endAdornmentData={<IconButton
                    aria-label="toggle password visibility"
                    onClick={() => props.handleShowPwd('pwd')}
                    edge="end"
                    data-test-id="btneye1"
                >
                    {props.states.showPwd ? (
                        <VisibilityOutlinedIcon />
                    ) : (
                        <VisibilityOffOutlinedIcon />
                    )}
                </IconButton>
                }
                label={`${configJSON.Profile.Password}*`}
                placeholder={configJSON.Profile.Password}
                mb='2px'
            />

            <Box className={classes.instructionBox}>
                At least <text style={{ color: props.states.rules.length ? '#0056A3' : 'inherit' }}>8 character long</text>, <text style={{ color: props.states.rules.upper ? '#0056A3' : 'inherit' }}>one uppercase letter</text>, <text style={{ color: props.states.rules.lower ? '#0056A3' : 'inherit' }}>one lowercase letter</text>, <text style={{ color: props.states.rules.number ? '#0056A3' : 'inherit' }}>one digit</text> & <text style={{ color: props.states.rules.specialChar ? '#0056A3' : 'inherit' }}>one special character</text>
            </Box>
        </Grid>
        <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
            <CommonTextField
                name={configJSON.Profile.ConfirmPwd}
                data-test-id='comfirmPwd'
                value={props.states.confirmPwd}
                handleChange={props.handlePwdChange}
                error={Boolean(props.states.rules.notEqual)}
                helperText={props.states.rules.notEqual}
                type={props.states.showConfPwd ? 'text' : 'password'}
                inputProps={{
                    minLength: 8,
                    maxLength: 30
                }}
                endAdornmentData={<IconButton
                    aria-label="toggle password visibility"
                    onClick={() => props.handleShowPwd('conf')}
                    edge="end"
                    data-test-id="btneye2"
                >
                    {props.states.showConfPwd ? (
                        <VisibilityOutlinedIcon />
                    ) : (
                        <VisibilityOffOutlinedIcon />
                    )}
                </IconButton>
                }
                label={`${configJSON.Profile.ConfirmPassword}*`}
                placeholder={configJSON.Profile.ConfirmPassword}
            />
        </Grid>
    </Grid>
}

export const ChildrensData = (props: any) => {
    const classes = useStyles()
    const childrens = props.userDetails?.childrens;
    return <>
        {childrens.length > 0 && <>
            <Grid item className={classes.childBtnWrapper} sm={12} xs={12} md={12} lg={12} xl={12}>
                {childrens.map((element: any, index: number) => {
                    return <Button
                        key={element}
                        data-test-id='childBtn'
                        onClick={() => props.onChildClick(index + 1)}
                        className={index === (props.states.page - 1) ? classes.childBtnActive : classes.childBtn}>
                        {childrens[index]?.attributes?.name}
                    </Button>
                })}
            </Grid>
            <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={12} xl={12} >
                <CommonTextField
                    name={configJSON.Profile.ChildEmail}
                    value={childrens[props.states.page - 1]?.attributes?.email}
                    disabled={true}
                    label={`${configJSON.Profile.ChildEmailPh}*`}
                    placeholder={configJSON.Profile.ChildEmailPh}
                />
            </Grid>
            <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                <CommonTextField
                    name={configJSON.Profile.SchoolName}
                    value={childrens[props.states.page - 1]?.attributes?.school_name}
                    disabled={true}
                    label={`${configJSON.Profile.SchoolName}*`}
                    placeholder={configJSON.Profile.SchoolName}
                />
            </Grid>
            <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                <CommonTextField
                    name={configJSON.Profile.Class}
                    value={childrens[props.states.page - 1]?.attributes?.school_class}
                    disabled={true}
                    label={`${configJSON.Profile.Section}*`}
                    placeholder={configJSON.Profile.ChildsClass}
                />
            </Grid>
        </>}
    </>
}

export const ProfilePage = (props: any) => {
    const classes = useStyles();
    const commonClasses = commonStyles({});
    const profileImageUrl = props?.userDetails?.image_url || profile;
    const profileImg = profileImageUrl;
    const [editingImage, setEditingImage] = useState<boolean>(false);
    const [profilePic, setProfilePic] = useState<string>(profileImg);

    useEffect(() => {
        if (profileImageUrl) {
            setProfilePic(profileImageUrl);
        }
    }, [profileImageUrl]);

    const handleUploadProfilePhoto = async (event: any) => {
        const file = event[0];
        props.handleSetProfileIcon(file)
        const url = URL.createObjectURL(file);
        setProfilePic(url);
    }

    const handleFieldCancel = () => {
        setProfilePic(profileImageUrl);
        props.handleCancel()
    }

    const isAllTrue = Object.values(props.states.rules).every((value) => value === true || value === '');
    const pwdVal = isPassworValid(props.states)
    const isProfileSelected = Boolean((props.states.profileIcon || props.states.avatarKey) && (props.states.tempPwd === '' && props.states.confirmPwd === ''))
    const checkBio = props.userDetails?.bio == props.states.bio
    const disableSubmit = submitDisable(isAllTrue, props.states, pwdVal, isProfileSelected, checkBio);
    const disableCancel = cancelDisable(props.states);
    const role = props.userDetails?.role;
    return (
        <Grid style={{ height: 'fit-content', padding: 24 }} data-test-id='grid' spacing={2} container>
            <Grid item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.gridProfileWrapper}
            >
                <Box className={classes.profileImgWrapper}>
                    <img className={classes.profileImg} src={profilePic} />
                    <IconButton
                        onClick={() => setEditingImage(!editingImage)}
                        className={classes.editIcon}
                        aria-label="edit profile photo"
                        edge="end"
                        data-test-id='edit-profile'
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
                {editingImage && <Grow data-test-id='grow' in={editingImage}>
                    <Box className={classes.menuWrapper}>
                    <Typography variant='body1' className={classes.selectText}>Upload your image</Typography>
                        <Box className={classes.imgDropZoneWrapper}>
                            <Dropzone data-test-id='drop-zone' onDrop={handleUploadProfilePhoto} accept={{
                                'image/jpeg': [],
                                'image/png': []
                            }} >
                                {({ getRootProps, getInputProps }) => (
                                    <span {...getRootProps()}>
                                        <Button className={classes.dropzoneBtn} data-test-id='drop' onClick={() => setEditingImage(!editingImage)} >
                                            <img style={{ height: 45, width: 45 }} src={uploadIcon} />
                                        </Button>
                                    </span>
                                )}
                            </Dropzone>
                        </Box>
                    </Box>
                </Grow>}
            </Grid>
            <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                <CommonTextField
                    name={configJSON.Profile.FirstName}
                    value={props.userDetails?.first_name}
                    disabled={true}
                    label={`${configJSON.Profile.FirstNamePh}*`}
                    placeholder={configJSON.Profile.FirstNamePh}
                    mb='14px'
                />
            </Grid>
            <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                <CommonTextField
                    name={configJSON.Profile.LastName}
                    value={props.userDetails?.last_name}
                    disabled={true}
                    label={`${configJSON.Profile.LastNamePh}*`}
                    placeholder={configJSON.Profile.LastNamePh}
                    mb='14px'
                />
            </Grid>
            {role !== configJSON.UserRole.School && <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                <CommonTextField
                    name={configJSON.Profile.DOB}
                    value={props.userDetails?.birthday}
                    disabled={true}
                    label={`${configJSON.Profile.DateofBirth}*`}
                    placeholder={configJSON.Profile.DateofBirth}
                    mb='14px'
                />
            </Grid>}
            {(role !== configJSON.UserRole.School && role !== configJSON.UserRole.Parents) && <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                <CommonTextField
                    name={configJSON.Profile.Gender}
                    value={props.userDetails?.gender}
                    disabled={true}
                    label={`${configJSON.Profile.GenderPh}*`}
                    placeholder={configJSON.Profile.GenderPh}
                    mb='14px'
                />
            </Grid>}
            <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                <CommonTextField
                    name={configJSON.Profile.Email}
                    value={props.userDetails?.email}
                    disabled={true}
                    label={`${configJSON.Profile.EmailID}*`}
                    placeholder={configJSON.Profile.EmailID}
                    mb='14px'
                />
            </Grid>
            {role == configJSON.UserRole.Student &&
                <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                    <CommonTextField
                        name={configJSON.Profile.ParentEmail}
                        value={props.userDetails?.parents_email}
                        disabled={true}
                        label={`${configJSON.Profile.ParentEmailID}*`}
                        placeholder={configJSON.Profile.ParentEmailID}
                        mb='14px'
                    />
                </Grid>}

            {role != configJSON.UserRole.Student &&
                <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                    <CommonTextField
                        name={configJSON.Profile.MobileNumber}
                        value={props.userDetails?.mobile_number}
                        disabled={true}
                        label={`${configJSON.Profile.MobileNumberPh}*`}
                        placeholder={configJSON.Profile.MobileNumberPh}
                        mb='14px'
                    />
                </Grid>}

            {role === configJSON.UserRole.Parents &&
                <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                    <CommonTextField
                        name={configJSON.Profile.City}
                        value={props.userDetails?.city}
                        disabled={true}
                        label={`${configJSON.Profile.CityPh}*`}
                        placeholder={configJSON.Profile.CityPh}
                    />
                </Grid>}

            {(role == configJSON.UserRole.Student || role == configJSON.UserRole.School) &&
                <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                    <CommonTextField
                        name={configJSON.Profile.School}
                        value={props.userDetails?.school_name}
                        disabled={true}
                        label={`${configJSON.Profile.SchoolNamePh}*`}
                        placeholder={configJSON.Profile.School}
                        mb='14px'
                    />
                </Grid>}
            {role === configJSON.UserRole.Student && <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={6} xl={6} >
                <CommonTextField
                    name={configJSON.Profile.Class}
                    value={props.userDetails?.school_class}
                    disabled={true}
                    label={`${configJSON.Profile.ClassPh}*`}
                    placeholder={configJSON.Profile.ClassPh}
                    mb='14px'
                />
            </Grid>}

            <PWDFields data-test-id='pwd' userDetails={props.userDetails} handleShowPwd={props.handleShowPwd} states={props.states} handlePwdChange={props.handlePwdChange} />
            {(role !== configJSON.UserRole.Student && role !== configJSON.UserRole.Parents) && < AddressFields role={role} handleChange={props.handleChange} states={props.states} userDetails={props.userDetails} />}
            {role == configJSON.UserRole.Trainer &&
                <>
                    <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={12} xl={12} >
                        <CommonTextField
                            name={configJSON.Profile.AAdhaar}
                            value={props.userDetails?.aadhar_card_number}
                            disabled={true}
                            label={`${configJSON.Profile.AdharCardNo}*`}
                            placeholder={configJSON.Profile.AdharCardNo}
                            mb='14px'
                        />
                    </Grid>
                    <Grid item className={classes.gridItem} sm={12} xs={12} md={12} lg={12} xl={12} >
                        <Typography className={classes.fieldLabel}>{"Uploaded Document"}</Typography>
                        <div className={classes.uploadBox}>
                            <div className={classes.flex}>
                                <img className={classes.imageBox} src={props.userDetails?.aadhar_photo_url}></img>
                                <img className={classes.imageCross} src={cross} />
                            </div>
                            <p className={classes.adharImageName}>{props.userDetails?.aadhar_card_file_name}</p>
                        </div>
                    </Grid>
                </>
            }
            {role === configJSON.UserRole.Parents && <ChildrensData userDetails={props.userDetails} onChildClick={props.onChildClick} states={props.states} />}
            <Grid
                item
                sm={12}
                xs={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.btnGrp}
            >
                <Button className={commonClasses.secondaryButton} data-test-id='cancel' onClick={handleFieldCancel} variant='outlined' color='primary'>{configJSON.ButtonTexts.Cancel}</Button>
                <Button data-test-id='submit' className={commonClasses.primaryButton} disabled={disableSubmit} onClick={props.handleSubmit} variant='contained' color='primary'>{configJSON.ButtonTexts.Save}</Button>
            </Grid>
        </Grid>
    )
}
// Customizable Area End

export default class Profile extends DashboardController {
    // Customizable Area Start
    cancleModalRendering = () => {
        return (
            <>
                <CancleButtonModalStyle>
                    <Box>{configJSON.Profile.confirmationMessage}</Box>
                    <Box className='buttons'>
                        <Button className="cancel" onClick={this.handleCancel}>{configJSON.SeekHelp.Cancel}</Button>
                        <Button className="comfirm" onClick={this.goBackCall} data-test-id="confirmTestId">{configJSON.ModalTypes.Confirm}</Button>
                    </Box>
                </CancleButtonModalStyle>
            </>
        )
    }

    async componentDidMount(): Promise<void> {
        this.getUserDetails();
        this.getUserRole()
    }

    render(): React.ReactNode {
        const profileProps = {
            userDetails: this.state.userDetails,
            handleShowPwd: this.handleShowPwd,
            handleSetProfileIcon: this.handleSetProfileState,
            states: this.state,
            handlePwdChange: this.handlePwdChange,
            handleCancel: this.handleCancel,
            handleSubmit: this.updateUserProfile,
            getUserRole: this.getUserRole,
            handleChange: this.handleChange,
            onChildClick: this.handlePage
        }

        return (
            <SidebarLayout {...this.props}>
                <PageContentLayout goBack={this.goBack} title={configJSON.Profile.MyProfile}>
                    <ProfilePage data-test-id='profile-page' {...profileProps} />
                    <CommonSnackBar
                        open={this.state.openSnackBar}
                        message={this.state.snackBarMessage}
                        fontWeight={600}
                        type={this.state.snackBarType}
                        onClose={() => this.setState({ openSnackBar: false, snackBarMessage: "" })}
                        duration={4000}
                        verticalPosition='top'
                        horizontalPosition='center' />
                    <Modal
                        open={this.state.openCancel}
                        onClose={this.handleModalOpen}
                    >
                        {this.cancleModalRendering()}
                    </Modal>
                </PageContentLayout>
            </SidebarLayout>
        );
    }
    // Customizable Area End
}
