import React from "react";
// Customizable Area Start
import {
  InputAdornment,
  Box,
  Button,
  TextField,
  Grid,
  Typography
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { mailVerify } from "./assets";
import CommonTextField from "../../dashboard/src/CommonComponents/CommonTextField.web";
// Customizable Area End
// Customizable Area Start
interface Props {
  handleSubmit: () => void;
  email: string;
  updateEmail: (email: string) => void;
  emailError: boolean;
}
const EmailVerify = (props: Props) => {
  const {
    errorField,
    stepHeading,
    stepPara,
    btnRight,
    checkIcon,
    stepImage,
    formBox,
    inputStyle,
    stepBtn,
    errorMessage,
    btnBox,
    emailVaild,
  } = webStyles;

  let regex = /^[\w.-]+@\w+(\.\w{2,3})+$/;
  let validEmail = false;
  if (regex.test(props.email)) {
    validEmail = true;
  }
  return (
    <Box>
      <Box style={{ textAlign: "center" }}>
        <Typography style={stepHeading}>Forgot Password ?</Typography>
        <Typography style={stepPara}>
          Enter your registered email below to receive the OTP <br />
          to reset password.
        </Typography>
      </Box>
      <Box style={stepImage}>
        <img src={mailVerify} />
      </Box>
      <Box style={formBox}>
        <Grid alignItems="center" spacing={3}>
          <Grid item md={12}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <CommonTextField
                data-test-id="txtInputEmail"
                mb={"0"}
                value={props.email}
                placeholder="Enter Email ID"
                handleChange={(e: any) => props.updateEmail(e.target.value)}
                error={props.emailError}
                helperText={props.emailError && 'Please enter your valid email'}
                startAdornment={<PersonIcon />}
              />
              <CheckCircleIcon style={validEmail ? emailVaild : checkIcon} />
            </Box>
          </Grid>
          <Grid container item md={12} justifyContent="flex-end">
            <Box style={btnBox}>
              <Box style={btnRight}>
                <Button
                  disabled={!validEmail}
                  data-test-id="btnVerifyEmail"
                  style={!validEmail ? { ...stepBtn, backgroundColor: '#0056a380' } : stepBtn }
                  fullWidth
                  onClick={props.handleSubmit}
                >
                  send
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
// Customizable Area End
export default EmailVerify;
// Customizable Area Start
const webStyles = {
  stepHeading: {
    fontSize: "40px",
    color: "#0056A3",
    fontWeight: "bold"
  },
  stepPara: {
    color: "#0056A3",
    paddingTop: "15px"
  },
  btnRight: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  checkIcon: {
    color: "#979797",
    width: "50px",
    fontSize: "33px"
  },
  emailVaild: {
    color: "#20BF55",
    width: "50px",
    fontSize: "33px"
  },
  stepImage: {
    margin: "auto",
    display: "flex",
    justifyContent: "center"
  },
  formBox: {
    maxWidth: "450px",
    margin: "auto",
    paddingTop: "30px"
  },
  inputStyle: {
    backgroundColor: "#fff"
  },
  stepBtn: {
    backgroundColor: "#0056A3",
    color: "#fff",
    borderRadius: "8px",
    width: "175px",
    textTransform: "capitalize" as const,
  },
  errorField: {
    border: "1px solid red",
    backgroundColor: "#fff",
    borderRadius: "5px"
  },
  errorMessage: {
    color: "red",
    fontSize: "small"
  },
  btnBox: {
    marginTop: "45px",
  }
};
// Customizable Area End