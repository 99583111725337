import React from "react";
// Customizable Area Start
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import UtilityInfo from "../../../../components/src/UtilityInfo.web";
const configJSON = require("../config");
import { Card, Grid, Box, Button, Select, MenuItem, Checkbox, OutlinedInput, ListItemText, styled } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import PartnershipPageController, { Props } from "./PartnershipPageController";
// Customizable Area End

const formText = configJSON.PartnershipPageContent.formText;
// Customizable Area Start
const formProgramOptions = [
  "Digital Platform",
  "Primary Years (Bud)",
  "High School (Blossom)",
  "Educators Training",
  "Parenting Workshops/Training",
  "Corporate Workshops",
  "Customized Program",
  "Other (Please specify)"
]

const formOrganisationOptions = [
  "School/Educational Institution",
  "NGO",
  "Corporate",
  "International Organisation",
  "Other (Please specify)",
]



interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
  multiLine?: string[];
}

const heading: Content = {
  headerText: formText.heading,
  fontSize: "32px",
  color: "#052B62",
  contentText: "",
  secondColor: "",
  paragraphFontSize: ""
};

const validationSchema = Yup.object({
  fullName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid name")
    .required("Full Name is required"),
  companyName: Yup.string().required("Company Name is required"),
  phoneNumber: Yup.string()
    .matches(/^((0|\+91|91)?\d{10})$/, "Phone number is not valid")
    .required("Phone Number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string().required("Message is required"),
});
const initialValues = {
  fullName: "",
  companyName: "",
  phoneNumber: "",
  email: "",
  message: "",
  typeOfOrganisation: "",
  programInterestedIn: []
};

const useStyles = makeStyles(theme => ({
  card: {
    padding: "32px",
    borderRadius: "10px"
  },
  input: {
    margin: "20px 0"
  },
  heading: {
    textAlign: "center"
  },
  label: {
    margin: "8px 0",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins"
  },
  fieldInput: {
    padding: "15px 10px",
    borderRadius: "5px",
    width: "100%",
    border: "1px solid #DEDDE4",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#3B3B3B"
  },
  multiSelectFieldInput: {
    borderRadius: "5px",
    fontSize: "16px",
    width: "100%",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Poppins",
    color: "#3B3B3B",
    "& .css-yk16xz-control": {
      minHeight: "50px"
    },
    "& .css-1okebmr-indicatorSeparator": {
      display: "none",
    },
    "& .css-tlfecz-indicatorContainer": {
      padding: "unset",
      "& svg": {
        height: "15px",
        fill: "#4c4545",
        width: "15px",
      }
    }
  },
  multiSelectFieldInputMui: {
    width: "100%",
  },
  dropdownCheckbox: {
    "& span": {
      color: "red",
      "& svg": {
        fill: "#052B62"
      }
    }
  },
  submitBtn: {
    maxWidth: "391px",
    borderRadius: "8px",
    color: "white",
    backgroundColor: "#0056A3",
    padding: "15px 0",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      color: "white",
      backgroundColor: "#0056A3"
    }
  },
  centerBtn: {
    paddingTop: "48px",
    textAlign: "center"
  },
  formBox: {
    marginLeft: "64px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      paddingTop: "0px"
    }
  },
  errorMessage: {
    color: "red"
  },
  alert: {
    width: "365px"
  },
  alertBox: {
    display: "flex",
    paddingTop: "10px",
    justifyContent: "center",
  },
  fieldInputMessage: {
    resize: "none",
    borderRadius: "5px",
    padding: "15px 10px",
    border: "1px solid #DEDDE4",
    width: "100%",
    lineHeight: "24px",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#3B3B3B"
  },
  menu: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 10px 16px rgba(165, 176, 194, 0.15)",
      border: '0.2px solid #e3e3e3',
      maxHeight: '176px',
    },
    '& .MuiPopover-paper.MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded': {
      overflow: 'hidden auto'
    }
  },
}));
export const FormData = (props: any) => {
  const classes = useStyles();
  const shouldRenderOrgInput = props.organisation.some((option: any) =>
    option.toLowerCase().includes('other')
  );

  const shouldRenderProgramInput = props.program.some((option: any) =>
    option.toLowerCase().includes('other')
  );

  return (
    <Box className={classes.formBox} data-test-id='box'>
      <Card className={classes.card}>
        <Box className={classes.heading}>
          <UtilityInfo {...heading} />
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={props.postPartnerData}
          data-test-id = "formikFormSubmit"
        >
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box className={classes.input}>
                  <Box>
                    <label className={classes.label} htmlFor="fullName">
                      Full Name*
                    </label>
                  </Box>
                  <Field
                    placeholder={"Enter Full Name"}
                    className={classes.fieldInput}
                    type="text"
                    id="fullName"
                    name="fullName"
                  />
                  <ErrorMessage
                    className={classes.errorMessage}
                    name="fullName"
                    component="Box"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.input}>
                  <Box>
                    <label className={classes.label} htmlFor="companyName">
                      Company Name*
                    </label>
                  </Box>
                  <Field
                    placeholder={"Enter Company Name"}
                    className={classes.fieldInput}
                    type="text"
                    id="companyName"
                    name="companyName"
                  />
                  <ErrorMessage
                    className={classes.errorMessage}
                    name="companyName"
                    component="Box"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box className={classes.input}>
                  <Box>
                    <label className={classes.label} htmlFor="phoneNumber">
                      Phone Number*
                    </label>
                  </Box>
                  <Field
                    placeholder={"Enter Phone Number"}
                    className={classes.fieldInput}
                    type="text"
                    id="phoneNumber"
                    name="phoneNumber"
                  />
                  <ErrorMessage
                    className={classes.errorMessage}
                    name="phoneNumber"
                    component="Box"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.input}>
                  <Box>
                    <label className={classes.label} htmlFor="email">
                      Email ID*
                    </label>
                  </Box>
                  <Field
                    placeholder={"Enter Email ID"}
                    className={classes.fieldInput}
                    type="email"
                    id="email"
                    name="email"
                  />
                  <ErrorMessage
                    className={classes.errorMessage}
                    name="email"
                    component="Box"
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Box className={classes.input}>
                  <Box>
                    <label className={classes.label} htmlFor="programInterestedIn">
                      Select Program*
                    </label>
                  </Box>

                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    data-test-id="selectProgram"
                    multiple
                    displayEmpty
                    value={props.program}
                    onChange={props.handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected: any) => {
                      if (selected.length === 0) {
                        return 'Select Program';
                      }
                      return selected.join(', ');
                    }}
                    // MenuProps={MenuProps}
                    className={classes.multiSelectFieldInputMui}
                    MenuProps={{ className: classes.menu }}
                  >
                    {props.selectOptions.programs.map((name: any) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox className={classes.dropdownCheckbox} checked={props.program.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {shouldRenderProgramInput && <Field
                    style={{ marginTop: "4%" }}
                    placeholder={"Enter Program"}
                    className={classes.fieldInput}
                    type="text"
                    id="otherProgram"
                    name="otherProgram"
                    value={props.otherProgram}
                    onChange={props.handleOtherProgramChange}
                  />}
                  <ErrorMessage
                    className={classes.errorMessage}
                    name="programInterestedIn"
                    component="Box"
                  />
                  { props.programErrors && <ErrorMsgStyle>
                    {configJSON.programError}
                  </ErrorMsgStyle> }
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.input}>
                  <Box>
                    <label className={classes.label} htmlFor="typeOfOrganisation">
                      Organisation Type*
                    </label>
                  </Box>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    displayEmpty
                    value={props.organisation}
                    onChange={props.handleOrgChange}
                    input={<OutlinedInput />}
                    renderValue={(selected: any) => {
                      if (selected.length === 0) {
                        return 'Select Organisation Type';
                      }
                      return selected.join(', ');
                    }}
                    // MenuProps={MenuProps}
                    MenuProps={{ className: classes.menu }}
                    className={classes.multiSelectFieldInputMui}
                  >
                    {props.selectOptions.organisations?.map((name: any) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={props.organisation.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                  {shouldRenderOrgInput && <Field
                    style={{ marginTop: "4%" }}
                    placeholder={"Enter Organisation"}
                    className={classes.fieldInput}
                    type="text"
                    id="otherProgram"
                    name="otherProgram"
                    value={props.otherOrganisation}
                    onChange={props.handleOtherOrgChange}
                  />}
                  { props.organisationErrors && <ErrorMsgStyle>
                    {configJSON.organisationError}
                  </ErrorMsgStyle> }
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className={classes.input}>
                <Box>
                  <label className={classes.label} htmlFor="message">
                    Message*
                  </label>
                </Box>
                <Field
                  placeholder={"Enter Message"}
                  className={classes.fieldInputMessage}
                  as="textarea"
                  id="message"
                  name="message"
                  rows="3"
                />
                <ErrorMessage
                  className={classes.errorMessage}
                  name="message"
                  component="Box"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className={classes.centerBtn}>
                <Button className={classes.submitBtn} type="submit" fullWidth onClick={props.handleErrorMessages} data-test-id="submitTestId">
                  Submit
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={12}>
              <Box className={classes.alertBox}>
                {props.alertStatus.showAlertSuccess ? (
                  <Alert className={classes.alert} severity="success">
                    Form Submitted successfully.
                  </Alert>
                ) : (
                  ""
                )}
                {props.alertStatus.showAlertFailed ? (
                  <Alert className={classes.alert} severity="error">
                    Something went wrong.
                  </Alert>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          </Form>
        </Formik>
      </Card>
    </Box>
  );
};
// Customizable Area End
export default class PartnerForm extends PartnershipPageController {
  // Customizable Area Start
  render() {
    return (
      <FormData
        data-test-id="formDataTestId"
        className="formDataStyle"
        postPartnerData={this.postPartnerData}
        alertStatus={this.state.alertStatus}
        program={this.state.program}
        organisation={this.state.organisation}
        otherOrganisation={this.state.otherOrganisation}
        otherProgram={this.state.otherProgram}
        handleChange={this.handleProgramChange}
        handleOrgChange={this.handleOrgChange}
        handleOtherOrgChange={this.handleOtherOrgChange}
        handleOtherProgramChange={this.handleOtherProgramChange}
        selectOptions={this.state.selectOptions}
        programErrors={this.state.programError}
        organisationErrors={this.state.organisationError}
        handleErrorMessages = {this.handleErrorMessage}
      />
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const ErrorMsgStyle = styled(Box)({
  color:"red"
});
// Customizable Area End