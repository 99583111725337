import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
// Customizable Area Start
import { CometChatContext } from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";

import { conversationActionStyle } from "./style.js";
// Customizable Area End
class CometChatConversationListActions extends React.PureComponent {
	// Customizable Area Start
	static contextType = CometChatContext;
	// Customizable Area End
	constructor(props) {
		super(props);
		// Customizable Area Start
		this._isMounted = false;
		this.state = {
			deleteInProgress: false,
		};
		// Customizable Area End
	}

	// Customizable Area Start
	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	toggleTooltip = (event, flag) => {
		const elem = event.target;

		if (flag) {
			elem.setAttribute("title", elem.dataset.title);
		} else {
			elem.removeAttribute("title");
		}
	};

	deleteConversation = (event) => {
		this.props.actionGenerated(
			enums.ACTIONS["DELETE_CONVERSATION"],
			this.props.conversation
		);
		event.stopPropagation();
	};

	// Customizable Area End
	render() {
		// Customizable Area Start
		return (
			<ul
				css={conversationActionStyle(this.context)}
				className='list__item__actions'
			>
			</ul>
		);
		// Customizable Area End
	}
}

export { CometChatConversationListActions };
