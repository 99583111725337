import React from "react";
// Customizable Area Start
import { Box, Grid } from "@material-ui/core";
import UtilityInfo from "../../../components/src/UtilityInfo.web";
import { makeStyles } from "@material-ui/core/styles";
const configJSON = require("./config");
const ProductHelpImg = require("./assets");
const productHelps = configJSON.LandingPageContent.productHelp;
// Customizable Area End

// Customizable Area Start
interface HowOurProduct {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
}

const howOurProduct: HowOurProduct = {
  headerText: productHelps.headerText,
  contentText: productHelps.contentText,
  fontSize: "48px",
  color: "#052B62",
  secondColor: "#5D5A6F",
  paragraphFontSize: "16px"
};
const useStyles = makeStyles(theme => ({
  ourProductHelpBanner: {
    position: "relative",
    width: "100%",
    "&::before": {
      content: '""',
      top: "-22px",
      left: "-26px",
      backgroundImage: `url(${ProductHelpImg.IconGroup})`,
      position: "absolute",
      height: "140px",
      width: "180px",
      zIndex: -1,
      [theme.breakpoints.down("sm")]: {
        top: "-17px",
        left: "-29px"
      }
    },
    "&::after": {
      content: '""',
      bottom: "-22px",
      zIndex: -1,
      right: "-24px",
      backgroundImage: `url(${ProductHelpImg.IconGroup})`,
      position: "absolute",
      height: "140px",
      width: "180px",
      [theme.breakpoints.down("sm")]: {
        right: "-12px",
        bottom: "-12px"
      }
    }
  },
  headingBox: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  leftTopImg: {
    position: "absolute",
    top: "-28px",
    left: " -26px",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  rightTopImg: {
    position: "absolute",
    bottom: "-28px",
    right: " -26px",
    zIndex: -1,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  imgBorder: {
    width: "100%",
    borderRadius: "50px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "18px"
    }
  }
}));
const ProductHelp = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box className={classes.headingBox}>
          <UtilityInfo {...howOurProduct} />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box className={classes.ourProductHelpBanner}>
          <img
            src={`${ProductHelpImg.hapchihelpNew}`}
            alt="banner"
            className={classes.imgBorder}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
// Customizable Area End
export default ProductHelp;
