import React from "react";
// Customizable Area Start
import { Box, Container, Grid, Typography } from "@material-ui/core";
const Image = require("../../assets");
import { makeStyles } from "@material-ui/core/styles";

interface Feature {
  heading: string;
  content: string;
  image: string;
}
interface Props {
  feature: Feature;
}
// Customizable Area End
// Customizable Area Start
const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: "40px",
    fontWeight: 600,
    color: "#052B62"
  },
  content: {
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74",
    padding: "15px 0"
  },
  imageBox: {
    display: "flex",
    justifyContent: "flex-end",
    transform: "translate(20px,0px)",
    [theme.breakpoints.down("xs")]: {
      transform: "none",
      paddingTop: "30px",
      justifyContent: "flex-start"
    },
    [theme.breakpoints.down("md")]: {
      transform: "none",
      paddingTop: "30px",
      justifyContent: "flex-start"
    }
  }
}));
const RightLeftLayout = (props: Props) => {
  const classes = useStyles();
  const { feature } = props;
  return (
  
      <Grid container>
        <Box sx={{ pt: "100px" }}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <Box>
                <Typography className={classes.heading}>
                  {feature.heading}
                </Typography>
                <Typography variant="body1" className={classes.content}>
                  {feature.content}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box className={classes.imageBox}>
                <img src={Image[`${feature.image}`]} width="100%" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
   
  );
};
// Customizable Area End
export default RightLeftLayout;
