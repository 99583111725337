import React, { useRef } from "react";
// Customizable Area Start
import { Box, Grid, Container, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Assessment from "./Assessment.web";
import WhyHapchi from "./WhyHapchi.web";
import UtilityInfo from "../../../components/src/UtilityInfo.web";
import GenericWebButton from "../../../components/src/GenericWebButton.web";
import WorkWith from "./WorkWith.web";
import ProductHelp from "./ProductHelp.web";
import HowHapchiHelps from "./HowHapchiHelps.web";
import HapchiFamily from "./HapchiFamily.web";
import FlotingButton from "../../../components/src/FlotingButton.web";
import OurPartners from "./OurPartners.web";
import Footer from "../../../components/src/Footer.web";
import LandingPageController,{ImpactDetails,Props} from './LandingPageController';
// Customizable Area End
const configJSON = require("./config");
const landingImages = require("./assets");
// Customizable Area Start
interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
  multiLine: string[];
}

const bannerText = configJSON.LandingPageContent.banner;
const multiInfo = [
  "An innovative digital platform for your school, dedicated to providing a safe and  protective space for your students. From infancy to adolescence, we've got every safety concern covered."
];
const content: Content = {
  headerText: bannerText.headerText,
  contentText: bannerText.contentText,
  fontSize: "60px",
  color: "#fff",
  secondColor: "rgba(255, 255, 255, 0.6);",
  paragraphFontSize: "18px",
  multiLine: multiInfo
};
const useStyles = makeStyles(theme => ({
  header: {
    background:
      "linear-gradient(70deg, rgba(1,86,162,1) -36.53%, rgba(75,144,205,1) 66%, rgba(179,219,255,1) 146%)",
    backgroundRepeat: "no-repeat",
    padding: "64px 0",
    maxHeight: "764px",
    [theme.breakpoints.down("sm")]: {
      padding: "64px 0",
      maxHeight: "100%"
    }
  },
  whiteBox: {
    height: "500px",
    width: "100%",
    background: "#fff",
    [theme.breakpoints.down("sm")]: {
      height: "300px"
    }
  },

  hapchiFamilyContainer: {
    padding: "80px 0",
    background:
      "linear-gradient(70deg, rgba(1,86,162,1) -36.53%, rgba(75,144,205,1) 66%, rgba(179,219,255,1) 146%)"
  },
  topSpaceWork: {
    padding: "100px 0px"
  },
  heroBoxImage: {
    transform: " translateY(-61px)",
    [theme.breakpoints.down("sm")]: {
      transform: " translateY(0px)"
    }
  },
  contentBox: {
    width: "551px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  topSpace: {
    marginTop: "15px"
  },
  relativeParent: {
    position: "relative"
  },
  hapchiSchoolContainer: {
    paddingTop: "100px",
    paddingBottom: "0px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "100px",
      paddingBottom: "0px"
    }
  },
  partnerContainer: {
    paddingBottom: "80px"
  }
}));
const LandingPageFunctional = (props:{impactDetails:ImpactDetails[]}) => {
  const classes = useStyles();
  const boxRef = useRef<HTMLDivElement>();
  const footerRef = useRef<HTMLDivElement>();
  const handleBackClick = () => {
    if (!!boxRef && !!boxRef.current && !!boxRef.current.scrollIntoView) {
      boxRef.current.scrollIntoView({ behavior: "smooth", block: "center",inline: "center" 
    });
    }
  };
  
  const handleKnowMoreClick = () => {
    window.location.replace("/programs");
  };
  return (
    <>
      <Box className={classes.relativeParent}>
        <Box className={classes.header}>
          <Container>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className={classes.contentBox}>
                  <UtilityInfo {...content} lineHeight="72px" fontSize="48px" />

                  <Box className={classes.topSpace}>
                  <Link href="/programs">
                    <GenericWebButton
                      icon={landingImages.rightArrow}
                      text={"Know More"}
                      callback={handleKnowMoreClick}
                    />
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Box className={classes.heroBoxImage}>
                  <img src={landingImages.sliderGIF} width="100%" />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <FlotingButton icon={landingImages.handImg} callback={handleBackClick} ourPartner={true} />
      <Container>
        <Assessment />
      </Container>
      <Container>
        <Grid container>
          <WhyHapchi />
        </Grid>
      </Container>
      <Container>
        <Box className={classes.topSpaceWork}>
          <WorkWith />
        </Box>
      </Container>
      <Container>
        <ProductHelp />
      </Container>
      <Container>
        <Box className={classes.hapchiSchoolContainer}>
          <HowHapchiHelps impactDetails={props.impactDetails}/>
        </Box>
      </Container>
      <Container>
        <Box className={classes.partnerContainer} {...{ ref: boxRef } as any}>
          <OurPartners navigation={undefined} id={""} />
        </Box>
      </Container>
      <Box className={classes.hapchiFamilyContainer}>
        <Container>
          <HapchiFamily />
        </Container>
      </Box>
      <Box {...{ ref: footerRef } as any}>
        <Footer />
      </Box>
    </>
  );
};
// Customizable Area End
class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    return <LandingPageFunctional impactDetails={this.state.impactDetails} />;
  }
  // Customizable Area End
}
export default LandingPage;
