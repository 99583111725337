// Customizable Area Start
import Point from '../Point';
import Menu from '../Menu';
import Piece from '../Piece';
import PolyPiece from '../PolyPiece';
import { uploadFile } from '../uploadFile';

type PuzzleParams = {
  img?: string | HTMLImageElement ;
  width: number;
  height: number;
  npieces: number;
  idiv: string | HTMLDivElement;
  onCompletion: () => void 
};

/* 
  see createPuzzle for the 'params' parameter
  This constructor is used to load the picture, the actual construction of the
  puzzle can't be done before picture dimensions are known
*/
class Puzzle {
  image: any;
  p1: any;
  p2: any;
  reqHeight: number = 0;
  reqWidth: number = 0;
  height: number = 0;
  static MARGIN1: number = 5;
  width: number = 0;
  divGame: HTMLElement = document.createElement('div');
  divBoard: any;
  listeners: any[] = [];
  canvMobile: any;
  dCoupling: number = 0;
  menu: any;
  npieces: number = 0;
  nx: any;
  ny: any;
  freeSpace: number = 0;
  dx: number = 0;
  dy: number = 0;
  pieces: Piece[][] = [];
  polyPieces: PolyPiece[] = [];
  mouseOffsX: number = 0;
  mouseOffsY: number = 0;
  pieceMove: any;
  mech: { scale: number; offsx: number; offsy: number } = {
    scale: 0,
    offsx: 0,
    offsy: 0,
  };
  anim: any;
  onCompletion: any;
  private onCompletionCallback!: () => void;

  constructor(params: PuzzleParams) {
    // image - by url (src) or straight image object
    if (typeof params.img == 'string') {
      this.onCompletionCallback = params.onCompletion || (() => {}); 
      this.image = new Image();
      this.image.src = params.img;
      this.image.addEventListener(
        'load',
        ( (obj) => {
          return function () {
            obj.createPuzzle(params);
          };
        })(this)
      );
    } 

    this.divGame = document.createElement('div');
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  mrandom = Math.random;
  mfloor = Math.floor;
  mhypot = Math.hypot;

  isMiniature = () => {
    return window.location.pathname.includes('/fullcpgrid/');
  };

  autoStart = this.isMiniature();

  alea = (min: number, max: number) => {
    // random number [min..max[ . If no max is provided, [0..min[

    if (typeof max == 'undefined') return min * this.mrandom();
    return min + (max - min) * this.mrandom();
  };

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  intAlea = (min: number, max: number | undefined) => {
    // random integer number [min..max[ . If no max is provided, [0..min[

    if (typeof max === 'undefined') {
      max = min;
      min = 0;
    }
    return this.mfloor(min + (max - min) * this.mrandom());
  }; // intAlea

  //-----------------------------------------------------------------------------
  arrayShuffle = (array: any[]) => {
    /* randomly changes the order of items in an array
         only the order is modified, not the elements
      */
    let k1, temp;
    for (let k = array.length - 1; k >= 1; --k) {
      k1 = Math.floor((k + 1) * Math.random());
      temp = array[k];
      array[k] = array[k1];
      array[k1] = temp;
    } // for k
    return array;
  }; // arrayShuffle

  lookForLoops = (tbCases: any[]) => {
    // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
 
    // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -
    // internal : checks if an edge given by kx, ky is in tbEdges
    // return index in tbEdges, or false

    const edgeIsInTbEdges = (kx: any, ky: any, edge: number) => {
     return this.checkedge(kx, ky, edge, tbEdges);
    }; // function edgeIsInTbEdges

    // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -

    let tbLoops = []; // for the result
    let tbEdges: any[] = []; // set of edges which are not shared by 2 pieces of input
    let k;
    let kEdge; // to count 4 edges
    let lp; // for loop during its creation
    let currEdge; // current edge
    let tries; // tries counter
    let edgeNumber: number | boolean = false; // number of edge found during research
    let potNext;

    // table of tries

    let tbTries = [
      // if we are on edge 0 (top)
      [
        { dkx: 0, dky: 0, edge: 1 }, // try # 0
        { dkx: 1, dky: 0, edge: 0 }, // try # 1
        { dkx: 1, dky: -1, edge: 3 }, // try # 2
      ],
      // if we are on edge 1 (right)
      [
        { dkx: 0, dky: 0, edge: 2 },
        { dkx: 0, dky: 1, edge: 1 },
        { dkx: 1, dky: 1, edge: 0 },
      ],
      // if we are on edge 2 (bottom)
      [
        { dkx: 0, dky: 0, edge: 3 },
        { dkx: -1, dky: 0, edge: 2 },
        { dkx: -1, dky: 1, edge: 1 },
      ],
      // if we are on edge 3 (left)
      [
        { dkx: 0, dky: 0, edge: 0 },
        { dkx: 0, dky: -1, edge: 3 },
        { dkx: -1, dky: -1, edge: 2 },
      ],
    ];

    // create list of not shared edges (=> belong to boundary)
    for (k = 0; k < tbCases.length; k++) {
      for (kEdge = 0; kEdge < 4; kEdge++) {
        if (!this.edgeIsCommon(tbCases[k].kx, tbCases[k].ky, kEdge, tbCases))
          tbEdges.push({
            kx: tbCases[k].kx,
            ky: tbCases[k].ky,
            edge: kEdge,
            kp: k,
          });
      } // for kEdge
    } // for k

    while (tbEdges.length > 0) {
      lp = []; // new loop
      currEdge = tbEdges[0]; // we begin with first available edge
      lp.push(currEdge); // add it to loop
      tbEdges.splice(0, 1); // remove from list of available sides
      do {
        for (tries = 0; tries < 3; tries++) {
          potNext = tbTries[currEdge.edge][tries];
          edgeNumber = edgeIsInTbEdges(
            currEdge.kx + potNext.dkx,
            currEdge.ky + potNext.dky,
            potNext.edge
          );
          if (edgeNumber === false) continue; // can't here
          // new element in loop
          currEdge = tbEdges[edgeNumber]; // new current edge
          lp.push(currEdge); // add it to loop
          tbEdges.splice(edgeNumber, 1); // remove from list of available sides
          break; // stop tries !
        } // for tries
        if (edgeNumber === false) break; // loop is closed
      } while (1); // do-while exited by break
      tbLoops.push(lp); // add this loop to loops list
    } // while tbEdges...
    return tbLoops;
  }; // function lookForLoops


     // internal : checks if an edge given by kx, ky is common with another cell
    // returns true or false

    edgeIsCommon(kx: number, ky: number, edge: number, tbCases: any[]){
      let k;
      switch (edge) {
        case 0:
          ky--;
          break; // top edge
        case 1:
          kx++;
          break; // right edge
        case 2:
          ky++;
          break; // bottom edge
        case 3:
          kx--;
          break; // left edge
      } // switch
      for (k = 0; k < tbCases.length; k++) {
        if (kx === tbCases[k].kx && ky === tbCases[k].ky) return true; // we found the neighbor
      }
      return false; // not a common edge
    } // function edgeIsCommon


    checkedge(kx: any, ky: any, edge: number, tbEdges:any[]){
      let k;
      for (k = 0; k < tbEdges.length; k++) {
        if (
          kx === tbEdges[k].kx &&
          ky === tbEdges[k].ky &&
          edge === tbEdges[k].edge
        )
          return k; // found it
      }
      return false; // not found
    }

  /*
    The given parameter if an object with the following properties :
    -  img, which may be:
      string type : an image url
      object type : an image object (with a ready loaded image)
    -  width : width available for the picture
    -  height : width available for the picture. The script will use the
    width x height space as smartly as possible
    -  npieces : number of pieces. May be any (sensible) integer, the script
    will choose a number of columns and rows to have pieces as square as possible,
    and an actual total number of pieces as close as possible to this integer.
    -  idiv : div which will contail the game
  */
 runPuzzle(){
return 2*5

 }
  createPuzzle(params: PuzzleParams) {

    // we change width or height in order to keep the picture size ratio
    let wi = this.image.width; // from original picture
    let he = this.image.height;

    this.reqHeight = params.height; // requested height
    this.reqWidth = params.width;
    this.height = this.reqHeight - 2 * Puzzle.MARGIN1; // place left on screen including margin
    this.width = this.reqWidth - 2 * Puzzle.MARGIN1; //

    if (wi / he > this.width / this.height) {
      // actual picture "more horizontal" than game board
      this.height = (this.width * he) / wi;
    } else {
      this.width = (this.height * wi) / he;
    }
    // end change width or height

    // div Game - by name (id) or directly an object
    if (typeof params.idiv == 'string') {
      this.divGame = document.getElementById(
        params.idiv
      ) as HTMLElement;
    } else {
      this.divGame = params.idiv;
    }
    if (!this.divGame) {
      return;
    }

    this.divGame.style.overflow = 'visible';
    this.divGame.style.position = 'relative';

    // divBoard
    if (!this.divBoard) {
      this.divBoard = document.createElement('div');
      this.divGame.appendChild(this.divBoard);
    }
    this.divBoard.style.overflow = 'hidden';
    this.divBoard.style.position = 'absolute';
    this.divBoard.style.left = 0;
    this.divBoard.style.top = 0;

    this.listeners = []; // table of eventListeners to remove

    /* 
      provisional dimensions of the game, waiting for actual dimensions which depend
      on number of pieces
    */

    this.divGame.style.width = this.divBoard.style.width =
      this.width + 2 * Puzzle.MARGIN1 + 'px';
    this.divGame.style.height = this.divBoard.style.height =
      this.height + 2 * Puzzle.MARGIN1 + 'px';

    // canv for the moving PolyPiece and the full image
    if (!this.canvMobile) {
      this.canvMobile = document.createElement('canvas');
      this.divBoard.appendChild(this.canvMobile);
    }
    this.canvMobile.style.visibility = 'visible';
    this.canvMobile.width = parseFloat(this.divBoard.style.width);
    this.canvMobile.height = parseFloat(this.divBoard.style.height);
    this.canvMobile.style.position = 'absolute';
    this.canvMobile.style.top = '0px';
    this.canvMobile.style.left = '0px';

    this.canvMobile.style.zIndex = 5;

    this.dCoupling = 10; // distance for pieces to couple together, in pixels (on each x and y axis)


    this.npieces = params.npieces
    this.next();


    this.canvMobile
      .getContext('2d')
      .drawImage(
        this.image,
        0,
        0,
        wi,
        he,
        Puzzle.MARGIN1,
        Puzzle.MARGIN1,
        this.width,
        this.height
      );

    if (this.menu) {
      this.menu = new Menu({
        parentDiv: this.divGame,
        idDivMenu: 'divmenu',
        title: 'MENU',
        lineOffset: 30,
        lineStep: 30,
        lines: [
          { text: 'load image', func: this.loadImage() },
          { text: '12 piece', func: this.returnFunct(12) },
          { text: '25 piece', func: this.returnFunct(25) },
          { text: '50 piece', func: this.returnFunct(50) },
          { text: '100 piece', func: this.returnFunct(100) },
          { text: '200 piece', func: this.returnFunct(200) },
        ],
      });
    }
    if (this.autoStart) {
      this.npieces = 25;
      this.next();
    }
  }

  returnFunct(nbpieces: number) {
    let puz = this;
    return function () {
      puz.menu.collapse();
      puz.npieces = nbpieces;
      puz.next();
    };
  }

  loadImage() {
    let puz = this;
    return function () {
      puz.menu.collapse();
      uploadFile(() => {}, {
        accept: 'image/*',
        readMethod: 'readAsDataURL',
        image: puz.image,
      });
    };
  }

  next() {
    let nx, ny, dx, dy, kx, ky;

    let np;
    /* parameters for the shape of pieces edges
     */

    let coeffDecentr = 0.12;

    this.canvMobile.style.visibility = 'hidden'; // hide the full picture

    // evaluation of number of pieces

    this.computenxAndny();
    nx = this.nx;
    ny = this.ny;

    // re - evaluation of the dimensions of the picture, leaving a space for pieces on one side

   this.evaluationOfTheDimension(nx, ny)


    this.dx = dx = this.width / nx; // horizontal side of tiling
    this.dy = dy = this.height / ny; // vertical side of tiling

    /* adjust coupling distance to size of tiles */
    this.dCoupling = Math.max(10, Math.min(dx, dy) / 10);

    // "clean" the board
    while (this.divBoard.firstChild)
      this.divBoard.removeChild(this.divBoard.firstChild);
    // but keep the canvMobile
    this.divBoard.appendChild(this.canvMobile);

    this.canvMobile.width = this.reqWidth;
    this.divGame.style.width = this.divBoard.style.width =
      this.canvMobile.width + 'px';

    this.canvMobile.height = this.reqHeight;
    this.divGame.style.height = this.divBoard.style.height =
      this.canvMobile.height + 'px';

    // compute the shapes of the pieces

    /* first, place the corners of the pieces
      at some distance of their theorical position, except for edges
    */

    let corners: any[] = [];
    for (ky = 0; ky <= ny; ++ky) {
      corners[ky] = [];
      for (kx = 0; kx <= nx; ++kx) {
        corners[ky][kx] = new Point(
          (kx + this.alea(-coeffDecentr, coeffDecentr)) * dx,
          (ky + this.alea(-coeffDecentr, coeffDecentr)) * dy
        );
        if (kx === 0) corners[ky][kx].x = 0;
        if (kx === nx) corners[ky][kx].x = this.width;
        if (ky === 0) corners[ky][kx].y = 0;
        if (ky === ny) corners[ky][kx].y = this.height;
      } // for kx
    } // for ky

    // Array of raw pieces (straight sides)
    // this.pieces = [];
    for (ky = 0; ky < ny; ++ky) {
      this.pieces[ky] = [];
      for (kx = 0; kx < nx; ++kx) {
        this.pieces[ky][kx] = np = new Piece(kx, ky);
        // top side
        if (ky === 0) {
          np.ts.points = [corners[ky][kx], corners[ky][kx + 1]];
          np.ts.type = 'd';
        } else {
          np.ts = this.pieces[ky - 1][kx].bs;
        }
        // right side
        np.rs.points = [corners[ky][kx + 1], corners[ky + 1][kx + 1]];
        np.rs.type = 'd';
        if (kx < nx - 1) {
          if (this.intAlea(2, undefined))
            // randomly twisted one one side of the side
            np.rs.twist(corners[ky][kx], corners[ky + 1][kx], 0.5, 1);
          else
            np.rs.twist(
              corners[ky][kx + 2],
              corners[ky + 1][kx + 2],
              0.5,
              1
            );
        }
        // left side
        if (kx === 0) {
          np.ls.points = [corners[ky][kx], corners[ky + 1][kx]];
          np.ls.type = 'd';
        } else {
          np.ls = this.pieces[ky][kx - 1].rs;
        }
        // bottom side
        np.bs.points = [corners[ky + 1][kx], corners[ky + 1][kx + 1]];
        np.bs.type = 'd';
        if (ky < ny - 1) {
          if (this.intAlea(2, undefined))
            // randomly twisted one one side of the side
            np.bs.twist(corners[ky][kx], corners[ky][kx + 1], 1, 0.5);
          else
            np.bs.twist(
              corners[ky + 2][kx],
              corners[ky + 2][kx + 1],
              1,
              0.5
            );
        }
      } // for kx
    } // for ky
    this.associateImage();
  }

  evaluationOfTheDimension(nx: any, ny: any){
    if (
      this.image.width / this.image.height <
      (this.reqWidth - 2 * Puzzle.MARGIN1) /
        (this.reqHeight - 2 * Puzzle.MARGIN1)
    ) {
      /* actual picture "more vertical" than available place
        leave place on the right side */
      this.width =
        ((this.reqWidth - 2 * Puzzle.MARGIN1) / (nx + 2)) * nx;
      this.height =
        (this.width / this.image.width) * this.image.height;
      if (this.height > this.reqHeight - 2 * Puzzle.MARGIN1) {
        this.height = this.reqHeight - 2 * Puzzle.MARGIN1;
        this.width =
          (this.height * this.image.width) / this.image.height;
      }
      this.freeSpace = 0; // place left on the right
    } else {
      /* actual picture "more horizontal" than available place
        leave place on the bottom side */
      this.height =
        ((this.reqHeight - 2 * Puzzle.MARGIN1) / (ny + 2)) * ny;
      this.width =
        (this.height / this.image.height) * this.image.width;
      if (this.width > this.reqWidth - 2 * Puzzle.MARGIN1) {
        this.width = this.reqWidth - 2 * Puzzle.MARGIN1;
        this.height =
          (this.width * this.image.height) / this.image.width;
      }
      this.freeSpace = 1; // place left under
    }
   }

  associateImage() {
    let kx, ky, kn, kp;
    let scale, he, wi, offsx, offsy, pc;

    // scale picture
    wi = this.image.width;
    he = this.image.height;

    if (wi / he > this.width / this.height) {
      // actual picture "more horizontal" than board
      scale = this.height / he;
      offsy = 0;
      offsx = (wi - this.width / scale) / 2; // offset in source picture
    } else {
      // actual picture "more (or equally)horizontal" than board
      scale = this.width / wi;
      offsx = 0;
      offsy = (he - this.height / scale) / 2; // offset in source picture
    }

    this.mech = { scale: scale, offsx: offsx, offsy: offsy }; // informations for scaling

    // creation of pieces
    // table of PolyPieces
    this.polyPieces = [];

    for (ky = 0; ky < this.ny; ky++) {
      for (kx = 0; kx < this.nx; kx++) {
        this.pieces[ky][kx].createDivPiece(this, scale, offsx, offsy);
        this.polyPieces.push(
          new PolyPiece(this.pieces[ky][kx], this)
        );
      } // for kx
    } // for ky

    // random zindex for initial pieces
    this.arrayShuffle(this.polyPieces);
    this.evaluateZIndex();

    for (kp = 0; kp < this.polyPieces.length; kp++) {
      for (kn = 0; kn < this.polyPieces[kp].pieces.length; kn++) {
        pc = this.polyPieces[kp].pieces[kn];

        this.divBoard.appendChild(pc.theDiv);
        switch (this.freeSpace) {
          case 0:
            pc.pTarget = new Point(
              this.reqWidth - (2.25 + Math.random() / 4) * this.dx,
              Math.random() * (this.height - this.dy) - this.dy
            );
            break;
          case 1:
            pc.pTarget = new Point(
              Math.random() * (this.width - this.dx) - this.dx,
              this.reqHeight - (2.25 + Math.random() / 4) * this.dy
            );
        } // switch
      } // for kn
    } // for kp
    window.setTimeout(
      (function (obj) {
        return function () {
          obj.launchAnimation();
        };
      })(this),
      1000
    );
  }

  addRemovableEventListener(
    event: string,
    funct: {
      (event: any): void;
      (event: any): void;
      (event: any): void;
      (event: any): void;
      (event: any): void;
      (event: any): void;
      (event: any): void;
    }
  ) {
    this.divBoard.addEventListener(event, funct);
    this.listeners.push({ event: event, funct: funct });
  }

  removeAllListeners() {
    let a;
    while (this.listeners.length > 0) {
      a = this.listeners.pop();
      this.divBoard.removeEventListener(a.event, a.funct);
    } // while
  }

  launchAnimation() {
    this.anim = { cpt: this.autoStart ? 200 : 100 };
    this.anim.tmr = window.setInterval(
      (function (puzz) {
        return function () {
          puzz.animate();
        };
      })(this),
      20
    );
  }

  animate() {
    let kp, kn, pc, act, cib;

    if (this.anim.cpt === 0) {
      window.clearInterval(this.anim.tmr);
      delete this.anim;

      this.evaluateZIndex();
      this.beginGame();

      return;
    }
    this.anim.cpt--;
    for (kp = 0; kp < this.polyPieces.length; kp++) {
      for (kn = 0; kn < this.polyPieces[kp].pieces.length; kn++) {
        pc = this.polyPieces[kp].pieces[kn];
        act = pc.where();
        cib = pc.pTarget;
        pc.moveTo(
          new Point(
            (this.anim.cpt * act.x + cib.x) / (this.anim.cpt + 1),
            (this.anim.cpt * act.y + cib.y) / (this.anim.cpt + 1)
          )
        );
        if (this.anim.cpt === 0) {
          delete pc.pTarget;
        }
      } // for kn
    } // for kp
  }

  animateEnd() {
    let xcou, ycou;
    if (this.anim.cpt === 0) {
      this.onCompletionCallback();
      window.clearInterval(this.anim.tmr);
      delete this.anim;
      return;
    }
    this.anim.cpt--;
    xcou = parseFloat(this.canvMobile.style.left);
    ycou = parseFloat(this.canvMobile.style.top);

    this.canvMobile.style.left =
      (this.anim.cpt * xcou + this.anim.xfin) / (this.anim.cpt + 1) +
      'px';
    this.canvMobile.style.top =
      (this.anim.cpt * ycou + this.anim.yfin) / (this.anim.cpt + 1) +
      'px';
  }

  // merges polyPieces[n2] and polyPieces[n1] into a new piece
  // removes those pieces and inserts nes one
  // re evaluates z-orders accordingly
  // return index of new polyPiece
  merge(n1: number, n2: number) {
    let nppiece, nbpieces, k;
    this.polyPieces[n1].merge(this.polyPieces[n2]); // merges pieces
    nppiece = this.polyPieces[n1]; // save new piece
    if (n1 > n2) {
      this.polyPieces.splice(n1, 1);
      this.polyPieces.splice(n2, 1);
    } else {
      this.polyPieces.splice(n2, 1);
      this.polyPieces.splice(n1, 1);
    }

    // will insert nes PolyPiece immediately before the first with less pieces
    nbpieces = nppiece.pieces.length;
    for (
      k = 0;
      k < this.polyPieces.length &&
      this.polyPieces[k].pieces.length >= nbpieces;
      k++
    ) {
      this.runPuzzle()
    }
    // insert new
    this.polyPieces.splice(k, 0, nppiece);

    return k;
  }

  evaluateZIndex() {
    let kp, kn, z;
    z = 1;
    for (kp = 0; kp < this.polyPieces.length; kp++) {
      for (kn = 0; kn < this.polyPieces[kp].pieces.length; kn++) {
        this.polyPieces[kp].pieces[kn].theDiv.style.zIndex = z++;
      } // for kn
    } // for kp
  }

  // beginning of game
  beginGame() {
    // record offset between mouse coordinates et board origin

    let styl = getComputedStyle(this.divGame);
    this.mouseOffsX =
    this.divGame.offsetLeft + parseFloat(styl.borderLeftWidth);
  this.mouseOffsY =
    (this.divGame.offsetTop + parseFloat(styl.borderTopWidth));
    
    this.pieceMove = false; // no selected piece
    // set event listeners
    this.addRemovableEventListener(
      'mousedown',
      (function (puzzle) {
        return function (event: {
          preventDefault: () => void;
          clientX: any;
          clientY: any;
          buttons: any;
        }) {
          event.preventDefault();
          let [x, y] = [event.clientX, event.clientY];
          let newEvent = {
            x: x,
            y: y,
            buttons: event.buttons,
            origin: 'mouse',
          };
          puzzle.mouseDownGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      'mouseup',
      (function (puzzle) {
        return function (event: {
          preventDefault: () => void;
          clientX: any;
          clientY: any;
          buttons: any;
        }) {
          event.preventDefault();
          let [x, y] = [event.clientX, event.clientY];
          let newEvent = {
            x: x,
            y: y,
            buttons: event.buttons,
            origin: 'mouse',
          };
          puzzle.mouseUpGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      'mousemove',
      (function (puzzle) {
        return function (event: {
          preventDefault: () => void;
          clientX: any;
          clientY: any;
          buttons: any;
        }) {
          event.preventDefault();
          let [x, y] = [event.clientX, event.clientY];
          let newEvent = {
            x: x,
            y: y,
            buttons: event.buttons,
            origin: 'mouse',
          };
          puzzle.mouseMoveGame(newEvent);
        };
      })(this)
    );

    this.addRemovableEventListener(
      'touchstart',
      (function (puzzle) {
        return function (event: {
          preventDefault: () => void;
          touches: string | any[];
        }) {
          event.preventDefault();
          if (event.touches.length !== 1) return;
          let [x, y] = [
            event.touches[0].clientX,
            event.touches[0].clientY,
          ];
          let newEvent = {
            x: x,
            y: y,
            buttons: null,
            origin: 'touch',
          };
          puzzle.mouseDownGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      'touchend',
      (function (puzzle) {
        return function (event: { preventDefault: () => void }) {
          event.preventDefault();
          let newEvent = { origin: 'touch' };
          puzzle.mouseUpGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      'touchcancel',
      (function (puzzle) {
        return function (event: { preventDefault: () => void }) {
          event.preventDefault();
          let newEvent = { origin: 'touch' };
          puzzle.mouseUpGame(newEvent);
        };
      })(this)
    );
    this.addRemovableEventListener(
      'touchmove',
      (function (puzzle) {
        return function (event: {
          preventDefault: () => void;
          touches: string | any[];
        }) {
          event.preventDefault();
          if (event.touches.length !== 1) return;
          let [x, y] = [
            event.touches[0].clientX,
            event.touches[0].clientY,
          ];
          let newEvent = {
            x: x,
            y: y,
            buttons: null,
            origin: 'touch',
          };
          puzzle.mouseMoveGame(newEvent);
        };
      })(this)
    );
  }

  // mouseDown during game
  mouseDownGame(event: {
    x: any;
    y: any;
    buttons: any;
    origin: any;
  }) {
    // ignore if not left button only
    if (event.origin === 'mouse' && event.buttons !== 1) return;
    this.pieceMove = this.lookForPiece(event);
    if (this.pieceMove === false) return;
    this.emphasize(this.pieceMove.pp);
    // we will add to the 'this.pieceMove' object the offset between mousePosition and
    //   canvMobile position for proper movement of canvMobile when mouse moves

    this.pieceMove.offsx =
      event.x -
      this.mouseOffsX -
      parseFloat(this.canvMobile.style.left);
    this.pieceMove.offsy =
      event.y -
      (this.mouseOffsY - window.scrollY) -
      parseFloat(this.canvMobile.style.top);
    this.divGame.style.cursor = 'move';
  }

  // mouseUp during game
   breakFunc2(yesyesMerge:any,idp:any,k:any){
    if (!yesyesMerge) {
      let tmp = this.polyPieces[idp]; // memorize polyPiece
      this.polyPieces.splice(idp, 1); // remove from list
      for (
        k = idp;
        k < this.polyPieces.length &&
        this.polyPieces[k].pieces.length >= tmp.pieces.length;
        k++
      );
      this.polyPieces.splice(k, 0, tmp); // re-insert at the right place
    } // if no merging
  }
  mouseUpGame(event: any) {
    let k, polyP, pc;

    this.divGame.style.cursor = 'default';

    if (this.pieceMove === false) return;

    // hide the canvasMobile which was used for the moving piece
    let canvx = parseFloat(this.canvMobile.style.left);
    let canvy = parseFloat(this.canvMobile.style.top);
    this.canvMobile
      .getContext('2d')
      .clearRect(0, 0, this.canvMobile.width, this.canvMobile.height);

    // display again original pieces
    polyP = this.polyPieces[this.pieceMove.pp];
    for (k = 0; k < polyP.pieces.length; k++) {
      pc = polyP.pieces[k];
      pc.moveTo(
        new Point(
          this.dx * (pc.kx - 1) + canvx,
          this.dy * (pc.ky - 1) + canvy
        )
      );
      pc.theDiv.style.visibility = 'visible';
    } // for k

    // check if moved piece is close enough of another to merge them
    //  check again with the result of the merge operation

    let idp = this.pieceMove.pp;
    let yesMerge = false,
      yesyesMerge = false;
    do {
      yesMerge = false;
      polyP = this.polyPieces[idp];
      for (k = 0; k < this.polyPieces.length; k++) {
        if (k === idp) continue; // don't check neighborhood with itself !
        if (polyP.ifNear(this.polyPieces[k])) {
          // yes !
          idp = this.merge(k, idp); // merge and keep track of index of merged piece
          yesMerge = true;
          yesyesMerge = true; // 2 pieces merging
          break; // out of  'for' loop
        } // if we found a piece
      } // for
    } while (yesMerge); // do it again if pieces werge merged

    // if no merging, move (if this.polypieces) the selected PolyPiece before
    // those with the same number of pieces


this.breakFunc2(yesyesMerge,idp,k)

    this.evaluateZIndex();
    this.pieceMove = false;

    // won ?
    if (this.polyPieces.length > 1) return; // no, continue

    // YES ! tell the player
    this.removeAllListeners();
    // normal image is re-drawn
    let ctx = this.canvMobile.getContext('2d');
    ctx.drawImage(
      this.image,
      this.mech.offsx,
      this.mech.offsy,
      this.width / this.mech.scale,
      this.height / this.mech.scale,
      0,
      0,
      this.width,
      this.height
    );
    this.anim = {
      cpt: 100,
      xorg: 0,
      yorg: 0,
      xfin: (this.reqWidth - this.dx * this.nx) / 2,
      yfin: (this.reqHeight - this.dy * this.ny) / 2,
    };

    this.anim.xorg =
      parseFloat(this.polyPieces[0].pieces[0].theDiv.style.left) +
      this.dx;
    this.anim.yorg =
      parseFloat(this.polyPieces[0].pieces[0].theDiv.style.top) +
      this.dy;
    this.canvMobile.style.left = this.anim.xorg + 'px';
    this.canvMobile.style.top = this.anim.yorg + 'px';

    // hide pieces
    for (k = 0; k < this.polyPieces[0].pieces.length; k++) {
      this.polyPieces[0].pieces[k].theDiv.style.visibility = 'hidden';
    } // for k

    // launch final animation

    let dist = Math.sqrt(
      (this.anim.xorg - this.anim.xfin) *
        (this.anim.xorg - this.anim.xfin) +
        (this.anim.yorg - this.anim.yfin) *
          (this.anim.yorg - this.anim.yfin)
    );
    // we want a speed of about 100 pix / s
    // the time increment beeing of 20 ms, this leads to 100 * 0.02 = 2 pix / pass
    this.anim.cpt = dist / 2;
    // limit the duration to the range 0.25..2s, i.e.12..100 steps
    if (this.anim.cpt < 12) this.anim.cpt = 12;
    if (this.anim.cpt > 100) this.anim.cpt = 100;
    this.anim.cpt = Math.floor(this.anim.cpt);
    this.anim.tmr = window.setInterval(
      (function (puzz) {
        return function () {
          puzz.animateEnd();
        };
      })(this),
      20
    );
  }
  // mouseMove during game
  mouseMoveGame(event: {
    x: any;
    y: any;
    buttons: any;
    origin: any;
  }) {
    if (this.pieceMove === false) return;

    // for the case where button was released out of 'good' area
    if (event.origin === 'mouse') {
      if ((event.buttons & 1) === 0) {
        this.mouseUpGame(event);
        return;
      }
    }

    let x = event.x - this.mouseOffsX;
    let y = event.y - (this.mouseOffsY - window.scrollY);
    if (x < 2) x = 2;
    if (x > Math.floor(parseFloat(this.divBoard.style.width)) - 2)
      x = Math.floor(parseFloat(this.divBoard.style.width)) - 2;
    if (y < 2) y = 2;
    if (y > Math.floor(parseFloat(this.divBoard.style.height)) - 2)
      y = Math.floor(parseFloat(this.divBoard.style.height)) - 2;

    this.canvMobile.style.left = x - this.pieceMove.offsx + 'px';
    this.canvMobile.style.top = y - this.pieceMove.offsy + 'px';
  }

  // searches the pieces whick was clicked on
  // event is the click event
  // returned value : (index of PolyPiece + piece) or false (if not on a piece)
  lookForPiece(event: { x: number; y: number }) {
    let kp, kn;
    let x = event.x - this.mouseOffsX;
    let y = event.y - (this.mouseOffsY - window.scrollY);
    for (kp = this.polyPieces.length - 1; kp >= 0; kp--) {
      for (
        kn = this.polyPieces[kp].pieces.length - 1;
        kn >= 0;
        kn--
      ) {
        if (this.polyPieces[kp].pieces[kn].insidePiece(x, y))
          return { pp: kp, pc: kn };
      } // for kn
    } // for kp

    return false; // found nothing
  }
  // emphasizes a polyPiece
  // its idividualpieces are masked (style.visibility = "hidden")
  // but they are collectively drawn on canvMobile
  // parameter : polyPiece index
  emphasize(npp: any) {
    let kbcl, kc, k;
    let ppc = this.polyPieces[npp]; // current PolyPiece
    let ctx = this.canvMobile.getContext('2d');
    let loops = this.lookForLoops(ppc.pieces);
    let edge;

    ctx.save();
    ctx.clearRect(
      0,
      0,
      this.canvMobile.width,
      this.canvMobile.height
    );
    ctx.beginPath();
    for (kbcl = 0; kbcl < loops.length; kbcl++) {
      for (kc = 0; kc < loops[kbcl].length; kc++) {
        edge = loops[kbcl][kc];

        switch (edge.edge) {
          case 0:
            ppc.pieces[edge.kp].ts.drawPath(
              ctx,
              0,
              0,
              false,
              kc !== 0
            );
            break;
          case 1:
            ppc.pieces[edge.kp].rs.drawPath(
              ctx,
              0,
              0,
              false,
              kc !== 0
            );
            break;
          case 2:
            ppc.pieces[edge.kp].bs.drawPath(
              ctx,
              0,
              0,
              true,
              kc !== 0
            );
            break;
          case 3:
            ppc.pieces[edge.kp].ls.drawPath(
              ctx,
              0,
              0,
              true,
              kc !== 0
            );
            break;
        }
      } // for kc
    } // for kbcl;

    // make shadow
    ctx.fillStyle = 'none';
    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.shadowBlur = 4;
    ctx.shadowOffsetX = 4;
    ctx.shadowOffsetY = 4;
    ctx.fill();

    // add image clipped by path
    ctx.clip('evenodd');
    // reset shadow else FF does not clip image
    ctx.shadowColor = 'rgba(0, 0, 0, 0)';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;

    ctx.drawImage(
      this.image,
      this.mech.offsx,
      this.mech.offsy,
      this.width / this.mech.scale,
      this.height / this.mech.scale,
      0,
      0,
      this.width,
      this.height
    );

    // hide original PolyPiece
    for (k = 0; k < ppc.pieces.length; k++) {
      ppc.pieces[k].theDiv.style.visibility = 'hidden';
    } // for k

    ctx.restore();

    // set picture position to hide previous one
    this.canvMobile.style.left =
      ppc.pieces[0].where().x -
      (ppc.pieces[0].kx - 1) * this.dx +
      'px';
    this.canvMobile.style.top =
      ppc.pieces[0].where().y -
      (ppc.pieces[0].ky - 1) * this.dy +
      'px';
    this.canvMobile.style.visibility = 'visible';
  }

  // checks if p1 and p2 pieces are close to each other
  // dx is -1, 0 or 1 to check left, (top or bottom) or right side of p1
  // dy is -1, 0 or 1 to check top, (left or right) or bottom of p2
  near(
    p1: { where: () => any },
    p2: { where: () => any },
    dx: number,
    dy: number
  ) {
    let ou1 = p1.where();
    let ou2 = p2.where();

    if (Math.abs(ou1.x - ou2.x + dx * this.dx) > this.dCoupling)
      return false;
    if (Math.abs(ou1.y - ou2.y + dy * this.dy) > this.dCoupling)
      return false;
    return true;
  }

  /* 
  computes the number of lines and columns of the puzzle,
  finding the best compromise between the requested number of pieces
  and a square shap for pieces
*/
  computenxAndny() {
    let kx,
      ky,
      width = this.image.width,
      height = this.image.height,
      npieces = this.npieces;
    let err,
      errmin = 1e9;
    let ncv, nch;

    let nHPieces = Math.round(Math.sqrt((npieces * width) / height));
    let nVPieces = Math.round(npieces / nHPieces);

    /* based on the above estimation, we will try up to + / - 2 values
   and evaluate (arbitrary) quality criterion to keep best result
*/

    for (ky = 0; ky < 5; ky++) {
      ncv = nVPieces + ky - 2;
      for (kx = 0; kx < 5; kx++) {
        nch = nHPieces + kx - 2;
        err = (nch * height) / ncv / width;
        err = err + 1 / err - 2; // error on pieces dimensions ratio)
        err += Math.abs(1 - (nch * ncv) / npieces); // adds error on number of pieces

        if (err < errmin) {
          // keep smallest error
          errmin = err;
          this.nx = nch;
          this.ny = ncv;
        }
      } // for kx
    } // for ky
  }

  /* algorithm to determine the boundary of a PolyPiece
  input : a table of cells, hopefully defining a 'good' PolyPiece, i.e. all connected together
  every cell is given as an object {kx: indice, ky: indice} representing an element of a 2D array.

  returned value : table of Loops, because the boundary may be made of several
simple loops : there may be a 'hole' in a PolyPiece
every loop is a list of consecutive edges,
every edge if an object {kp: index, edge: b} where kp is the index of the cell ine
the input array, and edge the side (0(top), 1(right), 2(bottom), 3(left))
every edge contains kx and ky too, normally not used here
*/
}

export default Puzzle;
// Customizable Area End
