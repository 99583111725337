import React from "react";
// Customizable Area Start
import ProgramLayout from "./GenericComponents/ProgramLayout.web";
const configJSON = require("../config");
const { programCustomBannerImage, programCustomImage } = require("../assets");

const customPageContent = configJSON.ProgramsPageContent.customPage;

const makeBoldParaNo = 3;
const CustomPage = () => {
  return (
    <ProgramLayout
      makeBoldParaNo={makeBoldParaNo}
      bannerImage={programCustomBannerImage}
      contentImage={programCustomImage}
      programContent={customPageContent}
    />
  );
};
// Customizable Area End
export default CustomPage;
