import React from 'react';
// Customizable Area Start
import PageContentLayout from "../../PageContentLayout.web";
import SidebarLayout from "../../SidebarLayout.web";
import { MaterialPage } from "./Material.web";
import MaterialController, { S } from "./MaterialController.web";
import Loader from '../../../../../components/src/Loader.web';
// Customizable Area End

const configJSON = require("../../config.js");

export default class ParentGuide extends MaterialController {
    // Customizable Area Start
    async componentDidMount() {
        this.getParentGuideData();
    }
    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<S>): void {
        if (prevState.page !== this.state.page) {
            this.getParentGuideData();
        }
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const pageCount = this.state.ParentGuideData.page_options?.total_pages;
        return <>
            <SidebarLayout {...this.props} data-test-id='page' >
                <PageContentLayout title={configJSON.Material.ParentingGuide} >
                    {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                    <MaterialPage
                        states={this.state}
                        pageCount={pageCount}
                        handlePage={this.handlePage}
                    />
                </PageContentLayout>
            </SidebarLayout>
        </>
        // Customizable Area End
    }
}