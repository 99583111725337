import React from "react";
// Customizable Area Start
import { Box, Grid, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OurStory from "./OurStory.web";
import Vision from "./Vision.web";
import Values from "./Values.web";
import Mission from "./Mission.web";
import Founder from "./Founder.web";
import Experts from "./Experts.web";
import Team from "./Team.web";
const configJSON = require("../config");
const { aboutUsImage } = require("../assets");
const bgImages = require("../assets");
import Banner from "../../../../components/src/Banner";
import FlotingButton from "../../../../components/src/FlotingButton.web";
import Footer from "../../../../components/src/Footer.web";

interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
  multiLine?: string[];
}
// Customizable Area End
const { headerText, contentText } = configJSON.AboutUsPageContent.bannerText;
// Customizable Area Start
const content: Content = {
  headerText: headerText,
  contentText: contentText,
  fontSize: "48px",
  color: "#FFFFFF",
  secondColor: "#FFFFFF",
  paragraphFontSize: "1rem"
};
const useStyles = makeStyles(theme => ({
  relativeParent: {
    position: "relative",
    marginBottom: "100px"
  },
  ourStoryBg: {
    backgroundImage: `url(${bgImages.storyLeft}),url(${bgImages.storyRight})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left ,top right",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none"
    }
  },
  expertBg: {
    backgroundImage: `url(${bgImages.expertLeft}),url(${bgImages.expertRight})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 -225px ,right -225px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none"
    }
  },
  teamBg: {
    backgroundImage: `url(${bgImages.teamBottomLeft}),url(${
      bgImages.teamBottomRight
    })`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left bottom, right bottom",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none"
    }
  }
}));
const AboutUsPage = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.relativeParent}>
        <Banner content={content} bannerImage={aboutUsImage} showBtn={false} />
      </Box>
      <FlotingButton ourPartner={false} />
      <Box className={classes.ourStoryBg}>
        <Container>
          <Grid container>
            <OurStory />
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid container>
          <Vision />
        </Grid>
      </Container>
      <Box>
        <Container>
          <Grid container>
            <Mission />
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid container>
          <Values />
        </Grid>
      </Container>

      <Container>
        <Grid container>
          <Founder />
        </Grid>
      </Container>

      <Box className={classes.expertBg}>
          <Experts navigation={undefined} id={""} />
      </Box>
      <Box className={classes.teamBg}>
        <Container>
          <Team navigation={undefined} id={""} />
        </Container>
      </Box>
      <Box >
        <Footer />
      </Box>
    </>
  );
};
// Customizable Area End
export default AboutUsPage;
