// Customizable Area Start
import React, { useState, useEffect } from "react";
import { Box, Typography, Button, makeStyles } from "@material-ui/core";
import { Guidelines } from "./MPowerController";
// Customizable Area End
const configJSON = require("../config.js");

// Customizable Area Start
interface Props {
  guidelines: Guidelines[];
  sections: any[];
  handleClickSessionStart: (sectionId: number | null) => void;
}
// Customizable Area End

// Customizable Area Start
const MpowerGuidelines = (props: Props) => {
  // Customizable Area Start
  const {
    heading,
    boxStyle,
    subHeading,
    circle,
    btnStyle,
    leftAlign,
    isBtnDisable
  } = useStyles();
  const [sectionedNumber, setNumber] = useState<any>();

  const isSectionNotEmpty = props.sections?.length > 0;
  useEffect(() => {
    if (isSectionNotEmpty) {
      const newSection = props.sections?.find((obj: any) => obj.status === configJSON.MPower.Pending)
      setNumber(newSection?.id)
    } else {
      setNumber(null)
    }
  }, [props.sections])

  const btnDisable = !isSectionNotEmpty;

  return (
    <Box className={boxStyle}>
      <Box>
        <Typography className={heading}>{configJSON.MPower.MPowerCheckGuidelines} </Typography>
      </Box>
      <Box>
        {(props.guidelines?.length > 0) ?
          props.guidelines?.map((item: Guidelines, index: number) => {
            return (
              <Box key={item.id}>
                <Typography className={subHeading}>
                  <Box className={circle}>{index + 1}</Box>
                  <Typography className={leftAlign}>
                    {item.description}
                  </Typography>
                </Typography>
              </Box>
            );
          })
          : <Box>{configJSON.MPower.NoGuidelines}</Box>
        }
      </Box>
      <Box style={{ textAlign: "right" }}>
        <Button disabled={btnDisable} onClick={() => props.handleClickSessionStart(sectionedNumber)} className={`${btnStyle} ${btnDisable ? isBtnDisable : ''}`}>
          {configJSON.MPower.AgreeAndStart}
        </Button>
      </Box>
    </Box>
  );
  // Customizable Area End
};
export default MpowerGuidelines;
// Customizable Area End

// Customizable Area Start
const useStyles = makeStyles(() => ({
  heading: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "36px",
    color: "#0056A3",
    marginBottom: "15px",
  },
  boxStyle: {
    padding: "32px"
  },
  subHeading: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    padding: "20px 0",
    display: "flex",
    position: "relative"
  },
  leftAlign: {
    marginLeft: "36px"
  },
  circle: {
    width: "24px",
    height: "24px",
    background: " #0056A3",
    borderRadius: "12px",
    lineHeight: "24px",
    color: "#fff",
    fontSize: "12px",
    marginRight: "15px",
    position: "absolute",
    textAlign: "center"
  },
  btnStyle: {
    backgroundColor: " #0056A3",
    color: "#fff",
    fontStyle: "normal",
    fontWeight: 500,
    textTransform: "capitalize",
    fontSize: "16px",
    borderAadius: "10px",
    padding: "10px 20px",
    marginTop: "100px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: " #0056A3",
      color: "#fff"
    }
  },
  isBtnDisable: {
    backgroundColor: '#ddd',
    color: '#fff',
  },
}));
// Customizable Area End
