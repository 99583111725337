import React from "react";
// Customizable Area Start
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  question: {
    marginBottom: theme.spacing(2)
  },
  option: {
    marginTop: theme.spacing(1)
  },
  quest: {
    fontWeight: 600
  }
}));
interface Props {
  question: string;
  options: Array<{ content: string, id: string }>;
  answer: string;
  handleOption: (answer_id: string) => void; 
}

const MCQLayout = (props: Props) => {
  const { question, options, answer, handleOption} = props;
  const classes = useStyles();
  return (
    <Field answer={answer}>
      {() => (
        <FormControl component="fieldset" className={classes.question}>
          <Typography variant="body1" className={classes.quest}>
            {question}
          </Typography>
          <RadioGroup>
            {options.map(({ content, id }, index) => (
              <FormControlLabel
                key={index}
                value={content}
                control={<Radio color="primary" />}
                label={content}
                onChange={() =>  handleOption(id)}
                className={classes.option}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </Field>
  );
};
// Customizable Area End
export default MCQLayout;
