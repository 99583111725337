// Customizable Area Start
import React from "react";
import DateCalendar from "react-calendar";
import { calendarArrowRight } from "../assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export const CustomPrevIcon = () => (
  // Customizable Area Start
  <img
    style={{ transform: "scaleX(-1)" }}
    src={calendarArrowRight}
    alt="Prev Icon"
  />
  // Customizable Area End
);

// Customizable Area Start
// Customizable Area End

export const CustomNextIcon = () => (
  // Customizable Area Start
  <img src={calendarArrowRight} alt="Next Icon" />
  // Customizable Area End
);
// Customizable Area Start
const dayFormat = ["S", "M", "T", "W", "T", "F", "S"];
// Customizable Area End

export const CalendarCard = () => {
  // Customizable Area Start
  return (
    <DateCalendar
      prevLabel={<CustomPrevIcon />}
      nextLabel={<CustomNextIcon />}
      formatShortWeekday={(_locale: any, date: Date) =>
        dayFormat[date.getDay()]
      }
      value={new Date()}
      onChange={() => {}}
    />
  );
  // Customizable Area End
};

// Customizable Area Start
// Customizable Area End
