// Customizable Area Start
import Point from "../Point";
import Piece from "../Piece";

// class PolyPiece
// represents a group of pieces well positionned with respect  to each other.
// pckxmin, pckxmax, pckymin and pckymax record each a piece with highest kx, lowest kx...

class PolyPiece {
  pckxmin: Piece;
  pckxmax: Piece;
  pckymin: Piece;
  pckymax: Piece;
  pieces: any[];
  puzzle: any;
  p1: any;
  p2: any;

  constructor(initialPiece: Piece, puzz: any) {
    this.pckxmin = initialPiece;
    this.pckxmax = initialPiece;
    this.pckymin = initialPiece;
    this.pckymax = initialPiece;
    this.pieces = [initialPiece];
    this.puzzle = puzz;
  }

  /*
    this method
      - adds pieces of otherPoly to this PolyPiece
      - adjusts coordinates of new pieces to make them consistent with this polyPiece
      - does not re - evaluate the z - index of the polyPieces
  */
  merge(otherPoly: { pieces: string | any[] }) {
    // watch leftmost, topmost... pieces

    for (const piece of otherPoly.pieces) {
      this.pieces.push(piece);

      // watch leftmost, topmost... pieces
      if (piece.kx < this.pckxmin.kx) {
        this.pckxmin = piece;
      }
      if (piece.kx > this.pckxmax.kx) {
        this.pckxmax = piece;
      }
      if (piece.ky < this.pckymin.ky) {
        this.pckymin = piece;
      }
      if (piece.ky > this.pckymax.ky) {
        this.pckymax = piece;
      }
    }
    // for k

    this.moveTo(this.where()); // to set positions of new pieces

    // sort the pieces by increasing kx, ky

    this.braakFunc(this.pieces);
  }

  braakFunc(pieces: any) {
    pieces.sort(function(p1: any, p2: any) {
      if (p1.ky < p2.ky) return -1;
      if (p1.ky > p2.ky) return 1;
      if (p1.kx < p2.kx) return -1;
      if (p1.kx > p2.kx) return 1;
      return 0; // should not occur
    });
  }

  where() {
    return this.pieces[0].where();
  }

  // -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -  -   -
  // the position of the PolyPiece is this of its 1st piece
  // the position of the others is evaluated on this basis
  moveTo(pnt: { x: number; y: number }) {
    this.pieces[0].moveTo(pnt);
    for (let kp = 1; kp < this.pieces.length; ++kp) {
      this.pieces[kp].moveTo(
        new Point(
          pnt.x + this.puzzle.dx * (this.pieces[kp].kx - this.pieces[0].kx),
          pnt.y + this.puzzle.dy * (this.pieces[kp].ky - this.pieces[0].ky)
        )
      );
    } // for kp
  }

  ifNear(otherPoly: { pieces: string | any[] }) {
    let p1, p2;
    for (const piece1 of this.pieces) {
      p1 = piece1;
      for (const piece2 of otherPoly.pieces) {
        p2 = piece2;
        // p2 above p1 ?

        if (this.breakFunc1(p1, p2)) {
          return true;
        }
      } // for piece2
    } // for piece1

    return false; // no, not near
  }

  breakFunc1(p1: any, p2: any) {
    if (
      p1.kx === p2.kx &&
      p1.ky === p2.ky + 1 &&
      this.puzzle.near(p1, p2, 0, -1)
    )
      return true;
    // p2 below p1 ?
    if (
      p1.kx === p2.kx &&
      p1.ky === p2.ky - 1 &&
      this.puzzle.near(p1, p2, 0, 1)
    )
      return true;
    // p2 left of p1 ?
    if (
      p1.kx - 1 === p2.kx &&
      p1.ky === p2.ky &&
      this.puzzle.near(p1, p2, -1, 0)
    )
      return true;
    // p2 right of p1 ?
    if (
      p1.kx + 1 === p2.kx &&
      p1.ky === p2.ky &&
      this.puzzle.near(p1, p2, +1, 0)
    )
      return true;
  }
}

export default PolyPiece;
// Customizable Area End
