import React, { useState } from "react";
// Customizable Area Start
import OtpInput from "./OtpInput.web";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { mailVerify } from "./assets";
// Customizable Area End
// Customizable Area Start
interface Props {
  handleSubmit: (otp: string) => void;
  email: string;
  otpError: boolean;
  resendOtp: () => void;
  otpSent: boolean;
}
const OtpPage = (props: Props) => {
  const [otp, setOtp] = useState("");
  const [otpcheck, setOtpStatus] = useState(false);
  const handleOtpChange = (newOtp: string) => {
    setOtp(newOtp);
    if(newOtp.length === 6){
      setOtpStatus(true);
    }else{
      setOtpStatus(false);
    }
  };
  const {
    stepHeading,
    stepPara,
    btnRight,
    checkIcon,
    stepImage,
    formBox,
    stepBtn,
    btnTop,
    errorMessage,
    success,
    allOTPValue,
  } = webStyles;
  return (
    <Box>
      <Box style={{ textAlign: "center" }}>
        <Typography style={stepHeading}>Verify Your Email ID </Typography>
        <Typography style={stepPara}>
          Enter the OTP sent on your email id: {props.email}
          <br />
          to verify your account
        </Typography>
      </Box>
      <Box style={stepImage}>
        <img src={mailVerify} />
      </Box>
      <Box style={formBox}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item md={12}>
            <Box
              style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <OtpInput
                otpError={props.otpError}
                length={6}
                onChange={handleOtpChange}
              />
              <CheckCircleIcon style={otpcheck ? allOTPValue :checkIcon} />
            </Box>
            {props.otpError && (
              <p data-test-id="otp-not-match" style={errorMessage}>
                OTP is not matching
              </p>
            )}
            {props.otpSent && <p style={success}>OTP sent</p>}
            <Typography
              data-test-id="resend-otp"
              style={{
                textAlign: "right",
                color: "#0056A3",
                padding: "10px 0px",
                fontSize: "12px",
                cursor: "pointer"
              }}
              variant="body2"
              onClick={props.resendOtp}
            >
              Didn’t receive OTP? Resend
            </Typography>
          </Grid>
          <Grid container item md={12} justifyContent="flex-end">
            <Box style={btnTop}>
              {" "}
              <Box style={btnRight}>
                <Button
                  disabled={!otpcheck}
                  data-test-id="btnVerifyOtp"
                  style={{ textTransform: "capitalize", ...stepBtn }}
                  fullWidth
                  onClick={() => props.handleSubmit(otp)}
                >
                  Verify
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
// Customizable Area End
export default OtpPage;
// Customizable Area Start
const webStyles = {
  stepHeading: {
    fontSize: "40px",
    color: "#0056A3",
    fontWeight: "bold"
  },
  stepPara: {
    color: "#0056A3"
  },
  btnRight: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
  },
  checkIcon: {
    color: "#979797",
    width: "50px",
    fontSize: "33px"
  },
  allOTPValue: {
    color: "#20BF55",
    width: "50px",
    fontSize: "33px"
  },
  stepImage: {
    margin: "auto",
    display: "flex",
    justifyContent: "center"
  },
  formBox: {
    maxWidth: "450px",
    margin: "auto",
    paddingTop: "30px"
  },
  stepBtn: {
    backgroundColor: "#0056A3",
    color: "#fff",
    borderRadius: "10px",
    width: "175px"
  },
  btnTop: {
    marginTop: "25px"
  },
  errorMessage: {
    color: "red",
    fontSize: "small",
    paddingLeft: "10px"
  },
  success: {
    color: "green",
    fontSize: "small",
    paddingLeft: "10px"
  }
};
// Customizable Area End