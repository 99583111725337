import React from "react";
// Customizable Area Start
import MindMasteryController from "./MindMasteryController.web";
import { Box, Button, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  bullet,
  calendarIcon,
  clockIcon,
  profile
} from "../assets";
import PageContentLayout from "../PageContentLayout.web";
import Plyr from "plyr";
import SidebarLayout from "../SidebarLayout.web";
import ReadMore from "../../../../components/src/ReadMore";
import Loader from "../../../../components/src/Loader.web";
// Customizable Area End

const configJSON = require("../config.js");

// Customizable Area Start
const useStyles = makeStyles(theme => ({
  courseDetailsTop: {
    background: "#EBEBEB",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "32px",
    "@media(max-width:767px)": {
      flexDirection: "column",
      alignItems: "center",
      padding: "32px"
    },
    "@media(max-width:576px)": {
      padding: "30px"
    }
  },
  mindMasteryWrapper: {
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "2px 10px 16px rgba(0, 0, 0, 0.0908271)",
    borderRadius: "12px"
  },
  videoWrapper: {
    width: "400px",
    "&>.plyr--video": {
      borderRadius: "8px"
    },
    "@media(max-width:960px)": {
      // width: '450px'
    },
    "@media(max-width:767px)": {
      width: "auto",
      height: 'auto',
    }
  },
  courseDetailsText: {
    maxWidth: "430px",
    width: "100%",
    paddingRight: "10px",
    "@media(max-width:767px)": {
      width: "auto",
      padding: "0",
      margin: "0 auto 30px 0",
      maxWidth: 'none'
    }
  },
  courseDetailsTitle: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#0056A3",
    fontFamily: "Poppins",
    textTransform: 'capitalize',
    marginBottom: '14px',
    "@media(max-width:1081px)": {
      fontSize: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: "22px"
    },
    "@media(max-width:575px)": {
      fontSize: '20px'
    }
  },
  courseTitle: {
    fontWeight: 700,
    fontSize: "24px",
    color: "#0056A3",
    fontFamily: "Poppins",
    marginBottom: '10px',
    "@media(max-width:1081px)": {
      fontSize: "24px",
    },
    "@media(max-width:767px)": {
      fontSize: "22px",
      marginBottom: '14px'
    },
    "@media(max-width:575px)": {
      fontSize: '20px'
    }
  },
  courseDetailsDescription: {
    fontWeight: 400,
    fontSize: "14px",
    color: '#3B3B3B',
    fontFamily: "Poppins",
  },
  courseDetailsWrapper: {
    padding: "40px 32px 36px 32px",
    "@media(max-width:767px)": {
      padding: "32px"
    },
    "@media(max-width:576px)": {
      padding: "30px"
    }
  },
  aboutCourseFocusedText: {
    fontWeight: 600,
    fontSize: "14px"
  },
  aboutCourseDescription: {
    fontWeight: 400,
    fontSize: "14px",
    color: '#3B3B3B',
    fontFamily: "Poppins",
  },
  courseDetailsTextWrapper: {
    marginBottom: "20px"
  },
  cardWrapper: {
    display: "flex",
    alignItems: "center",
    maxWidth: "321px",
    width: "100%",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "8px",
    border: "0.2px solid #e3e3e3"
  },
  cardImgWrapper: {
    marginRight: "16px",
    height: "78px",
    width: "78px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  cardContentWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
    "@media(max-width:575px)": {
      fontSize: '20px',
      lineHeight: '24px'
    }
  },
  cardSubtitle: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
  },
  cardGridWrapper: {
    marginBottom: "48px",
    alignItems: "center"
  },
  learningOutcome: {
    border: "0.2px solid #e3e3e3",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "12px",
    padding: "24px",
  },
  outcomeHeading: {
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
    marginBottom: "20px",
    "@media(max-width:575px)": {
      fontSize: '20px'
    }
  },
  outcomeList: {
    listStyleImage: `url(${bullet})`,
    margin: "0"
  },
  outcomeListItem: {
    marginBottom: "18px",
    paddingLeft: "10px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
  },
  sessionDetailsCardWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "@media(max-width:1140px)": {
      marginBottom: "20px"
    }
  },
  sessionDetailsCard: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "8px",
    minWidth: "233px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    border: "0.2px solid #e3e3e3"
  },
  sessionDetailsCardImgWrapper: {
    height: "80px",
    width: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  sessionDetailsCardText: {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "30px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
  },
  courseStructureWrapper: {
    paddingTop: '32px'
  },
  courseCardsWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "748px",
    marginBottom: "40px",
    "@media(max-width:1140px)": {
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "unset"
    }
  },
  sessionDetailsCardTitle: {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "36px",
    color: "#0056A3",
    fontFamily: "Poppins",
    marginBottom: '10px',
    "@media(max-width:1140px)": {
      fontSize: '18px',
      lineHeight: '30px'
    },
    "@media(max-width:575px)": {
      fontSize: '16px'
    }
  },
  sessionsVideoListWrapper: {
    position: "relative",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "12px",
    padding: "32px 32px 0px 32px",
    "@media(max-width:767px)": {
      padding: "32px"
    }
  },
  videoTitleCard: {
    display: "flex",
    alignItems: "center",
    marginBottom: "24px"
  },
  videoNumberBox: {
    minWidth: "64px",
    minHeight: "64px",
    background:
      "linear-gradient(134.86deg, #B3DBFF -36.53%, #4B90CD 17.76%, #0156A2 95.37%)",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: "16px"
  },
  videoNumber: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "30px",
    color: "white",
    fontFamily: "Poppins",
  },
  videNumberBoxSubtitle: {
    color: "white",
    fontFamily: "Poppins",
  },
  videoCardTitle: {
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
    "@media(max-width:767px)": {
      fontSize: "18px",
      lineHeight: '24px'
    },
    "@media(max-width:575px)": {
      fontSize: "16px",
      lineHeight: '22px'
    }
  },
  viewAllBtn: {
    position: "absolute",
    right: "32px",
    "&.MuiButton-root": {
      background: "#0056A3",
      borderRadius: "6px",
      color: "white",
      fontFamily: "Poppins",
      textTransform: "capitalize",
      height: "36px",
      width: "88px"
    },
    "@media(max-width:767px)": {
      bottom: "10px"
    }
  },
  cardGrid: {
    [theme.breakpoints.down("md")]: {
      "&.MuiGrid-root": {
        display: "flex",
      }
    }
  },
  courseOverViewImg: {
    borderRadius: "50%",
    width: "48px",
    height: "48px"
  }
}));
// Customizable Area End

// Customizable Area Start
interface CourseReviewCardProps {
  image: string;
  cardTitle: string;
  cardSubtitle: string;
}

export const CourseReviewCard = (props: CourseReviewCardProps) => {
  const { image, cardTitle, cardSubtitle } = props;
  const classes = useStyles();
  return (
    <Box className={classes.cardWrapper} data-test-id='box' >
      <Box className={classes.cardImgWrapper}>
        <img
          src={image}
          alt="course_overview_icon"
          className={classes.courseOverViewImg}
        />
      </Box>
      <Box className={classes.cardContentWrapper}>
        <Typography className={classes.cardTitle}>{cardTitle}</Typography>
        <Typography className={classes.cardSubtitle}>{cardSubtitle}</Typography>
      </Box>
    </Box>
  );
};

interface SessionDetailsCardProps {
  cardTitle: string;
  image: string;
  cardText: string;
}

export const SessionDetailsCard = (props: SessionDetailsCardProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.sessionDetailsCardWrapper} data-test-id='box'>
      <Typography className={classes.sessionDetailsCardTitle}>
        {props.cardTitle}
      </Typography>
      <Box className={classes.sessionDetailsCard}>
        <Box className={classes.sessionDetailsCardImgWrapper}>
          <img src={props.image} alt="sessionDetailsCardImage" />
        </Box>
        <Typography className={classes.sessionDetailsCardText}>
          {props.cardText}
        </Typography>
      </Box>
    </Box>
  );
};

interface VideoSessionCardProps {
  id: any;
  title: string
}

export const VideoSessionCard = (props: VideoSessionCardProps) => {
  const classes = useStyles();
  const index = String(props.id);

  return (
    <Box className={classes.videoTitleCard} data-test-id='box'>
      <Box className={classes.videoNumberBox}>
        <Typography className={classes.videoNumber}>{index.padStart(2, '0')}</Typography>
        <Typography
          variant="subtitle2"
          className={classes.videNumberBoxSubtitle}
        >
          {configJSON.MindMasteryTexts.Session}
        </Typography>
      </Box>
      <Typography className={classes.videoCardTitle}>{props.title}</Typography>
    </Box>
  );
};



interface CourseDetailsTopProps {
  videoRef: any;
  data: {
    attributes: {
      title: string;
      description: string;
    }
  }
}

export const CourseDetailsTop = (props: CourseDetailsTopProps) => {
  const classes = useStyles();
  const { data } = props;
  const attributes = data?.attributes;
  const description = attributes?.description;
  return (<Box className={classes.courseDetailsTop} data-test-id='box'>
    <Box className={classes.courseDetailsText}>
      <Typography className={classes.courseTitle}>
        {attributes?.title}
      </Typography>
      <Typography
        variant="body2"
        className={classes.courseDetailsDescription}
      >
        {description && (description.length < 250 ? description : <ReadMore sliceLength={250} textColor='#3B3B3B'>{description}</ReadMore>)}
      </Typography>
    </Box>
    <div className="courseDetailsVideoWrapper">
      <Box className={classes.videoWrapper}>
        <video ref={props.videoRef} />
      </Box>
    </div>
  </Box>
  );
};

interface VideoSessionCardsProps {
  attributes: {
    course_sessions: [{
      attributes: {
        name: string;
        id: any;
      }
    }];
  }
  sliceStart: number;
  sliceEnd: number;
  idx: any
}

export const VideoSessionCards = (props: VideoSessionCardsProps) => {
  const { attributes, sliceStart, sliceEnd, idx } = props;
  return (
    <Grid item sm={12} xs={12} lg={6} md={6} xl={6} data-test-id='box'>
      {attributes?.course_sessions
        .slice(sliceStart, sliceEnd)
        .map((element: any, index: number) => {
          return (
            <VideoSessionCard
              id={index + idx}
              title={element.attributes.name}
              key={element.attributes.id}
            />
          );
        })}
    </Grid>
  );
};

interface LearningOutComesProps {
  attributes: {
    learning_outcomes: [
      {
        id: any;
        outcome: string
      }
    ]
  },
  sliceStart: number;
  sliceEnd: number;
}

export const LearningOutComes = (props: LearningOutComesProps) => {
  const classes = useStyles();
  const { attributes, sliceStart, sliceEnd } = props;
  return (
    <Grid item lg={6} xl={6} md={6} sm={12} xs={12} data-test-id='box'>
      <ul className={classes.outcomeList}>
        {attributes?.learning_outcomes
          .slice(sliceStart, sliceEnd)
          .map((element: any) => {
            return (
              <li key={element.id} className={classes.outcomeListItem}>
                {element.outcome}
              </li>
            );
          })}
      </ul>
    </Grid>
  );
};

interface CourseDetailsContentProps {
  goTo: (module: string, params?: Object) => void;
  courseId: any;
  data: {
    attributes: {
      course_sessions: [{
        attributes: {
          name: string;
          mind_mastery_course_id:number;
          id: number;
          created_at:string;
          updated_at:string;
        }
      }];
      learning_outcomes: [
        {
          id: any;
          outcome: string
        }
      ];
      online_duration: string;
      offline_duration: string;
      educator: {
        image_url: string;
        full_name: string;
      };
      about: string;
    }
  }
}

export const CourseDetailsContent = (props: CourseDetailsContentProps) => {
  const classes = useStyles();
  const { data } = props;
  const attributes = data?.attributes;
  const courseSessionLength = String(
    attributes?.course_sessions?.length
  );
  const learningOutcomelength =
    attributes?.learning_outcomes?.length;
  const sessionDetails = [
    {
      image: calendarIcon,
      cardText: courseSessionLength.padStart(2, "0"),
      cardHeading: configJSON.MindMasteryTexts.Sessions
    },
    {
      image: clockIcon,
      cardText: `${attributes?.online_duration} min`,
      cardHeading: configJSON.MindMasteryTexts.Online
    },
    {
      image: clockIcon,
      cardText: `${attributes?.offline_duration} min`,
      cardHeading: configJSON.MindMasteryTexts.Offline
    }
  ];
  const educator = attributes?.educator;
  const courseReviewDetails = [
    {
      image: educator?.image_url || profile,
      cardHeading: configJSON.MindMasteryTexts.Trainer,
      cardSubtitle: educator?.full_name
    }
  ];

  const learningOutComeData = [{ sliceStart: 0, sliceEnd: Math.ceil(learningOutcomelength / 2), idx: 1 }, { sliceStart: Math.ceil(learningOutcomelength / 2), sliceEnd: learningOutcomelength, idx: 1 }]
  const videoSessionsCardData = [{ sliceStart: 0, sliceEnd: 3, idx: 1 }, { sliceStart: 3, sliceEnd: 6, idx: 4 }]
  const aboutDescription = attributes?.about
  return (<>
    <Box className={classes.courseDetailsWrapper} data-test-id='box' >
      <Box className={classes.courseDetailsTextWrapper}>
        <Typography className={classes.courseDetailsTitle}>
          {configJSON.MindMasteryTexts.AboutCourse}
        </Typography>
        <Typography className={classes.aboutCourseDescription}>
          {aboutDescription && (aboutDescription.length < 1000 ? aboutDescription : <ReadMore sliceLength={1000} textColor='#3B3B3B'>{aboutDescription}</ReadMore>)}
        </Typography>
      </Box>
      <Grid container spacing={2} className={classes.cardGridWrapper}>
        {courseReviewDetails.map(element => {
          return (
            <Grid
              item
              className={classes.cardGrid}
              xl={6}
              lg={6}
              md={12}
              sm={12}
              xs={12}
              key={element.image}
            >
              <CourseReviewCard
                image={element.image}
                cardTitle={element.cardHeading}
                cardSubtitle={element.cardSubtitle}
              />
            </Grid>
          );
        })}
      </Grid>
      {learningOutcomelength > 0 && <Box className={classes.learningOutcome}>
        <Typography className={classes.outcomeHeading}>
          Learning Outcomes :
        </Typography>
        <Grid container>
          {learningOutComeData.map((element) => {
            const learningOutcomeProps = {
              key: element.idx,
              attributes: attributes,
              sliceStart: element.sliceStart,
              sliceEnd: element.sliceEnd
            }
            return <LearningOutComes {...learningOutcomeProps} />
          })}
        </Grid>
      </Box>}
      <Box className={classes.courseStructureWrapper}>
        <Typography className={classes.courseDetailsTitle}>
          {configJSON.MindMasteryTexts.CourseStructure}
        </Typography>

        <Box className={classes.courseCardsWrapper}>
          {sessionDetails.map(element => {
            return (
              <SessionDetailsCard
                key={element.cardHeading}
                image={element.image}
                cardTitle={element.cardHeading}
                cardText={element.cardText} />
            );
          })}
        </Box>
        <Box className={classes.sessionsVideoListWrapper}>

          {attributes?.course_sessions?.length > 0 && <>
            <Button
              className={classes.viewAllBtn}
              onClick={() => props.goTo(configJSON.MindMasteryTexts.SessionListPage, { courseId: props.courseId })}
            >
              {configJSON.MindMasteryTexts.ViewAll}
            </Button>

            <Grid container>
              {videoSessionsCardData.map(element => {
                return (
                  <VideoSessionCards
                    key={element.idx}
                    attributes={attributes}
                    sliceStart={element.sliceStart}
                    sliceEnd={element.sliceEnd}
                    idx={element.idx}
                  />
                );
              })}
            </Grid>
          </>}
        </Box>
      </Box>
    </Box>
  </>
  );
};
// Customizable Area End
export default class CourseDetails extends MindMasteryController {
  // Customizable Area Start
  videoRef: any;

  player: Plyr = new Plyr("test");
  constructor(props: any) {
    super(props);
    this.videoRef = React.createRef();
  }

  async componentDidMount(): Promise<void> {
    window.scrollTo(0, 0);
    const courseId = Number(this.props.navigation.getParam('courseId'))
    const data: any = await this.getCourseDetails(courseId)
    const attributes = data?.attributes;
    const options = {
      controls: configJSON.MindMasteryTexts.Controls,
      speed: { selected: 1, options: configJSON.MindMasteryTexts.PlayBackSpeed }
    };
    this.player = new Plyr(this.videoRef.current, options);
    this.player.source = {
      type: configJSON.MindMasteryTexts.Video,
      sources: [
        {
          src: attributes?.video_url,
          size: 480
        }
      ],
      poster: attributes?.thumbnail_url
    };

    // ref https://github.com/sampotts/plyr/issues/1297
    const videoWrapper = document.querySelector(
      ".plyr--stopped"
    ) as HTMLElement;
    const controlsWrapper = videoWrapper.querySelector(
      ".plyr__controls"
    ) as HTMLElement;
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.attributeName === "class") {
          if (videoWrapper.classList.contains("plyr--stopped")) {
            controlsWrapper.style.display = "none";
          } else {
            controlsWrapper.style.display = "";
          }
        }
      });
    });
    observer.observe(videoWrapper, { attributes: true });
  }

  async componentWillUnmount(): Promise<void> {
    this.player.destroy();
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const courseDetailsPageData = this.state.courseDetailsPageData;
    const courseId = Number(this.props.navigation.getParam("courseId"));
    // Customizable Area End
    return (
      // Customizable Area Start
      <SidebarLayout {...this.props}>
        {this.state.isLoading ? (
          <Loader loading={this.state.isLoading} />
        ) : null}
        <PageContentLayout goBack={this.goBack} title="Mind Mastery" subtitle={'Concept Revision'}>
          <CourseDetailsTop
            data-test-id='top'
            data={courseDetailsPageData}
            videoRef={this.videoRef}
          />
          <CourseDetailsContent
            data={courseDetailsPageData}
            courseId={courseId}
            goTo={this.goTo}
          />
        </PageContentLayout>
      </SidebarLayout>
      // Customizable Area End
    );
  }
}
