import React from "react";
// Customizable Area Start
import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const configJSON = require("../config");
const {
  safetyParentBanner1,
  safetyParentBanner2,
  safetyParentBanner3,
  safetyChildrenBanner,
  safetySchoolBanner
} = require("../assets");
import Banner from "../../../../components/src/Banner";
import TabSwitch from "./GenericComponents/TabSwitch.web";
import LandingPageController, { Props, SafetyIQ }  from "../LandingPageController";
import FlotingButton from "../../../../components/src/FlotingButton.web";
import Footer from "../../../../components/src/Footer.web";
// Customizable Area End

// Customizable Area Start
interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
  multiLine?: string[];
}

interface DetailsOFTheSafetyIQ{
  safetyIQDetails: SafetyIQ[],
  changeQuestionByType: (type: string, age: string) => void;
  submitSection:(finalAnswers:any)=>void,
  states:any
}

const { heading, content } = configJSON.SafetyIQPageContent;

const useStyles = makeStyles(() => ({
  relativeParent: {
    position: "relative"
  },
  containerStyle: {
    justifyContent: "center",
    alignItems: "flex-end"
  }
}));

const SafetyIQPageDetails = (props: DetailsOFTheSafetyIQ) => {
  const [bannerContentText, setbannerContentText] = React.useState<any>(content.parent);
  const bannerContent: Content = {
    headerText: heading,
    contentText: bannerContentText,
    fontSize: "48px",
    color: "#FFFFFF",
    secondColor: "#FFFFFF",
    paragraphFontSize: "1rem"
  };
  const [bannerImg, setBannerImg] = React.useState<any>(safetyParentBanner1);
  const changeBanner = (value: any) => {
    switch (value) {
      case 0:
        setbannerContentText(content.parent);
        setBannerImg(safetyParentBanner1);
        break;
      case 1:
        setbannerContentText(content.children);
        setBannerImg(safetyChildrenBanner);
        break;
      case 2:
        setbannerContentText(content.school_owners);
        setBannerImg(safetySchoolBanner);
        break;

      case 3:
        setBannerImg(safetyParentBanner2);
        break;
      case 4:
        setBannerImg(safetyParentBanner3);
        break;
      default:
        break;
    }
  };
  const classes = useStyles();
  return (
    <>
      <Box className={classes.relativeParent}>
        <Banner
          content={bannerContent}
          bannerImage={bannerImg}
          showBtn={false}
        />
        <FlotingButton ourPartner={false} />
        <Box>
          <Container className={classes.containerStyle}>
            <TabSwitch states={props.states} changeBanner={changeBanner} submitSection={props.submitSection} safetyIQDetails={props.safetyIQDetails} changeQuestion={props.changeQuestionByType} />
          </Container>
        </Box>
      </Box>
      <Box >
        <Footer />
      </Box>
    </>
  );
};
// Customizable Area End
class SafetyIQPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  render() {
    const safetyIQData = {
      safetyIQDetails: this.state.safetyIQDetails,
      changeQuestionByType:this.getSafetyIQDetails,
      submitSection:this.submitSection,
      states:this.state,
    };
    return <SafetyIQPageDetails  {...safetyIQData} />;
  }
  // Customizable Area End
}

export default SafetyIQPage;
