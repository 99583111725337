// Customizable Area Start
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  makeStyles,
  Card,
  Grid,
  Tab,
  TextField,
  Tabs,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@material-ui/icons";
import SidebarLayout from "../SidebarLayout.web";
import UploadAttachment from "../YouMatter/UploadAttachment.web";
import QuestionsController, {
  Question,
  QuestionType,
} from "./QuestionsController";
import { attachmentClip } from "../assets";
import { CheckedIcon, Icon } from "../YouMatter/YouMatterQuestions.web";
import InfoModal from "../CommonComponents/InformationModal.web";
import "react-jigsaw-puzzle/lib/jigsaw-puzzle.css";
const configJSON = require("../config");
import Puzzle from "../../../dashboard/src/YouMatter/Items/Puzzle/index";
// Customizable Area End

// Customizable Area Start
interface SessionData {
  questionLength?: number;
  questions?: Question[];
  screen?: string;
}
interface SectionItem {
  id: number;
  status: string;
}
// Customizable Area End

const QuestionsView = (props: SessionData | any) => {
  // Customizable Area Start
  const {
    textArea,
    optionWrapper,
    qBox,
    blueBox,
    boxPara,
    cardBox,
    btnStyle,
    enableBtn,
    btnDisable,
    question,
    SelectHeading,
    submitBtnNextPrevLayout,
    nextPrevBtnBox,
    btnLayout,
    SelectHeadingBox,
    attachFlexContainer,
    attachBtn,
    questionInfoImage,
    uploadImg,
    optionTypoStyle,
    optionNumberStyle,
    mPowerTabViewWrapper,
    questionText,
    selectedOption,
    answerOption,
    tabs,
    tab,
    tabContentWrapper,
    fileName,
  } = useStyles();
  const [selectedQuestion, setSelectedQuestion] = useState(0);
  const [isQuestionView, questionViewSelected] = React.useState(false);
  const [firstIndexValue, firstIndexCall] = useState(0);
  const [lastIndexValue, lastIndexCall] = useState(props.questionLength);
  const [activeNumber, activeQuestion] = useState(0);
  const [activeSection, setActiveSection] = useState(0);
  const [value, setValue] = useState(0);
  const [questionId, setQuestionsId] = useState<number[]>([]);
  const alphChars = ["A", "B", "C", "D", "E"];
  const [finalAnswers, setfinalAnswers] = useState<any>({});
  let isBtndisabled;

  const handleChoiceChange = (
    questionId: number | string,
    question_type: string,
    choiceId: string
  ) => {
    const preAns = finalAnswers[questionId];
    setfinalAnswers((prevAnswers: any) => ({
      ...prevAnswers,
      [questionId]: {
        ...preAns,
        question_id: questionId,
        question_type: question_type,
        choice_id: choiceId,
        mpower_checks_section_id: activeSection, // Change to the appropriate section ID
      },
    }));
  };

  const handleSolutionChange = (
    questionId: number | string,
    question_type: string,
    solution: string | boolean
  ) => {
    const preAns = finalAnswers[questionId];
    if (solution) {
      setfinalAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        [questionId]: {
          ...preAns,
          question_id: questionId,
          question_type: question_type,
          solution: solution,
          mpower_checks_section_id: activeSection, // Change to the appropriate section ID
        },
      }));
    } else {
      setfinalAnswers((prevAnswers: any) => {
        const updatedAnswers = { ...prevAnswers };
        delete updatedAnswers[questionId];
        return updatedAnswers;
      });
    }
  };

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `m-power-tab-${index}`,
      "aria-controls": `m-power-tabpanel-${index}`,
    };
  };

  const handleQuestionClick = (questionId: number, active: number) => {
    if (isQuestionView) {
      firstIndexCall(active);
      lastIndexCall(active + 1);
    }
    setSelectedQuestion(questionId);
    activeQuestion(active);
  };

  const checkQuestionView = () => {
    questionViewSelected(!isQuestionView);
  };

  useEffect(() => {
    updateIndex();
  }, [isQuestionView]);

  const updateIndex = () => {
    if (isQuestionView) {
      if (activeNumber >= 1) {
        firstIndexCall(activeNumber);
        lastIndexCall(activeNumber + 1);
      } else {
        lastIndexCall(1);
      }
    } else {
      firstIndexCall(0);
      lastIndexCall(props.questionLength);
    }
  };

  useEffect(() => {
    props?.questions[0]?.data.attributes.questions.data.forEach(
      (questionDetails: QuestionType) => {
        setQuestionsId((prev) => [...prev, Number(questionDetails?.id)]);
      }
    );
    lastIndexCall(props.questionLength);
    getActiveSectionId();
  }, [props?.questions[0]?.data]);

  const handleSubmit = () => {
    props.handleMpowerSubmit();
  };

  const submitForm = () => {
    const submissionObject = { answers: Object.values(finalAnswers) };
    props.submitSection(submissionObject);
  };

  useEffect(() => {
    if (props.states.tempAttachmentUrl) {
      const preAns = finalAnswers[props.states.attachmentQuestionId];
      setfinalAnswers((prevAnswers: any) => ({
        ...prevAnswers,
        [props.states.attachmentQuestionId]: {
          ...preAns,
          document_file:
            props.states.answers[props.states.attachmentQuestionId]?.attachment, // Change to the appropriate section ID
        },
      }));
    }
  }, [props.states.tempAttachmentUrl]);

  isBtndisabled = props.questionLength !== Object.values(finalAnswers).length;
  const getActiveSectionId = () => {
    const selectedSectionID = JSON.parse(
      localStorage.getItem("mpowerData") || "{}"
    )?.sectionId;
    props?.questions[0]?.data?.attributes?.remaining_sections?.map(
      (ids: SectionItem, index: number) => {
        if (selectedSectionID == ids.id) {
          setValue(index);
        }
      }
    );
    setActiveSection(selectedSectionID);
  };

  const handleQuestionChange = (mode: string) => {
    if (mode === "increase") {
      firstIndexCall(firstIndexValue + 1);
      lastIndexCall(lastIndexValue + 1);
      activeQuestion(activeNumber + 1);
    } else {
      firstIndexCall(firstIndexValue - 1);
      lastIndexCall(lastIndexValue - 1);
      activeQuestion(activeNumber - 1);
    }
  };

  const handleCondition = (questionInfo: any) => {
    const puzzleCondition = !finalAnswers[questionInfo?.attributes?.id]
      ?.solution
      ? "Solve The Puzzle"
      : "";
    const puzzle2 =
      questionInfo.attributes.question_type === "puzzle"
        ? puzzleCondition
        : "Select Your Solution";
    return questionInfo.attributes.question_type === "text_based"
      ? " Enter Your Solution"
      : puzzle2;
  };

  const solved = (questionInfo: any) => {
    return (
      finalAnswers[questionInfo?.attributes?.id]?.solution === true && (
        <Box className={selectedOption}>
          <span className={answerOption}>Solved</span>
        </Box>
      )
    );
  };

  const PuzzleWork = ({ questionInfo }: any) => {
    useEffect(() => {
      const handlePuzzleCompletion = () => {
        // Your logic for handling puzzle completion goes here
        handleSolutionChange(
          questionInfo.attributes.id,
          questionInfo.attributes.question_type,
          true
        );
      };

      const divElement: any = document.querySelector("#puzzalContainer");
      if (questionInfo.attributes.document_file) {
        const newPuzzle = new Puzzle({
          img: questionInfo.attributes.document_file,
          width: divElement?.offsetWidth,
          height: divElement?.offsetHeight,
          npieces:
            (questionInfo.attributes?.no_of_pieces || 4),
          idiv: "forPuzzle",
          onCompletion: handlePuzzleCompletion,
        });
        newPuzzle.runPuzzle();
      }
    }, [questionInfo.attributes.document_file]);

    const style = ` 

    
.jigsaw-puzzle {
    position: relative;
    background-image: url(${questionInfo.attributes.reference_document_file});
    background-size: contain;
}


`;

    return (
      <>
        {questionInfo.attributes.question_type === "puzzle" && (
          <div style={{display:"flex",flexDirection:"column",alignItems:"center",width:"100%"}}>
         {!finalAnswers[questionInfo?.attributes?.id]?.solution && (     <img
                  src={questionInfo.attributes.document_file}
                  alt="no image"
                  style={{height:200,width:200,paddingBottom:25}}
                />)}
            {/* <div style={{width:"50%",height:"50%",margin:"auto", borderStyle:"solid", borderColor:"black",borderWidth:2, boxShadow:"0 5px 10px 2px rgba(0, 0, 0, 0.25)"}}> */}
            <div
              id="puzzalContainer"
              style={{
                height: "500px",
                width: "900px",
                margin: "auto",
                borderStyle: "solid",
                borderColor: "black",
                borderWidth: 0,
                boxShadow: "0 5px 10px 2px rgba(0, 0, 0, 0.25)",
              }}
            >
              <style>{style}</style>

              {!finalAnswers[questionInfo?.attributes?.id]?.solution ? (
                <div className="puzzalImage">
                  <div id="forPuzzle"></div>
                </div>
              ) : (
                <img
                  src={questionInfo.attributes.document_file}
                  alt="no image"
                  className={uploadImg}
                />
              )}
            </div>
          </div>
        )}
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  return (
    <>
      <SidebarLayout
        childState={activeNumber}
        navigation={undefined}
        id={""}
        selectedSection={value + 1}
        handleQuestionClick={handleQuestionClick}
        questionId={questionId}
        {...props}
        checkQuestionView={checkQuestionView}
      >
        <Card className={cardBox}>
          <Box className={mPowerTabViewWrapper}>
            <Tabs
              data-test-id={`selectNewTab`}
              className={tabs}
              value={value}
              onChange={handleTabChange}
              variant="fullWidth"
            >
              {props?.questions[0]?.data?.attributes?.remaining_sections?.map(
                (ids: SectionItem, index: number) => {
                  return (
                    <Tab
                      disabled={
                        ids.status === "completed" || activeSection != ids.id
                      }
                      key={ids.id}
                      className={tab}
                      label={`Section -${index + 1}`}
                      {...a11yProps(index + 1)}
                    />
                  );
                }
              )}
            </Tabs>
            <Box className={tabContentWrapper}></Box>
          </Box>
          {props &&
            props.questions?.length > 0 &&
            props?.questions?.map((questionDetails: Question) => {
              return questionDetails?.data.attributes.questions.data
                .slice(firstIndexValue, lastIndexValue)
                ?.map((questionInfo: any, index: number) => {
                  return (
                    <React.Fragment key={questionInfo.attributes.id}>
                      <Box
                        data-test-id={`showOptions-${questionInfo.attributes.id}`}
                        className={qBox}
                        onClick={() =>
                          isQuestionView
                            ? {}
                            : handleQuestionClick(
                                questionInfo.attributes.id,
                                index
                              )
                        }
                      >
                        <Box className={blueBox}>
                          <Typography className={question}>
                            {`Q${
                              isQuestionView ? firstIndexValue + 1 : index + 1
                            }.`}
                          </Typography>
                        </Box>
                        <Box className={boxPara}>
                          <Typography className={questionText}>
                            {questionInfo.attributes.text}
                          </Typography>
                        </Box>
                        {questionInfo?.attributes?.choice?.map(
                          (options: any, index: number) => {
                            return (
                              options?.id ==
                                finalAnswers[questionInfo?.attributes?.id]
                                  ?.choice_id && (
                                <Box className={selectedOption}>
                                  {`Your Solution. `}
                                  <span className={answerOption}>
                                    ({alphChars[index]}) &nbsp;{" "}
                                    {options.content}
                                  </span>
                                </Box>
                              )
                            );
                          }
                        )}
                        {solved(questionInfo)}
                      </Box>
                      {(selectedQuestion == questionInfo.attributes.id ||
                        isQuestionView) && (
                        <>
                          {questionInfo.attributes.question_type ===
                            "text_based" && (
                            <Box className={attachFlexContainer}>
                              <Button
                                data-test-id={`setOptionsValue-${questionInfo.attributes.id}`}
                                onClick={() =>
                                  props.handleOpenDropZone(
                                    questionInfo.attributes.id,
                                    index + 1
                                  )
                                }
                                className={attachBtn}
                              >
                                <img src={attachmentClip} alt="clip_img" />
                                Attach File
                              </Button>
                              {props.states.answers[questionInfo.attributes.id]
                                ?.attachment?.name && (
                                <Typography className={fileName}>
                                  {" "}
                                  {
                                    props.states.answers[
                                      questionInfo.attributes.id
                                    ]?.attachment?.name
                                  }{" "}
                                </Typography>
                              )}
                            </Box>
                          )}

                          {questionInfo.attributes.document_file !== "" &&
                            questionInfo.attributes.document_file !== null &&
                            questionInfo.attributes.question_type !==
                              "puzzle" && (
                              <Box className={questionInfoImage}>
                                <img
                                  src={questionInfo.attributes.document_file}
                                  alt="no image"
                                  className={uploadImg}
                                />
                              </Box>
                            )}

                          <Grid container>
                            <Box className={SelectHeadingBox}>
                              <Typography className={SelectHeading}>
                                {handleCondition(questionInfo)}
                              </Typography>
                            </Box>
                            {questionInfo.attributes.question_type ===
                              "text_based" && (
                              <>
                                <TextField
                                  fullWidth
                                  className={textArea}
                                  placeholder="Write your answer here"
                                  multiline
                                  minRows={6}
                                  color="primary"
                                  size="small"
                                  data-test-id={`setOnchangeValue-${questionInfo.attributes.id}`}
                                  value={
                                    finalAnswers[questionInfo?.attributes?.id]
                                      ?.solution || ""
                                  }
                                  onChange={(e) =>
                                    handleSolutionChange(
                                      questionInfo.attributes.id,
                                      questionInfo.attributes.question_type,
                                      e.target.value
                                    )
                                  }
                                />
                              </>
                            )}

                            <PuzzleWork questionInfo={questionInfo} />
                          </Grid>
                          <RadioGroup style={{ marginBottom: 20 }}>
                            <Grid container spacing={3}>
                              {questionInfo?.attributes?.choice?.map(
                                (options: any, index: number) => {
                                  return (
                                    <Grid item xs={12} md={6} key={options.id}>
                                      <Box className={optionWrapper}>
                                        <Box className={blueBox}>
                                          <Typography
                                            className={optionTypoStyle}
                                          >
                                            {alphChars[index]}
                                          </Typography>
                                        </Box>
                                        <Typography
                                          className={optionNumberStyle}
                                        >
                                          {options.content}
                                        </Typography>
                                        <Box>
                                          <FormControlLabel
                                            data-test-id={`setChoiceValue-${questionInfo.attributes.id}`}
                                            style={{ marginRight: "0px" }}
                                            value={options.content}
                                            control={
                                              <Radio
                                                color="primary"
                                                icon={<Icon />}
                                                checkedIcon={<CheckedIcon />}
                                                checked={
                                                  finalAnswers[
                                                    questionInfo?.attributes?.id
                                                  ]?.choice_id === options.id
                                                }
                                                onChange={() =>
                                                  handleChoiceChange(
                                                    questionInfo.attributes.id,
                                                    questionInfo.attributes
                                                      .question_type,
                                                    options.id
                                                  )
                                                }
                                              />
                                            }
                                            label=""
                                          />
                                        </Box>
                                      </Box>
                                    </Grid>
                                  );
                                }
                              )}
                            </Grid>
                          </RadioGroup>
                        </>
                      )}
                    </React.Fragment>
                  );
                });
            })}

          <Box
            className={`${
              isQuestionView ? submitBtnNextPrevLayout : btnLayout
            }`}
          >
            <Button
              data-test-id={`submitQuestions`}
              disabled={isBtndisabled}
              className={`${btnStyle} ${isBtndisabled ? btnDisable : ""} `}
              onClick={() => handleSubmit()}
            >
              Submit MPower Check
            </Button>
            {isQuestionView && (
              <Box className={nextPrevBtnBox}>
                <Button
                  data-test-id="navigators"
                  disabled={firstIndexValue == 0}
                  className={enableBtn}
                  onClick={() => handleQuestionChange("")}
                >
                  <ArrowBackIosRounded />
                </Button>
                <Button
                  data-test-id="navigatorsnext"
                  disabled={activeNumber + 1 == props.questionLength}
                  className={enableBtn}
                  onClick={() => handleQuestionChange("increase")}
                >
                  <ArrowForwardIosRounded />
                </Button>
              </Box>
            )}
          </Box>
        </Card>
      </SidebarLayout>
      <InfoModal
        data-test-id="successfulSubmissionModal"
        info={
          props.states.modalType === "success"
            ? "Your valuable inputs have been submitted successfully!"
            : "Are you sure you want to submit the Mpower check?"
        }
        modalType={props.states.modalType}
        submit={submitForm}
        open={props.states.openFormSubmitModal}
        closeModal={props.handleCloseSubmitSuccessModal}
      />
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
// Customizable Area End

class Questions extends QuestionsController {
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const sessionData = {
      questionLength: this.state.sessionQuestions[0]?.data.attributes.questions
        .data.length,
      questions: this.state.sessionQuestions,
      screen: configJSON.mpowerQuestion,
      handleOpenDropZone: this.handleMpowerOpenDropZone,
      states: this.state,
      submitSection: this.submitSection,
      handleMpowerSubmit: this.handleMpowerOpenDialog,
      handleCloseSubmitSuccessModal: this.handleMpowerCloseSubmitSuccessModal,
    };
    return (
      <>
        <QuestionsView data-test-id="page" {...sessionData} />
        <UploadAttachment
          states={this.state}
          handleCloseDropZone={this.handleMpowerAttachmentUploadDone}
          handleOnDrop={this.handleMpowerUploadAttachment}
          handleCancel={this.handleMpowerCancelUpload}
          handleDone={this.handleMpowerAttachmentUploadDone}
        />
      </>
    );
    // Customizable Area End
  }
}

export default Questions;
// Customizable Area Start
const useStyles = makeStyles((theme) => ({
  // Customizable Area Start
  textArea: {
    "&.MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      padding: "24px 22px",
      background: "#FDFDFD",
      border: "0.2px solid #0056A3",
      borderRadius: "8px",
    },
    "& .MuiInput-underline:before": {
      display: "none",
    },
    "& .MuiInput-underline:after": {
      display: "none",
    },
  },
  btnLayout: {
    textAlign: "right",
  },
  submitBtnNextPrevLayout: {
    textAlign: "right",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "50px",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  nextPrevBtnBox: {
    width: "120px",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    },
  },
  cardBox: {
    padding: "25px",
    boxShadow: "2px 10px 16px rgba(0, 0, 0, 0.0908271)",
    borderRadius: "12px",
  },
  qBox: {
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "8px",
    border: "0.2px solid #e3e3e3",
    marginBottom: "20px",
  },
  optionWrapper: {
    display: "flex",
    alignItems: "center",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "8px",
    border: "0.2px solid #e3e3e3",
    paddingRight: "8px",
    "&:has(.Mui-checked)": {
      border: "0.75px solid #0056A3",
    },
  },
  blueBox: {
    minWidth: "56px",
    height: "56px",
    borderRadius: "8px",
    backgroundColor: "#0056A3",
    color: "white",
    fontSize: "20px",
    fontFamily: "Poppins",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "16px",
  },
  boxPara: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
  },
  question: {
    fontWeight: 600,
    fontSize: "20px",
  },
  questionText: {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    color: "#3B3B3B",
    fontFamily: "Poppins",
  },
  selectedOption: {
    fontWeight: 500,
    fontSize: "14px",
    marginLeft: "20px",
  },
  answerOption: {
    color: "#0056A3",
  },
  btnStyle: {
    backgroundColor: " #0056A3",
    color: "#fff",
    fontStyle: "normal",
    fontWeight: 500,
    extTransform: "capitalize",
    fontSize: "16px",
    borderAadius: "10px",
    padding: "10px 20px",
    borderRadius: "10px",
    textTransform: "capitalize",
    marginTop: "15px",
    "&:hover": {
      backgroundColor: " #0056A3",
      color: "#fff",
    },
  },
  enableBtn: {
    "&.MuiButton-root": {
      width: "50px",
      height: "50px",
      borderRadius: "8px",
      backgroundColor: "#0056A3",
      border: "1px solid #20202066",
      minWidth: "auto",
    },
    "& .MuiButton-label": {
      color: "white",
      fontFamily: "Poppins",
    },
    "&.MuiButton-root.Mui-disabled": {
      border: "1px solid gray",
      backgroundColor: "#e3e3e3",
    },
  },
  disableBtn: {
    minWidth: "18px",
    marginRight: "5px",
    backgroundColor: " #ddd",
    "&:hover": {
      backgroundColor: " #ddd",
      color: "#fff",
    },
  },
  btnDisable: {
    backgroundColor: "#ddd",
    color: "#fff",
  },
  SelectHeading: {
    color: "#0056A3",
    fontWeight: 600,
    fontSize: "20px",
    margin: "10px 0",
    "@media(max-width:767px)": {
      fontSize: "18px",
    },
  },
  SelectHeadingBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: "15px",
  },
  attachFlexContainer: {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  attachBtn: {
    "&.MuiButton-root": {
      height: "36px",
      width: "144px",
      borderRadius: "8px",
      border: "1px solid #0056A3",
      backgroundColor: "white",
      marginLeft: "auto",
      display: "flex",
    },
    "& .MuiButton-label": {
      textTransform: "capitalize",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      color: "#0056A3",
      fontFamily: "Poppins",
      "& img": {
        marginRight: "10px",
      },
    },
  },
  questionInfoImage: {
    height: "308px",
    "@media (max-width: 900px) and (min-width: 500px) ": {
      marginTop: "40px",
    },
  },
  uploadImg: {
    objectFit: "contain",
    display: "flex",
    margin: "auto",
    width: "100%",
    height: "100%",
  },
  selectBox: {
    display: "flex",
    alignItems: "center",
    minHeight: "56px",
    border: "1px solid #efefef",
    justifyContent: "space-between",
    padding: "5px 5px",
    borderRadius: "5px",
  },
  selectBlueBox: {
    background: "#0056A3",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    width: "48px",
    height: "48px",
    justifyContent: "center",
    borderRadius: "5px",
  },
  optionTypoStyle: {
    fontWeight: 600,
    fontSize: "22px",
  },
  optionNumberStyle: {
    marginRight: "auto",
    fontSize: "14px",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkitLineClamp": 1,
    "-webkitBoxOrient": "vertical",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    "&:hover": {
      "-webkitBoxOrient": "inherit",
      wordBreak: "break-word",
    },
  },
  tab: {
    "&.MuiTab-root": {
      textTransform: "capitalize",
      color: "#0056A3",
      fontWeight: 400,
      maxWidth: "none",
      width: "33%",
      fontSize: "18px",
      lineHeight: "27px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
    "&.MuiTab-root.Mui-selected": {
      fontWeight: 700,
      color: "white",
      textTransform: "capitalize",
      backgroundColor: "#0056A3",
      borderRadius: "8px",
      fontSize: "18px",
      lineHeight: "27px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      },
    },
    "&.MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  tabs: {
    "&.MuiTabs-root": {
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
      borderRadius: "8px",
      border: "0.2px solid #e3e3e3",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  mPowerTabViewWrapper: {
    padding: "1px",
  },
  tabContentWrapper: {
    paddingTop: "24px",
    marginBottom: "30px",
  },
  fileName: {
    display: "block",
    marginTop: "15px",
    color: "#0056A3",
  },
  // Customizable Area End
}));
// Customizable Area End
