// Customizable Area Start
import React, { useState, useEffect } from "react";
import { configJSON } from "../DashboardController";
import {
    Box,
    Grid,
    Typography,
    Link,
    Icon,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    makeStyles
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import {
    closeFile,
    editPencilIcon,
    deleteIcon,
    strokeIcon,
    locationIcon,
    twoUserIcon,
    notesIcon,
    trainerCloseIcon,
    profile
} from "../assets";
// Customizable Area End

// Customizable Area Start
const colors = ["yellow", "red", "blue", "purple", "indigo"] as const;
type ColorType = typeof colors[number];

interface EventAttributes {
    date: string,
    time: string,
    location: string,
    colour?: ColorType
}
interface GuestAttributes {
    email: string,
    profile_photo_url: string
}

interface Organizer {
    name: string,
    profile_photo_url: string
}
interface Guest {
    id: string,
    type: string,
    attributes: GuestAttributes
}

interface E {
    id: string,
    type: string,
    attributes: EventAttributes,
    day_date_time?: string,
    location?: string,
    name?: string,
    notes?: string,
    total_guests?: number,
    organizer?: Organizer,
    program_guests?: Array<Guest>
}

interface Props {
    handleCancel: () => void;
    openDeleteModal: (id: string) => void;
    openEditModal: (id: string) => void;
    open: boolean;
    toggleGuestView: () => void;
    changeColor: (id: string, color: string) => void;
    selectedEvent: E;
    isDashboard?: boolean;
}
// Customizable Area End

// Customizable Area Start
// Customizable Area End

const TextLabel = {
    // Customizable Area Start
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "36px",
    color: "#3B3B3B"
    // Customizable Area End
};
// Customizable Area Start
const useStyles = makeStyles({
    // Customizable Area Start
    dialog: {
        "& .MuiPaper-root": {
            padding: "24px",
            backgroundColor: "#F0F4F6",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "1200px",
            width: "520px",
            height: "550px"
        },
        "& .MuiPaper-elevation24": {
            boxShadow: "none"
        }
    },
    backdrop: {
        '&.MuiBackdrop-root': {
            backgroundColor: 'rgba(8, 77, 149, 0.481206)'
        }
    },
    title: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "32px",
        lineHeight: "48px",
        background:
            "linear-gradient(137.61deg, #0056A3 0%, #09539E 14.18%, #000428 143.69%)",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        textFillColor: "transparent",
        '@media(max-width:767px)': {
            fontSize: '22px'
        }
    },
    backDrop: {
        backdropFilter: "blur(3px)",
        backgroundColor: "rgba(0,0,30,0.4)"
    },
    closeButton: {
        position: "absolute",
        top: 0,
        right: 0
    },
    eventColor: {
        background: "#FFB74C",
        boxShadow: "0px 2px 18px rgba(0, 0, 0, 0.159555)",
        borderRadius: "4px",
        width: "24px",
        height: "24px",
        marginTop: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer"
    },
    eventTitle: {
        ...TextLabel,
        '@media(max-width:767px)': {
            fontSize: '18px'
        }
    },
    eventTime: {
        ...TextLabel,
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
        opacity: 0.74
    },
    eventLink: {
        ...TextLabel,
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px"
    },
    eventGuests: {
        ...TextLabel,
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "14px",
        marginTop: '6px'
    },
    eventOrganizer: {
        ...TextLabel,
        fontWeight: 500,
        fontSize: "10px",
        lineHeight: "15px",
        opacity: 0.74,
        top: "calc(50% - 15px/2 + 10.5px)"
    },
    selectColor: {
        position: "absolute",
        flexDirection: "row",
        display: "flex",
        width: "168px",
        height: "40px",
        left: "64px",
        top: "101px",
        border: "0.2px solid #979797",
        boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.186107)",
        borderRadius: "8px",
        background: "#F0F4F6"
    },
    guestProfilePhoto: {
        height: "32px",
        width: "32px",
        border: "0.2px solid #3B3B3B",
        borderRadius: "100%",
        filter: "drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.243526))"
    },
    guestName: {
        ...TextLabel,
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "21px"
    },
    locationBox: {
        marginTop: 30
    },
    guestBox: {
        marginTop: 30,
        paddingBottom: 24,
        marginBottom: 24,
        borderBottom: '1px solid #0155A2'
    },
    contentWrapper: {
        '&.MuiDialogContent-root': {
            '@media(max-width:450px)': {
                padding: 0
            }
        }
    }
    // Customizable Area End
});

const colorList = configJSON.ProgramColor;
// Customizable Area End

// Customizable Area Start
// Customizable Area End
export const ProgramModal = (props: Props) => {
    // Customizable Area Start
    const classes = useStyles();
    const [show, setShow] = useState<boolean>(false);
    const [selectedColor, setSelectedColor] = useState<ColorType>(props?.selectedEvent?.attributes?.colour ? props.selectedEvent.attributes.colour : colors[2]);
    const user = JSON.parse(`${localStorage.getItem('userDetails')}`);
    const role = user?.attributes?.role;

    useEffect(() => {
        if (show) {
            props.changeColor(props.selectedEvent.id, selectedColor);
            setTimeout(() => setShow(false), 1000);
        }
    }, [selectedColor]);

    return (
        <Dialog
            className={classes.dialog}
            BackdropProps={{ className: classes.backdrop }}
            open={props.open}
            onClose={props.handleCancel}
        >
            {show && (
                <Box className={classes.selectColor} data-testid="color-box">
                    {colors.map(color => (
                        <Box
                            key={color}
                            className={classes.eventColor}
                            style={{ backgroundColor: colorList[color].primary, marginLeft: "8px" }}
                            onClick={() => setSelectedColor(color)}
                        >
                            {color === selectedColor && <img src={strokeIcon} />}
                        </Box>
                    ))}
                </Box>
            )}
            <Box
                display="flex"
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={role === "Trainer" ? "flex-start" : "center"}
            >
                <DialogTitle>
                    <Typography className={classes.title}>Program Schedule</Typography>
                </DialogTitle>

                {role === "Trainer" && !props.isDashboard ? (<Box
                    className={classes.closeButton}
                >
                    <IconButton onClick={() => {
                        props.handleCancel();
                        props.openEditModal(props.selectedEvent.id);
                    }}>
                        <img src={editPencilIcon} alt="Edit Event" />
                    </IconButton>
                    <IconButton onClick={() => {
                        props.handleCancel();
                        props.openDeleteModal(props.selectedEvent.id);
                    }}>
                        <img src={deleteIcon} alt="Delete Event" />
                    </IconButton>
                    <IconButton
                        onClick={props.handleCancel}
                    >
                        <img src={trainerCloseIcon} alt="Close Modal" />
                    </IconButton>
                </Box>) : (
                    <IconButton
                        className={classes.closeButton}
                        onClick={props.handleCancel}
                    >
                        <img src={closeFile} alt="Close Modal" />
                    </IconButton>
                )}

            </Box>

            <DialogContent className={classes.contentWrapper}>
                <Grid container spacing={1}>
                    <Grid item xs={1} style={{ marginRight: '10px' }}>
                        <Box
                            className={classes.eventColor}
                            style={{ background: colorList[selectedColor].primary }}
                            onClick={() => setShow(show => !show)}
                            data-test-id="program-modal-color"
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <Typography className={classes.eventTitle}>
                            {props.selectedEvent.name}
                        </Typography>
                        <Typography className={classes.eventTime}>
                            {props.selectedEvent.day_date_time}{" "}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.locationBox}>
                    <Grid item xs={1} style={{ marginRight: '10px' }}>
                        <Icon>
                            <img src={locationIcon} />
                        </Icon>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography className={classes.eventLink}>
                            {props.selectedEvent.location}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.guestBox}>
                    <Grid item xs={1} style={{ marginRight: '10px' }}>
                        <Icon style={{ display: "inline-flex" }}>
                            <img src={twoUserIcon} />
                        </Icon>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography className={classes.eventGuests}>{props.selectedEvent.total_guests ? props.selectedEvent.total_guests + 1 : 1} Guest{props.selectedEvent.total_guests ? "s" : ""}</Typography>
                        {props.selectedEvent.organizer ?
                            <Box flexDirection={"row"} display={"flex"} marginTop={"16px"}>
                                <img className={classes.guestProfilePhoto} src={props.selectedEvent.organizer.profile_photo_url ? props.selectedEvent.organizer.profile_photo_url : profile} />
                                <Box
                                    flexDirection={"column"}
                                    display={"flex"}
                                    marginLeft={"11px"}
                                >
                                    <Typography className={classes.guestName}>
                                        {props.selectedEvent.organizer.name}
                                    </Typography>
                                    <Typography className={classes.eventOrganizer}>
                                        Organizer
                                    </Typography>
                                </Box>
                            </Box>

                            : (<Skeleton />)}
                        {props.selectedEvent.program_guests ? props.selectedEvent.program_guests.map(guest => {
                            return (
                                <Box key={guest.attributes.email} flexDirection={"row"} display={"flex"} marginTop={"16px"}>
                                    <img className={classes.guestProfilePhoto} src={guest.attributes.profile_photo_url ? guest.attributes.profile_photo_url : profile} />
                                    <Box
                                        justifyContent={"center"}
                                        flexDirection={"column"}
                                        display={"flex"}
                                        marginLeft={"11px"}
                                    >
                                        <Typography className={classes.guestName}>{guest.attributes.email}</Typography>
                                    </Box>
                                </Box>
                            )
                        }) : (<Skeleton />)}

                        {props.selectedEvent.total_guests ? !props.isDashboard && props.selectedEvent.total_guests > 3 && <Box className={classes.locationBox}><Link onClick={props.toggleGuestView}>{"Show More >"}</Link></Box> : <></>}

                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={1} style={{ marginRight: '10px' }}>
                        <Icon style={{ display: "inline-flex" }}>
                            <img src={notesIcon} />
                        </Icon>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography className={classes.eventGuests}>Notes</Typography>
                        <Box flexDirection={"row"} display={"flex"} marginTop={"16px"}>
                            <Box
                                flexDirection={"column"}
                                display={"flex"}
                            >
                                <Typography className={classes.eventTime}>{props.selectedEvent.notes}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
    // Customizable Area End
};
// Customizable Area Start
// Customizable Area End
