// Customizable Area Start
export const footerStyle = () => {
	return {
		width: "100%",
		zIndex: "1",
		height: "64px",
	};
};

export const navbarStyle = (theme) => {
	const mq = [...theme.breakPoints];
	return {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-around",
		width: "100%",
		height: "100%",
		[`@media ${mq[0]}`]: {
			backgroundColor: "#0056a3",
		},
	};
};

export const navFooterOption = (theme) => {
	const mq = [...theme.breakPoints];
	return {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		cursor: "pointer",
		padding: "8px 16px",
		margin: "8px 16px",
		position: "relative",
		backgroundColor: "#014785",
		borderRadius: "5px", width: "280px", color: "white", fontSize: "12px", fontWeight: "bold",

		[`@media ${mq[0]}`]: {
			padding: "8px 14px",
			margin: "0 49px",
		}
	}
};

export const itemStyle = (props) => {
	return {
		padding: "8px",
		cursor: "pointer",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "12px",
		color: `${props.theme.color.helpText}`,
	};
};

export const itemLinkStyle = (icon, isActive, context) => {
	let activeStateBg = isActive
		? {
				backgroundColor: `${context.theme.primaryColor}`,
		  }
		: {
				backgroundColor: `${context.theme.secondaryTextColor}`,
		  };

	return {
		height: "24px",
		width: "24px",
		display: "inline-block",
		mask: `url(${icon}) no-repeat center center`,
		...activeStateBg,
	};
};

export const itemLinkTextStyle = (isActive, context) => {
	const colorProp = isActive
		? {
				color: `${context.theme.primaryColor}`,
		  }
		: {
				color: `${context.theme.secondaryTextColor}`,
		  };

	return {
		...colorProp,
		paddingTop: "2px",
	};
};
// Customizable Area End
