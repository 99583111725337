// Customizable Area Start
import React from "react";
import { Grid, Box } from "@material-ui/core";
import { Guest } from "./ProgramSchedulerController.web";
import { makeStyles } from "@material-ui/core/styles";
import { profile } from "../assets";

// Customizable Area End

// Customizable Area Start
// Customizable Area End
const useGuestStyles = makeStyles({
  // Customizable Area Start
  header: {
    justifyContent: "space-around",
    backgroundColor: "#0056A3",
    color: "white",
    marginBottom: "16px",
    height: "64px",
    alignItems: "center",
    borderRadius: "8px",
    fontFamily: "Poppins",
    flexDirection: "row",
    display: "flex",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "27px",
    padding: "16px 24px"
  },
  eventRow: {
    flexDirection: "row",
    display: "flex",
    backgroundColor: "#FFFFFF",
    marginBottom: "16px",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#3B3B3B",
    padding: "16px 24px",
    border: '0.2px solid #e3e3e3'
  },
  guestProfilePhoto: {
    height: "40px",
    width: "40px",
    border: "0.5px solid #297FCA",
    borderRadius: "100%",
    marginRight: 10
  },
  emailWrapper: {
    '&.MuiGrid-item': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  tableWrapper: {
    overflowX: 'auto',
    width: 'calc(100vw - 127px)',
    '@media(max-width:600px)': {
      width: 'calc(100vw - 84px)',
    }
  },
  gridWrapper: {
    '&.MuiGrid-container': {
      '@media(max-width:767px)': {
        minWidth: '767px'
      }
    }
  }
  // Customizable Area End
});

// Customizable Area Start
interface GuestViewProps {
  guests: Array<Guest>;
}
// Customizable Area End

export const GuestView = (props: GuestViewProps) => {
  // Customizable Area Start
  const classes = useGuestStyles();
  return (
    <Box className={classes.tableWrapper}>
      <Grid container className={classes.gridWrapper}>
        <Grid item xs={12} className={classes.header}>
          <Grid item xs={2}>
            Profile
          </Grid>
          <Grid item xs={10}>
            Email
          </Grid>
        </Grid>
        {props.guests.length > 0 ? (
          props.guests.map((guest: Guest) => {
            return (
              <Grid key={guest.id} item xs={12} className={classes.eventRow}>
                <Grid item xs={2}>
                  <img className={classes.guestProfilePhoto} src={guest.attributes.profile_photo_url ? guest.attributes.profile_photo_url : profile} />
                </Grid>
                <Grid item xs={10} className={classes.emailWrapper} >
                  {guest.attributes.email}
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12} className={classes.eventRow}>
            No Guests Found
          </Grid>
        )}
      </Grid>
    </Box>
  );
  // Customizable Area End
};
// Customizable Area Start
// Customizable Area End
