import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";

// Customizable Area Start
let config = require("../../../../../framework/src/config");

interface Data {
  id: string;
  type: string;
  attributes: {
    mind_mastery_course: {
      id: number,
      name: string
    },
    school: {
      id: number,
      name: string
    },
    academic_year: string
    need_analysis_report_url: string
  };
}

export const configJSON = require("../../config.js");

interface Academic {
  value: string;
  id: string; 
  title: string;
}

interface CourseDetails {
  id: number;
  title: string;
  module_names: string[];
  overview: string;
  academic_year: string[];
}

interface Courses {
  type: string;
  id: string;
  attributes: CourseDetails;
}

interface TransformeAcademic {
  value: string;
  title: string;
  id: string;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  history: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  openReport: boolean
  firstRowIndex: number;
  lastRowIndex: number;
  Deletesuccess: boolean;
  DeleteId: number;
  createsuccess: boolean;
  Editsuccess: boolean;
  schoolId: any;
  mindId: any;
  selectedSchool: any
  selectedCourse: any
  ProgrameCourse: any
  SchoolCourse: any
  reader_list: Data[];
  student_list: any;
  editProgramCourse: any;
  editSchoolCourse: any;
  editList: any;
  editAcademicYear: string;
  editProgramCourseId: number,
  editSchoolCourseId: number,
  paramsId: number,
  readyForm: boolean
  NeedlistCourse: any,
  needFilterCourse: any
  courseTitle: any,
  NeedListYear: any,
  needFilterYear: any,
  ProgrameCourseSelect: any,
  pageNumber: any,
  totalReports: any,
  isLoading: boolean;
  academicYearList:Array<Academic>,
  academicYear: string;
  // Customizable Area End
}
interface SS { 
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalysisReportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  analysisDataApiCallId: any = "";
  analysisDeleteApiCallId: any = "";
  courseschoolApiCallId: any = "";
  createFromApiCallId: any = "";
  analysisEditApiCallId: any = "";
  createEditApiCallId: any = ""

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      isLoading: false,
      openReport: false,
      reader_list: [],
      firstRowIndex: 0,
      lastRowIndex: 10,
      Deletesuccess: false,
      DeleteId: 0,
      createsuccess: false,
      Editsuccess: false,
      schoolId: [],
      mindId: [],
      selectedSchool: { name: "", id: 0 },
      selectedCourse: { name: "", id: 0 },
      ProgrameCourseSelect: 0,
      ProgrameCourse: [],
      SchoolCourse: [],
      student_list: [],
      editProgramCourse: { name: "", id: 0 },
      editSchoolCourse: { name: "", id: 0 },
      editProgramCourseId: 0,
      editSchoolCourseId: 0,
      editAcademicYear: "",
      editList: [],
      paramsId: 0,
      readyForm: false,
      NeedlistCourse: [],
      needFilterCourse: { value: "", id: 0 },
      courseTitle: [],
      NeedListYear: ["Select Academic Year"],
      needFilterYear: { value: "", id: 0 },
      pageNumber: 1,
      totalReports: 1,
      academicYearList:[],
      academicYear:""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getreader_listbackData();
    this.CourseSchool();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.ProgrameCourse !== this.state.ProgrameCourse || prevState.editList !== this.state.editList) {
      if (!!this.state.ProgrameCourse.data?.length && this.state.editList && this.state.paramsId !== 0) {
        const courseId = this.state.editList.attributes?.mind_mastery_course_id;
        const courseName = this.state.ProgrameCourse.data.filter((el: any) => { return el.attributes.id === courseId });
        this.handleInputChange({ target: { value: courseName[0]?.attributes.title } });
      }
    }

    if (prevState.SchoolCourse !== this.state.SchoolCourse || prevState.editList !== this.state.editList) {
      if (!!this.state.SchoolCourse.data?.length && this.state.editList && this.state.paramsId !== 0) {
        const schoolId = this.state.editList.attributes?.school_id;
        const schoolName = this.state.SchoolCourse.data.filter((el: any) => { return el.attributes.id === schoolId });
        this.handleSchoolInputChange({ target: { value: schoolName[0]?.attributes.name } });
      }
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        this.handleApiResponse(apiRequestCallId, responseJson)
      }
    }
  }

  handleApiResponse = async (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.analysisDataApiCallId:
        this.handleApiReqSetFilters(responseJson);
        break

      case this.analysisDeleteApiCallId:
        if (responseJson) {
          this.setState({ Deletesuccess: false });
          this.getreader_listbackData()
        }
        break

      case this.courseschoolApiCallId:
        if (responseJson) {
          const schoolid = responseJson?.schools?.data?.map((item: any) => item?.attributes.name) || [];
          const mind_mastery_courses = responseJson?.mind_mastery_courses?.data?.map((item: any) => item?.attributes?.title) || []
          this.setState({ schoolId: schoolid, mindId: mind_mastery_courses, ProgrameCourse: responseJson?.mind_mastery_courses, SchoolCourse: responseJson?.schools })
        }
        break;

      case this.createFromApiCallId:
        if (responseJson?.data) {
          this.setState({ createsuccess: true });
        }
        break;
      case this.analysisEditApiCallId:
        if (responseJson) {
          this.setState({ editList: responseJson.report, readyForm: true });
          const programId = this.state.editList.attributes?.mind_mastery_course_id
          const schoolId = this.state.editList?.attributes?.school_id
          const academicYear = this.state.editList?.attributes?.academic_year
          this.setState({ editSchoolCourseId: schoolId, editProgramCourseId: programId })
          const idProgramCourse = this.state.ProgrameCourse?.data?.filter((course: any) => {
            return course.attributes.id == programId
          })
          this.setState({
            editProgramCourse: { name: idProgramCourse?.[0].attributes?.title, id: idProgramCourse?.[0].attributes.id }
          });
          const idSchoolCourse = this.state.SchoolCourse?.data?.filter((course: any) => {
            return course.attributes.id == schoolId
          })
          this.setState({
            editSchoolCourse: { name: idSchoolCourse?.[0]?.attributes?.name, id: idSchoolCourse?.[0]?.attributes.id },
            academicYear:academicYear
          });
          
        }
        break

      case this.createEditApiCallId:
        if (responseJson?.data) {
          this.setState({ Editsuccess: true });
        }
        break;
    }
  }

  handleApiReqSetFilters = (responseJson: any) => {
    if (responseJson) {
      const resCourse = responseJson.filters.mind_mastery_courses?.map((item: any) => item.title) || [];
      const resAcademicYear = responseJson.filters?.academic_years || [];
      this.setState({ reader_list: responseJson.reports, NeedlistCourse: ["Select Course", ...resCourse], courseTitle: responseJson.filters.mind_mastery_courses, NeedListYear: ["Select Academic Year", ...resAcademicYear] })
    }
  }

  getDataBasedOnUserRole = (userRole: any, SchoolData: any, StudentData: any, ReportData: any) => {
    switch (userRole) {
      case configJSON.UserRole.School:
        return SchoolData;
      case configJSON.UserRole.Student:
        return StudentData;
      default:
        return ReportData;
    }
  };

  handleInputChange = async (event: any) => {
    const courseData = this.state.ProgrameCourse
    const idOverview = this.state.ProgrameCourse?.data?.filter((course: any) => {
      return course?.attributes?.title === event.target.value;
    });
    this.setState({ selectedCourse: { name: idOverview[0]?.attributes.title, id: idOverview[0]?.attributes.id } }
      ,()=>{
        const courseTitle = this.state.selectedCourse.name
        const findAcademicYearByTitle = (courseValue: Courses[], title: string): string[] | null => {
          const course = courseValue.find((item: Courses) => item.attributes.title === title);
          return course ? course.attributes.academic_year : null;
        };        
        const transformAcademicYear = (courseValue: string[]): TransformeAcademic[] =>
          courseValue.map((year: string) => ({
            title: year,
            value: year,
            id: year
          }));
       
          const academicYear = findAcademicYearByTitle(courseData.data, courseTitle);
          const transformedData = transformAcademicYear(academicYear as string[]);
          this.setState({ academicYearList: transformedData });}
    );
  }

  handleSchoolInputChange = async (event: any) => {
    const school = this.state.SchoolCourse?.data?.filter((school: any) => {
      return school.attributes.name === event.target.value;
    })
    this.setState({ selectedSchool: { name: school?.[0]?.attributes.name, id: school?.[0]?.attributes.id } });
  }

  handleAcademicYear = async(event:React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({academicYear: event.target.value });
  }

  filterEndpointprams = (id: any, callerName: string) => {
    if (callerName === "courseFilter") {
      return this.createCourseFilterEndPoint(id);
    }
    if (callerName === "yearFilter") {
      return this.createYearFilterEndPoint();
    }
    return "";
  }

  handlePageChange = (page: any) => {
    this.setState({ pageNumber: page }, () => {
      let endPoint = `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}?page=${this.state.pageNumber}`;
      if (!this.state.needFilterYear.value?.toLowerCase().includes("select") && this.state.needFilterYear.value !== "") {
        endPoint = endPoint + `&academic_year=${this.state.needFilterYear.value}`;
      }
      if (!this.state.needFilterCourse.value?.toLowerCase().includes("select") && this.state.needFilterCourse.value !== "") {
        const ImpactSelectId = this.state.courseTitle?.filter((course: any) => {
          return course.title === this.state.needFilterCourse.value;
        })
        endPoint = endPoint + `&mind_mastery_course_id=${ImpactSelectId[0]?.id}`
      }
      const header = {
        Authorization: this.getToken()
      };
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.analysisDataApiCallId = apiRequest.messageId;
      this.readerapicall(
        apiRequest.messageId,
        configJSON.dashboardGetApiMethod,
        endPoint,
        header
      );
    });
  }

  createCourseFilterEndPoint = (id: any) => {
    if (this.state.needFilterYear.value?.toLowerCase().includes("select") || this.state.needFilterYear.value === "") {
      return this.state.needFilterCourse.value?.toLowerCase().includes("select") ?
        `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}` :
        `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}?mind_mastery_course_id=${id}`

    } else {
      return this.state.needFilterCourse.value?.toLowerCase().includes("select") ?
        `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}?academic_year=${this.state.needFilterYear.value}` :
        `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}?mind_mastery_course_id=${id}&academic_year=${this.state.needFilterYear.value}`
    }

  }
  createYearFilterEndPoint = () => {
    if (this.state.needFilterCourse.value?.toLowerCase().includes("select") || this.state.needFilterCourse.value === "") {
      return this.state.needFilterYear?.value.toLowerCase().includes("select") ?
        `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}` :
        `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}?academic_year=${this.state.needFilterYear.value}`

    } else {
      const ImpactSelectId = this.state.courseTitle?.filter((course: any) => {
        return course.title === this.state.needFilterCourse.value;
      })
      return this.state.needFilterYear.value?.toLowerCase().includes("select") ?
        `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}?mind_mastery_course_id=${ImpactSelectId[0]?.id}` :
        `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}?mind_mastery_course_id=${ImpactSelectId[0]?.id}&academic_year=${this.state.needFilterYear.value}`
    }
  }

  NeedcourseFilterChange = async (event: any) => {
    const NeedSelectId = this.state.NeedlistCourse?.filter((course: any) => {
      return course === event.target.value;
    })
    const CourseId = this.state.courseTitle?.filter((course: any) => {
      return course.title === NeedSelectId[0];
    })

    this.setState({
      needFilterCourse: { value: NeedSelectId[0] },
      pageNumber: 1
    }, () => {
      const header = {
        Authorization: this.getToken()
      };
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.analysisDataApiCallId = apiRequest.messageId;
      this.readerapicall(
        apiRequest.messageId,
        configJSON.dashboardGetApiMethod,
        this.filterEndpointprams(CourseId[0]?.id, "courseFilter"),
        header
      );
    });
  }

  NeedYearFilterChange = async (event: any) => {
    const ImpactSelectYear = this.state.NeedListYear?.filter((year: any) => {
      return year === event.target.value;
    })

    this.setState({
      needFilterYear: { value: ImpactSelectYear[0] },
      pageNumber: 1
    }, () => {
      const header = {
        Authorization: this.getToken()
      };
      const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.analysisDataApiCallId = apiRequest.messageId;
      this.readerapicall(
        apiRequest.messageId,
        configJSON.dashboardGetApiMethod,
        this.filterEndpointprams("", "yearFilter"),
        header
      );
    });
  }

  goBack = () => {
    this.props.navigation?.goBack();
  };

  handleOpenDialog = () => {
    this.setState({ openReport: true, })
  }
  getToken = () => {
    return localStorage.getItem('user_token')
  }

  goTo = (module: string, params: Object = {}) => {
    this.props.navigation.navigate(module, { ...params });
  };

  handleClose = () => {
    this.setState({ Deletesuccess: false });
  };

  handledelete = () => {
    this.setState({ Deletesuccess: false });
    this.DeleteData(this.state.DeleteId)
  };

  async readerapicall(
    uniqueAPICallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body?: any
  ) {
    let FullURL =
      endpoint.indexOf("://") === -1
        ? config.baseURL + "/" + endpoint
        : endpoint;

    let apiResponseMessage = new Message(getName(MessageEnum.RestAPIResponceMessage))
    apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceDataMessage), uniqueAPICallId)

    try {
      let response = await fetch(FullURL, {
        method: method.toUpperCase(),
        headers: headers,
        body: body
      });
      if (response.status === 401) {
        this.goTo("LoginForm");
      }
      let responseJson = await response.json();

      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      )
    } catch (error) {
      runEngine.debugLog("RestApiClient Error", error);
      //setting Error
      console.log("Api Error" + JSON.stringify(error));

      apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceErrorMessage), "Please try again later.");
    }
    this.send(apiResponseMessage);
  }

  handlesuccesClose = () => {
    this.setState({ createsuccess: false });
    this.props.navigation.goBack();
  };

  handleEditSuccessClose = () => {
    this.setState({ Editsuccess: false });
    this.props.navigation.goBack()
  }

  handleopen = (itemId: number) => {
    this.setState({ Deletesuccess: true, DeleteId: itemId });
  };

  async DeleteData(itemId: number) {
    const header = {
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.analysisDeleteApiCallId = apiRequest.messageId;
    await this.readerapicall(
      apiRequest.messageId,
      'DELETE',
      `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}/${itemId}`,
      header
    );
  }

  async getreader_listbackData() {
    const header = {
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.analysisDataApiCallId = apiRequest.messageId;
    await this.readerapicall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.AnalysisReport.NeedAnalysisReportEndPoint,
      header
    );
  }

  async CourseSchool() {
    const header = {
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.courseschoolApiCallId = apiRequest.messageId;
    await this.readerapicall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      configJSON.AnalysisReport.SchoolCourseOptionEndPoint,
      header
    );
  }

  OnCreate = async (value: any) => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getToken()
    };
    const body = JSON.stringify(
      {
        "introduction": value.introduction,
        "executive_summary": value.Executive,
        "school_id": this.state.selectedSchool?.id,
        "mind_mastery_course_id": this.state.selectedCourse?.id,
        "academic_year": this.state.academicYear,
        "recommendations_and_requirements": {
          "key_concerns": value.keyConcerns,
          "focus_areas": value.focusAreas,
          "priority_modules": value.priority,
          "skillset_needed": value.skillset
        },
        "way_ahead": {
          "way_ahead_summary": [value.summary1, value.summary2],
          "o1": value.Ahead01,
          "o2": value.Ahead02,
          "o3": value.Ahead03,
          "o4": value.Ahead04
        },
        "conclusion": value.Conclusion
      }

    );
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createFromApiCallId = apiRequest.messageId;

    await this.readerapicall(
      apiRequest.messageId,
      "POST",
      configJSON.AnalysisReport.NeedAnalysisReportEndPoint,
      header,
      body
    );
    return true
  }

  OnUpdate = async (value: any) => {
    const header = {
      "Content-Type": configJSON.programSchedulerContentType,
      Authorization: this.getToken()
    };
    const body = JSON.stringify(
      {
        "school_id": 3,
        "mind_mastery_course_id": 14,
        "academic_year": this.state.academicYear,
        "introduction": value.introduction,
        "executive_summary": value.Executive,
        "recommendations_and_requirements": {
          "key_concerns": value.keyConcerns,
          "focus_areas": value.focusAreas,
          "priority_modules": value.priority,
          "skillset_needed": value.skillset
        },
        "way_ahead": {
          "way_ahead_summary": [value.summary1, value.summary2],
          "o1": value.Ahead01,
          "o2": value.Ahead02,
          "o3": value.Ahead03,
          "o4": value.Ahead04
        },
        "conclusion": value.Conclusion
      }
    );
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.createEditApiCallId = apiRequest.messageId;

    await this.readerapicall(
      apiRequest.messageId,
      "PUT",
      `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}/${this.state.paramsId}`,
      header,
      body
    );
    return true
  }

  getEditAnalysisData = (idEdit: any) => {
    const header = {
      Authorization: this.getToken()
    };
    const apiRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.analysisEditApiCallId = apiRequest.messageId;
    this.readerapicall(
      apiRequest.messageId,
      configJSON.dashboardGetApiMethod,
      `${configJSON.AnalysisReport.NeedAnalysisReportEndPoint}/${idEdit}/edit`,
      header
    );
  }
  // Customizable Area End
}
