// Customizable Area Start
import Point from "../Point";
import Segment from "../Segment";

// Defining the Side class
class Side {
  type: string;
  points: Point[];

  constructor() {
    this.type = ""; // "d" for straight line or "z" for classic
    this.points = []; // real points or Bezier curve points
  }

  // Method to modify the side from a straight line to a complex one
  twist(ca: Point, cb: Point, tgx: number, tgy: number): void {
    const seg0 = new Segment(this.points[0], this.points[1]);
    const dxh = seg0.dx();
    const dyh = seg0.dy();

    const seg1 = new Segment(ca, cb);
    const mid0 = seg0.pointOnRelative(0.5);
    const mid1 = seg1.pointOnRelative(0.5);

    const segMid = new Segment(mid0, mid1);
    const dxv = segMid.dx();
    const dyv = segMid.dy();

    const pointAt = (coeffh: number, coeffv: number) => {
      return new Point(
        seg0.p1.x + coeffh * dxh + coeffv * dxv,
        seg0.p1.y + coeffh * dyh + coeffv * dyv
      );
    };

    const pa = pointAt(5 / 12, 1 / 12);
    const pb = pointAt(4 / 12, 3 / 12);
    const pc = pointAt(1 / 2, 4 / 12);
    const pd = pointAt(1 - 4 / 12, 3 / 12);
    const pe = pointAt(1 - 5 / 12, 1 / 12);

    this.points = [
      seg0.p1,
      new Point(
        seg0.p1.x + (5 / 12) * dxh * 0.52,
        seg0.p1.y + (5 / 12) * dyh * 0.52
      ),
      new Point(pa.x - (1 / 12) * dxv * 0.72, pa.y - (1 / 12) * dyv * 0.72),
      pa,
      new Point(pa.x + (1 / 12) * dxv * 0.72, pa.y + (1 / 12) * dyv * 0.72),

      new Point(pb.x - (1 / 12) * dxv * 0.72, pb.y - (1 / 12) * dyv * 0.72),
      pb,
      new Point(pb.x + (1 / 12) * dxv * 0.52, pb.y + (1 / 12) * dyv * 0.52),
      new Point(
        pc.x - (2 / 12) * dxh * 0.52 * tgx,
        pc.y - (2 / 12) * dyh * 0.52 * tgy
      ),
      pc,
      new Point(
        pc.x + (2 / 12) * dxh * 0.52 * tgx,
        pc.y + (2 / 12) * dyh * 0.52 * tgy
      ),
      new Point(pd.x + (1 / 12) * dxv * 0.52, pd.y + (1 / 12) * dyv * 0.52),
      pd,
      new Point(pd.x - (1 / 12) * dxv * 0.72, pd.y - (1 / 12) * dyv * 0.72),
      new Point(pe.x + (1 / 12) * dxv * 0.72, pe.y + (1 / 12) * dyv * 0.72),
      pe,
      new Point(pe.x - (1 / 12) * dxv * 0.72, pe.y - (1 / 12) * dyv * 0.72),
      new Point(
        seg0.p2.x - (5 / 12) * dxh * 0.52,
        seg0.p2.y - (5 / 12) * dyh * 0.52
      ),
      seg0.p2
    ];
    this.type = "z";
  }

  // Method to draw the path corresponding to a side
  drawPath(
    ctx: CanvasRenderingContext2D,
    shiftx: number,
    shifty: number,
    backwards: boolean,
    withoutMoveTo: boolean
  ): void {
    let k, mix, miy;

    if (backwards) {
      if (!withoutMoveTo) {
        ctx.moveTo(
          this.points[this.points.length - 1].x - shiftx,
          this.points[this.points.length - 1].y - shifty
        );
      }
      if (this.type == "d") {
        ctx.lineTo(this.points[0].x - shiftx, this.points[0].y - shifty);
      } else {
        // jigsaw side
        for (k = this.points.length - 2; k > 0; k -= 3) {
          ctx.bezierCurveTo(
            this.points[k].x - shiftx,
            this.points[k].y - shifty,
            this.points[k - 1].x - shiftx,
            this.points[k - 1].y - shifty,
            this.points[k - 2].x - shiftx,
            this.points[k - 2].y - shifty
          );
        } // for k
      } // if jigsaw side
    } else {
      if (!withoutMoveTo) {
        ctx.moveTo(this.points[0].x - shiftx, this.points[0].y - shifty);
      }

      this.breakFunc(ctx, shiftx, shifty, k);
    }
  } // Side.prototype.drawPath

  breakFunc(ctx: any, shiftx: any, shifty: any, k: any) {
    if (this.type == "d") {
      ctx.lineTo(this.points[1].x - shiftx, this.points[1].y - shifty);
    } else {
      // edge zigzag
      for (k = 1; k < this.points.length - 1; k += 3) {
        ctx.bezierCurveTo(
          this.points[k].x - shiftx,
          this.points[k].y - shifty,
          this.points[k + 1].x - shiftx,
          this.points[k + 1].y - shifty,
          this.points[k + 2].x - shiftx,
          this.points[k + 2].y - shifty
        );
      } // for k
    }
  }
}

export default Side;
// Customizable Area End
