// Customizable Area Start
class Point {
  x: number;
  y: number;

  constructor(x: any, y: any) {
    this.x = Number(x);
    this.y = Number(y);
  }

  copy() {
    return new Point(this.x, this.y);
  }

  getData() {
    return {
      x: 1,
      y: 2
    };
  }
}

export default Point;
// Customizable Area End
