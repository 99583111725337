import React from 'react'
// Customizable Area Start
import TrainerMpowerYouMatterController, { Fields } from './TrainerMpowerYouMatterController.web';
import SidebarLayout from '../../SidebarLayout.web';
import PageContentLayout from '../../PageContentLayout.web';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Typography, IconButton } from "@material-ui/core";
import { removeQuestionIcon } from '../../assets';
import { useStyles as questionBankStyles } from './TrainerMpowerYouMatterQuestionBank.web';
import RootContext from '../../../../../components/src/RootContext';
// Customizable Area End
const configJSON = require("../../config.js");

// Customizable Area Start
const useStyles = makeStyles(() => ({
    pageWrapper: {
        padding: '24px'
    },
    sectionBtnWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    sectionName: {
        color: '#0056A3',
        fontSize: '24px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        marginRight: '16px'
    },
    importBtn: {
        "&.MuiButton-root": {
            height: '48px',
            width: '246px',
            borderRadius: "8px",
            border: '1px solid #0056A3',
            backgroundColor: "white",
            display: 'block',
            marginLeft: 'auto',
            '@media(max-width:767px)': {
                width: 'auto',
                height: 'auto'
            }
        },
        '& .MuiButton-label': {
            textTransform: 'capitalize',
            fontSize: '16px',
            fontWeight: "500",
            lineHeight: '24px',
            color: "#0056A3",
            fontFamily: "Poppins",
            '& img': {
                marginRight: '10px'
            }
        }
    },
    questionListWrapper: {
        borderRadius: '8px',
        background: '#FFF',
        boxShadow: '2px 10px 16px 0px rgba(0, 0, 0, 0.09)',
        padding: '24px 24px 24px 16px',
        marginBottom: '40px'
    },
    tableHeader: {
        padding: '14px 24px',
        borderRadius: '8px',
        backgroundColor: '#0056A3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-between",
        marginBottom: '24px'
    },
    headerText: {
        color: 'white',
        fontSize: '18px',
        fontFamily: 'Poppins',
        fontWeight: 700,
        '@media(max-width:600px)': {
            fontSize: '14px',
        }
    },
    questionCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.122705)',
        borderRadius: '8px',
        border: '0.2px solid #e3e3e3',
        marginBottom: '12px',
        paddingRight: '20px'
    },
    questionNumber: {
        minHeight: '56px',
        minWidth: '56px',
        borderRadius: '8px',
        backgroundColor: '#0056A3',
        color: 'white',
        fontFamily: "Poppins",
        fontSize: '20px',
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '16px'
    }
}))

export const QuestionListLayout = (props: any) => {
    const classes = useStyles();
    const commonClasses = questionBankStyles();
    return <Box className={classes.questionListWrapper} data-test-id='box'>
        <Box className={classes.tableHeader}>
            <Typography className={classes.headerText}>{configJSON.TrainerMPowerYouMatter.Questions}</Typography>
            <Box className={commonClasses.headerEnd}>
                <Typography className={classes.headerText}>{configJSON.TrainerMPowerYouMatter.Type}</Typography>
                <Typography className={classes.headerText}>{configJSON.TrainerMPowerYouMatter.Action}</Typography>
            </Box>
        </Box>
        {props.children}
    </Box>
}

interface TrainerMpowerYouMatterQuestionsListPageProps {
    handleRemoveQuestion: (sectionId: any, questionId: any, context: any) => void;
    handleNextClick: (context: any, isYouMatter: boolean) => void;
    sectionsData: Fields[];
    goTo: (module: string, params: Object) => void;
    isYouMatter: boolean;
    context: any;
}

export const TrainerMpowerYouMatterQuestionsListPage = (props: TrainerMpowerYouMatterQuestionsListPageProps) => {
    const classes = useStyles(); 
    const commonClasses = questionBankStyles();
    const isDisable = () => {
        return props.sectionsData?.every((element: any) => Number(element?.totalQuestions) === element?.questions?.length);
    }
    return <Box className={classes.pageWrapper}>
        {props.sectionsData?.map((element: Fields) => {
            const { id, questions, sectionName, totalQuestions } = element;
            return <>
                <Box key={id} className={classes.sectionBtnWrapper}>
                    <Typography className={classes.sectionName}>{sectionName}</Typography>
                    {Number(totalQuestions) > questions?.length &&
                        <Button data-test-id='import' className={classes.importBtn} onClick={() => props.goTo(props.isYouMatter ? 'TrainerYouMatterQuestionBank' : 'TrainerMpowerQuestionBank', { sectionId: id, totalQuestions: totalQuestions })} >
                            {configJSON.TrainerMPowerYouMatter.ImportQuestions}
                        </Button>}
                </Box>
                <QuestionListLayout>
                    {questions.length > 0 ? questions.map((question: any, index: number) => {
                        return <Box className={classes.questionCard} key={question.id} >
                            <Box className={commonClasses.questionWrapper}>
                                <Box className={classes.questionNumber}>{`Q${index + 1}.`}</Box>
                                <Typography className={commonClasses.questionText}>{question.content}</Typography>
                            </Box>
                            <Box className={commonClasses.headerEnd}>
                                <Typography>{question.type}</Typography>
                                <IconButton data-test-id='delete' onClick={() => props.handleRemoveQuestion(id, question.id, props.context)} >
                                    <img src={removeQuestionIcon} alt="delete_icon" />
                                </IconButton>
                            </Box>
                        </Box>
                    }) : configJSON.TrainerMPowerYouMatter.NoImportedQuestions}
                </QuestionListLayout>
            </>
        })}
        <Button className={commonClasses.nextBtn} disabled={!isDisable()} data-test-id='next' onClick={() => props.handleNextClick(props.context, props.isYouMatter)}>Next</Button>
    </Box>
}
// Customizable Area End
export default class TrainerMpowerYouMatterQuestionList extends TrainerMpowerYouMatterController {
    // Customizable Area Start
    static ContextType = RootContext;
    async componentDidMount() {
        window.addEventListener("beforeunload", this.unloadCallback);
        window.addEventListener("unload", this.handleUnload);
        const isReload = localStorage.getItem(configJSON.TrainerMPowerYouMatter.IsReload)
        if (isReload) {
            localStorage.removeItem(configJSON.TrainerMPowerYouMatter.IsReload)
            const path = window.location.pathname;
            const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath)
            this.goTo(isYouMatter ? configJSON.TrainerMPowerYouMatter.TrainerYouMatterCheck : configJSON.TrainerMPowerYouMatter.TrainerMpowerCheck)
        }
    }
    async componentWillUnmount() {
        this.setState({ isContextRestored: false })
        localStorage.removeItem(configJSON.TrainerMPowerYouMatter.IsReload)
        window.removeEventListener("beforeunload", this.unloadCallback);
        window.removeEventListener("unload", this.handleUnload);
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const path = window.location.pathname;
        const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath)
        const fields = this.context?.formData?.addFormData?.fields;
        return <>
            <SidebarLayout {...this.props} >
                <PageContentLayout title={this.context?.formData?.addFormData?.title} goBack={() => this.goTo(isYouMatter ? 'CreateYouMatter' : 'CreateMpower')}>
                    <TrainerMpowerYouMatterQuestionsListPage
                        handleRemoveQuestion={this.removeQuestionFromSection}
                        handleNextClick={this.handleQuestionListNext}
                        sectionsData={fields}
                        goTo={this.goTo}
                        isYouMatter={isYouMatter}
                        context={this.context}
                    />
                </PageContentLayout>
            </SidebarLayout>
        </>
        // Customizable Area End
    }
}