import React from "react";
// Customizable Area Start
import PageContentLayout from "../../PageContentLayout.web";
import SidebarLayout from "../../SidebarLayout.web";
import MaterialController, { S } from "./MaterialController.web";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommonPagination from "../../../../../components/src/Pagination.web";
import MaterialList from "./MaterialList.web";
import CommonSelect from "../../CommonComponents/CommonSelect.web";
import Loader from "../../../../../components/src/Loader.web";
// Customizable Area End
const configJSON = require("../../config.js");

const useStyles = makeStyles({
  // Customizable Area Start
  pageWrapper: {
    padding: "32px 24px 24px 24px",
  },
  dropDown: {
    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2",
    },
    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #066CD2",
    },
    "&.MuiOutlinedInput-root": {
      width: "100%",
      height: "48px",
      backgroundColor: "#ffffff4f",
      borderRadius: "8px",
      marginLeft: "auto",
      marginRight: "24px",
      color: "black",
      fontSize: "14px",
      border: "1px solid #DEDDE4",
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingLeft: "14px",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingRight: "2px",
      width: "calc(100% - 60px)",
      "&:hover": {
        width: "100%",
      },
    },
  },
  menuItems: {
    "&.MuiMenuItem-root": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#0056a314",
    },
    "&.MuiListItem-root:hover": {
      backgroundColor: "#0056a314",
    },
  },
  tableHead: {
    "&.MuiGrid-container": {
      background: "#0056A3",
      borderRadius: "8px",
      color: "white",
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "27px",
      justifyContent: "space-between",
      padding: "15px 24px",
      marginBottom: "16px",
    },
  },
  tableRow: {
    "&.MuiGrid-container": {
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.122705)",
      borderRadius: "8px",
      border: "1px solid #e3e3e3",
      justifyContent: "space-between",
      marginBottom: "8px",
      padding: "12px 24px",
    },
  },
  headerCell: {
    "&.MuiGrid-item": {
      padding: "0 10px",
      overflow: "hidden",
      margin: "auto",
      display: "-webkit-box",
      "-webkitLineClamp": 2,
      "-webkitBoxOrient": "vertical",
      textOverflow: "ellipsis",
      wordBreak: "break-word",
      "&:hover": {
        "-webkitBoxOrient": "inherit",
        wordBreak: "break-word",
      },
    },
  },
  completeStatusCell: {
    "&.MuiGrid-item": {
      color: "#008A00",
      margin: "auto",
    },
  },
  pendingStatusCell: {
    "&.MuiGrid-item": {
      color: "#FF9300",
      margin: "auto",
    },
  },
  dropDownWrapper: {
    marginBottom: "32px",
  },
  tableWrapper: {
    marginBottom: "36px",
  },
  pageTitle: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "33px",
    color: "white",
    fontFamily: "Poppins",
    "@media(max-width:575px)": {
      fontSize: "16px",
      lineHeight: "22px",
    },
  },
  courseName: {
    cursor: 'pointer',
    borderRadius: '8px',
    border: '0.2px solid #e3e3e3',
    background: '#FFF',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
    '@media(max-width:767px)': {
      flexDirection: 'column',
    }
  },
  courseTitle: {
    color: '#0056A3',
    fontWeight: 700,
    fontSize: '20px',
    '@media(max-width:575px)': {
      fontSize: '16px',
    }
  },
  thumbnail: {
    marginRight: '20px',
    width: "130px",
    borderRadius: '8px',
    '@media(max-width:767px)': {
      width: '100%',
      margin: '0 0 15px 0'
    }
  }
  // Customizable Area End
});

// Customizable Area Start
interface MaterialPageProps {
  states: S,
  pageCount: number,
  handleFilterChange?: (event: React.ChangeEvent<{ value: any, name: any }>) => void;
  clearFilter?: (key: any) => void;
  handleChangeAccordion?: (panel: string) => ((event: React.ChangeEvent<{}>, expanded: boolean) => void) | undefined;
  handlePage: (page: number) => void;
  goTo?: (module: string, params?: Object) => void;
}

interface ElementAttributes {
  id: number;
  thumbnail: string; 
  title: string; 
}

interface DataElement {
  id: number; 
  attributes: ElementAttributes;
}

export const MaterialPage = (props: MaterialPageProps) => {
  const {
    pageCount,
    states,
    handleFilterChange,
    clearFilter,
    handlePage,
    goTo
  } = props;
  const classes = useStyles();
  const schoolFilterList = configJSON.formatDropdownList(props.states.schoolList);
  const sectionFilterList = configJSON.formatDropdownList(props.states.sectionList);
  const courseFilterList = configJSON.formatDropdownList(props.states.courseList);

  let isMaterialPage = window.location.pathname !== "/parent/guide";
  const isTrainerMaterial = window.location.pathname.includes('trainer')
  const Attachment = isMaterialPage ? states.CoursesData : states.ParentGuideData;
  const Data = Attachment?.data;
  const filterWidth = isTrainerMaterial ? 4 : 6;
  return (
    <>
      <Box className={classes.pageWrapper}>
        {clearFilter &&
          <Grid container className={classes.dropDownWrapper} spacing={2}>
            {isTrainerMaterial && <Grid item lg={4} xl={4} md={4} sm={4} xs={12}>
              <CommonSelect
                data-test-id='course'
                handleChange={handleFilterChange}
                onClear={() => clearFilter('courseFilter')}
                name={configJSON.Material.CourseFilter}
                placeholder={configJSON.Material.SelectCourse}
                value={states.courseFilter}
                menuList={courseFilterList} />
            </Grid>}
            <Grid item lg={filterWidth} xl={filterWidth} md={filterWidth} sm={filterWidth} xs={12}>
              <CommonSelect
                data-test-id='school'
                handleChange={handleFilterChange}
                onClear={() => clearFilter('schoolFilter')}
                name={configJSON.Material.SchoolFilter}
                placeholder={configJSON.Material.SelectSchool}
                value={states.schoolFilter}
                menuList={schoolFilterList} />
            </Grid>
            <Grid item lg={filterWidth} xl={filterWidth} md={filterWidth} sm={filterWidth} xs={12}>
              <CommonSelect
                data-test-id='section'
                handleChange={handleFilterChange}
                onClear={() => clearFilter('sectionFilter')}
                name={configJSON.Material.SectionFilter}
                placeholder={configJSON.Material.SelectGrade}
                value={states.sectionFilter}
                menuList={sectionFilterList} />
            </Grid>
          </Grid>
        }
        <Box className={classes.tableWrapper}>
          <Box>
            {isMaterialPage && <>
              <Grid container className={classes.tableHead}>
                <Typography className={classes.pageTitle}>
                  {isTrainerMaterial ? 'Training' : 'Parent'} Material
                </Typography>
              </Grid>
              <Grid container spacing={2}>
                {Data?.map((element: DataElement, index: number) => {
                  return <Grid item xs={12} key={element.id} onClick={() => goTo && goTo(isTrainerMaterial ? 'TrainerSessionWiseMaterial' : 'ParentSessionWiseMaterial', { courseId: element.id })} >
                    <Box className={classes.courseName}>
                      <img src={element.attributes.thumbnail} className={classes.thumbnail} alt="" />
                      <Typography className={classes.courseTitle} >{element.attributes.title}</Typography>
                    </Box>
                  </Grid>
                })}
              </Grid>
            </>}
            {!isMaterialPage && (Data?.length > 0 ? Data.map((element: any, id: any) => {
              return <MaterialList key={element} data={element} isMaterialPage={isMaterialPage} />;
            }) : configJSON.Material.NoParentGuidelines)}
          </Box>
        </Box>
        <CommonPagination
          data-test-id='pagination'
          page={states.page}
          handlePage={handlePage}
          pageCount={pageCount}
        />
      </Box>
    </>
  );
};
// Customizable Area End
export default class Material extends MaterialController {
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getFilterDropdownData('', this.state.courseFilter, this.state.schoolFilter);
    const isParentsMaterial = window.location.pathname === '/parent/parent-material'
    this.getMaterialPageData(isParentsMaterial);
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<S>): void {

    const isCourseOrSchoolFilterChanged = prevState.courseFilter !== this.state.courseFilter || prevState.schoolFilter !== this.state.schoolFilter;

    if (isCourseOrSchoolFilterChanged || prevState.sectionFilter !== this.state.sectionFilter || prevState.page !== this.state.page) {
      const isParentsMaterial = window.location.pathname === '/parent/parent-material'
      this.getMaterialPageData(isParentsMaterial);
    }
    if (isCourseOrSchoolFilterChanged) {
      this.getFilterDropdownData(prevState.courseFilter, this.state.courseFilter, this.state.schoolFilter)
    }
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const pageCount = this.state.CoursesData?.page_options?.total_pages;
    const isParentsMaterial = window.location.pathname === configJSON.Material.ParentMaterialPath
    return (
      <>
        <SidebarLayout {...this.props}>
          <PageContentLayout title={isParentsMaterial ? configJSON.Material.ParentMaterial : configJSON.Material.TrainingMaterial}>
            {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
            <MaterialPage
              data-test-id="page"
              handleFilterChange={this.handleFilterChange}
              clearFilter={this.handleClearSelect}
              pageCount={pageCount}
              states={this.state}
              goTo={this.goTo}
              handlePage={this.handlePage}
            />
          </PageContentLayout>
        </SidebarLayout>
      </>
    );
    // Customizable Area End
  }
}
