// Customizable Area Start
import React, { useState } from 'react';
const {
  CometChatUI,
} = require('../../src/SeekHelpModule/CometChat/CometChatWorkspace/src');
import SeekHelpController, { Props } from './SeekHelpController';
// Customizable Area End

// Customizable Area Start
interface UserDetails {
  isUserLogin:boolean;
  selectedUser: string[];
  isAdmin: boolean;
  notificationCall: (id: number) => void;
}
// Customizable Area End

function SeekHelpSection(props: UserDetails) {
  // Customizable Area Start
  let getuseDetails = localStorage.getItem("userDetails");
  let userDetails = getuseDetails ? JSON.parse(getuseDetails) : null;
  const [selectedRole, _] = useState(userDetails?.attributes?.role);
  const [ChatMode, setSelectedChatMode] = useState(
    userDetails?.attributes?.role
  );
  /* istanbul ignore next*/
  const selectUserForChat = (chatWith: string) => {
    if (chatWith === "Admin") {
      setSelectedChatMode(chatWith);
    } else {
      setSelectedChatMode(userDetails?.attributes.role);
    }
  };
  
  return (
    <>
    { props.isUserLogin && (
       <CometChatUI
       selectUserForChat={selectUserForChat}
       selectedUser={props.selectedUser}
       userRole={ChatMode}
       isAdmin={props.isAdmin}
       sendNotificationTotrainerOnMail={props.notificationCall}
     />
    ) }
    </>
  );
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
class SeekHelp extends SeekHelpController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <SeekHelpSection
        isUserLogin={this.state.isUserLogin}
        selectedUser={this.state.cometChatIds}
        isAdmin={this.state.isAdmin}
        notificationCall={this.sendNotificationTotrainerOnMail}
      />
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
export default SeekHelp;

// Customizable Area Start
// Customizable Area End
