import React from "react";
// Customizable Area Start
const configJSON = require("../config");
import StudentProgramLayout from "./GenericComponents/StudentProgramLayout.web";
const { programBudBannerImage } = require("../assets");

const budPageContent = configJSON.ProgramsPageContent.budPage;

const BudPage = () => {
  return (
    <StudentProgramLayout
      programContent={budPageContent}
      bannerImage={programBudBannerImage}
    />
  );
};
// Customizable Area End
export default BudPage;
