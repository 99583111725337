import React from "react";
// Customizable Area Start
import MindMasteryController from "./MindMasteryController.web";
import {
  Box,
  Button,
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PageContentLayout from "../PageContentLayout.web";
import SidebarLayout from "../SidebarLayout.web";
import Loader from "../../../../components/src/Loader.web";
import CommonPagination from "../../../../components/src/Pagination.web";
// Customizable Area End
const configJSON = require('../config')

const useStyles = makeStyles(theme => ({
  // Customizable Area Start
  mindMasteryWrapper: {
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "2px 10px 16px rgba(0, 0, 0, 0.0908271)",
    borderRadius: "12px"
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    background:
      "linear-gradient(131deg, #B3DBFF -33.84%, #4B90CD 19.41%, #0156A2 95.54%)",
    borderRadius: "12px 12px 0px 0px",
    height: "80px"
  },
  backButton: {
    "&.MuiButton-root": {
      padding: "0",
      background: "#010E36",
      height: "48px",
      width: "48px",
      opacity: 0.5,
      borderRadius: "8px",
      margin: "0 24px",
      minWidth: "auto"
    }
  },
  mindMasteryPageContent: {
    padding: "32px"
  },
  cardMedia: {
    "&.MuiCardMedia-root": {
      height: "185px"
    }
  },
  cardWrapper: {
    "&.MuiCard-root": {
      maxWidth: "328px",
      borderRadius: "8px",
      border: "0.2px solid #e3e3e3"
    },
    "&.MuiPaper-elevation1": {
      boxShadow: "none"
    }
  },
  cardTitle: {
    color: "#0056A3",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "18px",
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkitLineClamp': 1,
    '-webkitBoxOrient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    '&:hover': {
      '-webkitBoxOrient': 'inherit',
      wordBreak: 'break-word',
    }
  },
  startCourseBtn: {
    "&.MuiButton-root": {
      color: "white",
      fontFamily: "Poppins",
      width: "262px",
      height: "36px",
      background: "#0056A3",
      borderRadius: "8px",
      margin: "0 auto 10px auto",
      textTransform: "capitalize"
    }
  },
  cardDescription: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#3B3B3B",
    minHeight: '141px'
  },
  cardContent: {
    "&.MuiCardContent-root": {
      padding: "20px 24px 16px 24px"
    }
  },
  gridItem: {
    [theme.breakpoints.down("sm")]: {
      "&.MuiGrid-root": {
        display: "flex",
        justifyContent: "center"
      }
    }
  },
  paginationWrapper: {
    marginTop: '30px'
  }
  // Customizable Area End
}));

// Customizable Area Start
interface MindMasteryPageProps {
  pageCount: number;
  goTo: (module: string, params?: Object) => void;
  page: number;
  handlePage: (page: number) => void;
  courseList: [
    {
      id:number;
      type:string;
      attributes: {
        title: string;
        thumbnail: string;
        description:string;
      }
    }
  ]
}

export const MindMasteryPage = (props: MindMasteryPageProps) => {
  const { courseList, pageCount } = props;
  const classes = useStyles();
  return (
    <Box className={classes.mindMasteryPageContent}>
      {courseList?.length > 0 ? <><Grid container spacing={4}>
        {courseList?.map((element: any) => {
          const { title, thumbnail } = element.attributes;
          return (
            <Grid
              container
              item
              spacing={1}
              className={classes.gridItem}
              key={element.id}
              xs={12}
              sm={6}
              md={6}
              lg={4}
              xl={3}
            >
              <Grid item>
                <Card className={classes.cardWrapper}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={thumbnail}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography className={classes.cardTitle}>
                      {title}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      className={classes.startCourseBtn}
                      onClick={() =>
                        props.goTo(configJSON.MindMasteryTexts.CourseDetailsPage, {
                          courseId: element.id
                        })
                      }
                    >
                      {configJSON.MindMasteryTexts.StartCourse}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
        <Box className={classes.paginationWrapper}>
          <CommonPagination page={props.page} pageCount={pageCount} handlePage={props.handlePage} />
        </Box>
      </> : configJSON.MindMasteryTexts.NoMindMasteryCourseFound}
    </Box >
  );
};
// Customizable Area End

export default class MindMastery extends MindMasteryController {
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getCourses(this.state.page);
  }

  componentDidUpdate(prevProps: any, prevState: any): void {
    if (prevState.page !== this.state.page) {
      this.getCourses(this.state.page);
    }
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const mindMasteryProps = {
      courseList: this.state.mindMasteryPageData.data,
      goTo: this.goTo,
      page: this.state.page,
      handlePage: this.handlePage,
      pageCount: this.state.mindMasteryPageData.page_options?.total_pages
    }
    return (
      <SidebarLayout {...this.props}>
        <PageContentLayout title={configJSON.MindMasteryTexts.MindMastery} subtitle={configJSON.MindMasteryTexts.ConceptRevision}>
          {this.state.isLoading ? (
            <Loader loading={this.state.isLoading} />
          ) : null}
          <MindMasteryPage   {...mindMasteryProps} />
        </PageContentLayout>
      </SidebarLayout>
    );
    // Customizable Area End
  }
}
