import React from "react";
// Customizable Area Start
import { Box, Grid, Container, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Banner from "../../../../components/src/Banner";
import BannerWithSpan from "./GenericComponents/BannerWithSpan";
import RightLeftLayout from "./GenericComponents/RightLeftLayout.web";
import LeftRightLayout from "./GenericComponents/LeftRightLayout.web";
import FlotingButton from "../../../../components/src/FlotingButton.web";
import Footer from "../../../../components/src/Footer.web";
// Customizable Area End
const configJSON = require("../config");
// Customizable Area Start
const {
  programMainBannerImage,
  programMainChlidren,
  welcomeLeftBg
} = require("../assets");

interface Content {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
  multiLine?: string[];
}
const {
  heading,
  rightParagraphs,
  topParagraphs,
  bottomParagraphs,
  features
} = configJSON.ProgramsPageContent.mainPage;
const content: Content = {
  headerText: heading,
  contentText: "",
  fontSize: "48px",
  color: "#FFFFFF",
  secondColor: "",
  paragraphFontSize: ""
};

const bannerCss = {
  top: "-240px",
  marginTop: "19%",
  padding: "0 0",
}

const useStyles = makeStyles(theme => ({
  relativeParent: {
    position: "relative"
  },
  containerStyle: {
    justifyContent: "center",
    alignItems: "flex-end",

  },
  welcomeBg: {
    backgroundImage: `url(${welcomeLeftBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0 -280px",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: "none"
    }
  },
  bottomContentBox: {
    paddingTop: "15px"
  },
  breakPara: {
    marginBottom: "8px"
  },
  paragraph: {
    fontWeight: 500,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74"
  },
  boldParagraph: {
    fontWeight: 600,
    lineHeight: "24px",
    fontSize: "16px",
    color: "#3B3B3B",
    opacity: "0.74"
  },
  imageBox: {
    transform: "translate(-20px, 0)",
    [theme.breakpoints.down("sm")]: {
      transform: "translate(0, 0)",
      marginBottom: "30px"
    }
  },
  childrenImg: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  
    mainHeading: {
      fontSize: "40px",
      fontWeight: 600,
      color: "#052B62"
    },
}));
const ProgramsPage = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.relativeParent}>
      <BannerWithSpan
          bannerCss = {bannerCss}
          heading={heading}
          content={topParagraphs}
          bannerImage={programMainBannerImage}
          showBtn={true}
          headingSpan={""}
          />
        <FlotingButton ourPartner={false} />
        <Box sx={{ py: "100px" }} className={classes.welcomeBg}>
          <Container className={classes.containerStyle}>
            <Grid container >
              <Grid item xs={12} md={12}>
              <Box>
              <Typography className={classes.mainHeading}>
                  {rightParagraphs}
                </Typography>
                </Box>
                <Box className={""}>
                 
                  {features.map((feature: any, index: number) =>
                    index % 2 === 0 ? (
                      <LeftRightLayout key={index} feature={feature} index={index}/>
                    ) : (
                      <RightLeftLayout key={index} feature={feature} />
                    )
                  )}
                </Box>
                <Box className={classes.bottomContentBox}>
                  {bottomParagraphs.map((paragraph: string, index: number) => (
                    <Box key={index} className={classes.breakPara}>
                      <Typography
                        variant="body1"
                        className={
                          index === 0
                          ? `${classes.paragraph}`
                          : `${classes.paragraph} ${classes.boldParagraph}`
                        }
                      >
                      {index === 1 && (
                        <>
                          <Link color="primary" href="/partnership#specific-section">
                            {paragraph}
                          </Link>
                          <span>{" to book a demo and learn more about our platform"}</span>
                        </>
                      )}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Box >
        <Footer />
      </Box>
    </>
  );
};
// Customizable Area End
export default ProgramsPage;
