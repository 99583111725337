import React from "react";
// Customizable Area Start
import {
  Box,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  makeStyles
} from "@material-ui/core";
import { logo, closeFile } from "./assets";
// Customizable Area End
const configJSON = require("./config");
// Customizable Area Start
interface Props {
  handleCancel: () => void;
  open: any;
  showPrivacyPolicy?: boolean;
}

const useStyles = makeStyles({
  dialog: {
    "& .MuiPaper-root": {
      padding: "24px 48px 48px 48px",
      backgroundColor: "#F0F4F6",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "1200px",
      width: "90%",
      height: "80%"
    },
    "& .MuiPaper-elevation24": {
      boxShadow: "none"
    }
  },
  title: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "27px",
    textAlign: "center",
    color: "#0056A3",
    borderBottom: "1px solid #0056A3",
    marginBottom: "28px"
  },
  content: {
    "& p": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "28px",
      color: "#3B3B3B",
      opacity: 0.84
    },
    "& h5": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "20px",
      lineHeight: "30px",
      color: "#0056A3"
    },
    "& li": {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "28px",
      color: "#3B3B3B",
      opacity: 0.84,
      "&:first-letter": {
        textTransform: "capitalize"
      }
    },
    "&::-webkit-scrollbar": {
      width: "0.4em"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0056A3",
      borderRadius: "4px"
    }
  }
});

export const TermsAndConditionsPopup = (props: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      className={classes.dialog}
      open={props.open}
      onClose={props.handleCancel}
    >
      <Box display="flex" flexDirection={"row-reverse"}>
        <IconButton onClick={props.handleCancel}>
          <img src={closeFile} alt="Close Modal" />
        </IconButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <img style={{width:'194px'}} src={logo} />
      </Box>
      <DialogTitle id="id" className={classes.title}>
        <Box flexGrow={1}>
          {props.showPrivacyPolicy ? "Privacy Policy" : "Terms of Use"}
        </Box>
      </DialogTitle>
      <DialogContent>
        {props.showPrivacyPolicy ? (
          <span className={classes.content}>
            <p>
              This Privacy Policy (hereafter &ldquo;Policy&rdquo;) presents the
              privacy practices for Hapchi Private Limited
              (&ldquo;Hapchi&rdquo;, &ldquo;Organisation&rdquo;,
              &ldquo;We&rdquo; or &ldquo;Us&rdquo;) regarding the usage of the
              Personal Information of the users (&ldquo;You&rdquo; or
              &ldquo;Your&rdquo;) and use of our learning platform
              www.hapchi.com (&ldquo;Website/website&rdquo;).
            </p>
            <p>{configJSON.TermsConditions.WeCare}</p>
            <p>{configJSON.TermsConditions.OurPrivacyPolicyDescribes}</p>
            <p>
              Please read this Policy carefully before using this website (
              <a href="https://www.hapchi.com" target="_blank">
                www.hapchi.com
              </a>
              ), along with the Terms of Use provided on the website. For the
              purpose of this Privacy Policy, &ldquo;Personal Information&rdquo;
              is defined as any information that can either identifies you as an
              individual (&quot;Personal Information&quot;) or that may be
              connected to you indirectly by linking it to Personal Information.
              We urge you not to access or use our website if you do not agree
              with the terms of this Policy.
            </p>
            <h5>{configJSON.TermsConditions.TypesOfInformationCollected}</h5>
            <p>{configJSON.TermsConditions.PersonalInfoCollection}</p>
            <ul>
              <li>{configJSON.TermsConditions.ContactInfo}</li>
              <li>{configJSON.TermsConditions.DemographicInformation}</li>
              <li>{configJSON.TermsConditions.InformationAbout}</li>
              <li>{configJSON.TermsConditions.OtherMoreInfo}</li>
            </ul>
            <h5>{configJSON.TermsConditions.UseOfInformation}</h5>
            <p>{configJSON.TermsConditions.WhereWeUseInfo}</p>
            <ul>
              <li>{configJSON.TermsConditions.ToProvide}</li>
              <li>{configJSON.TermsConditions.ManageAccess}</li>
              <li>{configJSON.TermsConditions.ToProcessTransaction}</li>
              <li>{configJSON.TermsConditions.ToRespondToInquiries}</li>
              <li>{configJSON.TermsConditions.ToSendPromotionalEmail}</li>
              <li>{configJSON.TermsConditions.ToPersonalizeExp}</li>
              <li>{configJSON.TermsConditions.ToImproveSecurity}</li>
              <li>
                {configJSON.TermsConditions.SharingBusinessInfo} &amp;{" "}
                {configJSON.TermsConditions.Communication}
              </li>
              <li>{configJSON.TermsConditions.AnalysingUseOfWebSite}</li>
              <li>{configJSON.TermsConditions.SupplyingNewsLetters}</li>
            </ul>
            <h5>{configJSON.TermsConditions.ScopeOfUse}</h5>
            <p>
              &#8203;{configJSON.TermsConditions.OurWebSite}{" "}
              <a href="mailto:admin@hapchi.in">admin@hapchi.in</a>
            </p>
            <p>{configJSON.TermsConditions.WeWillEnsure}</p>
            <p>{configJSON.TermsConditions.ItIsImportant}</p>
            <h5>{configJSON.TermsConditions.SharingInfo}</h5>
            <p>{configJSON.TermsConditions.WeMayContract}</p>
            <h5>{configJSON.TermsConditions.ThirdPartyLinks}</h5>
            <p>
              The{" "}
              <a href="https://www.hapchi.com" target="_blank">
                www.hapchi.com
              </a>{" "}
              {configJSON.TermsConditions.WebSiteMay}
            </p>
            <h5>{configJSON.TermsConditions.RetentionOfPersonalInformation}</h5>
            <p>{configJSON.TermsConditions.YourPersonalInfo}</p>
            <ul>
              <li>{configJSON.TermsConditions.ItMayBe}</li>
              <li>{configJSON.TermsConditions.WhereItIs}</li>
            </ul>
            <h5>{configJSON.TermsConditions.SecurityOfInformation}</h5>
            <p>{configJSON.TermsConditions.WeTakeReasonable}</p>
            <h5>{configJSON.TermsConditions.Cookies}</h5>
            <p>{configJSON.TermsConditions.HapchiMay}</p>
            <p>{configJSON.TermsConditions.TheWebSite}</p>
            <p>
              {configJSON.TermsConditions.TheseCookies} &quot;targeted
              advertising&quot; through other companies' sites.
            </p>
            <h5>{configJSON.TermsConditions.UserConsent}</h5>
            <p>{configJSON.TermsConditions.ByRegistering}</p>
            <h5>{configJSON.TermsConditions.ChangesToThisPrivacyPolicy}</h5>
            <p>{configJSON.TermsConditions.WeMayUpdate}</p>
            <h5>{configJSON.TermsConditions.ContactInformation}</h5>
            <p>
              {configJSON.TermsConditions.IfYouHave}{" "}
              <a href="mailto:admin@hapchi.in">admin@hapchi.in</a>
            </p>
          </span>
        ) : (
          <span className={classes.content}>
            <p>
              Our Company{" "}
              <b>{configJSON.TermsConditions.HAPCHIPRIVATELIMITED}</b> welcomes
              you to the website, on behalf of itself and its affiliates and
              licensors, licensees or service providers
              (&quot;Organization&rdquo;, also referred to as &ldquo;us&rdquo;,
              &ldquo;our&rdquo; or &ldquo;we&rdquo;). These Terms of Use governs
              the users (&ldquo;you&rdquo; or &ldquo;your&rdquo;) access to and
              use of our website, including any content, functionality and
              services offered on or through our website, and the treatment of
              any personal information that you choose to submit.
              <br />
              {configJSON.TermsConditions.IfYouAre}
            </p>

            <p>
              You represent and warrant that you either (a) are an individual of
              legal age to form a binding contract or (b) you&rsquo;ve received
              your parent&rsquo;s or guardian&rsquo;s permission to use the
              Services and have gotten your parent or guardian to agree to these
              Terms on your behalf. If you&rsquo;re agreeing to these Terms on
              behalf of an organization or entity, you represent and warrant
              that you are authorized to agree to these Terms on that
              organization&rsquo;s or entity&rsquo;s behalf and bind them to
              these Terms (in which case, the references to &ldquo;you&rdquo;
              and &ldquo;your&rdquo; in these Terms, except for in this
              sentence, refer to that organization or entity).
            </p>

            <h5>{configJSON.TermsConditions.AcceptanceOfTheTermsOfUse}</h5>

            <p>
              Whether you have enrolled in a Program or are simply browsing the
              Platform, once you have accessed the Platform or Programs, you
              shall be considered a &lsquo;user&rsquo; for the purpose of these
              Terms. You are responsible for all your activities in connection
              with the use of the Platform and Programs.
            </p>

            <p>{configJSON.TermsConditions.EachTime}</p>

            <h5>{configJSON.TermsConditions.ChangesToThisTermsOfUse}</h5>
            <p>
              {configJSON.TermsConditions.WeMayRevise}
              <br />
              {configJSON.TermsConditions.IfYouUse}
              <br />
              {configJSON.TermsConditions.WeReserve}
            </p>
            <h5>{configJSON.TermsConditions.AccessToTheWebsite}</h5>
            <p>
              {configJSON.TermsConditions.YouAreRes}
              <br />
              {configJSON.TermsConditions.ToAccess} (&ldquo;User Data&rdquo;).
              You agree that all information you provide us to register with
              this website or otherwise is complete, correct and accurate. You
              consent to all actions we take with respect to your information
              consistent with our Privacy Policy.
            </p>

            <h5>{configJSON.TermsConditions.Security}</h5>
            <p>
              {configJSON.TermsConditions.YouAlsoAck}
              <br />
              {configJSON.TermsConditions.WeHaveThe}
            </p>
            <h5>{configJSON.TermsConditions.IntellectualPropertyRights}</h5>
            <p>
              {configJSON.TermsConditions.TheWebSiteAnd}
              <br />
              {configJSON.TermsConditions.TheseTerms}
              <br />
              {configJSON.TermsConditions.IfYouWish}{" "}
              <a href="mailto:admin@hapchi.in">admin@hapchi.in</a>.
            </p>

            <p>{configJSON.TermsConditions.NoRight}</p>
            <h5>{configJSON.TermsConditions.HapchiClassroom}</h5>
            <p>
              Teachers, parents, and other educational group leaders
              (&ldquo;Educators&rdquo;) may invite users to join a private group
              through the website (&ldquo;Classrooms&rdquo; and the Classroom
              participants, the &ldquo;Students&rdquo;). Educators may authorize
              Students to upload and interact with text, graphics, data,
              articles, photos, images, illustrations, etc.
              (&ldquo;Content&rdquo;). Educators may upload unique Content,
              assign projects to Students, and interact with Students&rsquo;
              projects.
              <br />
              {configJSON.TermsConditions.IfYouAreAnEducator}
              <br />
              {configJSON.TermsConditions.BecauseStudents}{" "}
              <a href="mailto:admin@hapchi.in">admin@hapchi.in</a>.<br />
            </p>
            <h5>{configJSON.TermsConditions.ProhibitedUses}</h5>
            <p>
              You may access and use the website only for lawful purposes and in
              accordance with these Terms of Use. The Organization reserves the
              right to investigate and take appropriate legal action against
              anyone who, in the Organization&rsquo;s sole discretion, violates
              this provision, including without limitation terminating the usage
              of such violators. <br />
              {configJSON.TermsConditions.ProhibitedContent}
            </p>
            <ul>
              <li>{configJSON.TermsConditions.Infringe}</li>
              <li>{configJSON.TermsConditions.ViolatesAny}</li>
              <li>{configJSON.TermsConditions.IsDangerous}</li>
              <li>
                jeopardizes the security of your Hapchi User ID, account or
                anyone else&rsquo;s (such as allowing someone else to log in to
                the Services as you);
              </li>
              <li>{configJSON.TermsConditions.Attempts} </li>
              <li>{configJSON.TermsConditions.AnAttempts}</li>
              <li>{configJSON.TermsConditions.PromotesRacism}</li>
              <li>{configJSON.TermsConditions.PromotesInfo}</li>
              <li>{configJSON.TermsConditions.HarmsOf}</li>
              <li>{configJSON.TermsConditions.PromotesAnIllegal} </li>
              <li>{configJSON.TermsConditions.Sends}</li>
              <li>
                {configJSON.TermsConditions.Involves} &quot;junk mail,&quot; or
                &quot;spam&quot; {configJSON.TermsConditions.OrAny}
              </li>
              <li>{configJSON.TermsConditions.AttemptsToImpersonate}</li>
              <li>{configJSON.TermsConditions.ExposeTheWeb}</li>
              <li>{configJSON.TermsConditions.OtherwiseAttempts}</li>
            </ul>

            <h5>User Submissions</h5>

            <p>
              The website may contain message boards, chat rooms, classrooms,
              personal web pages or profiles, bulletin boards and other
              interactive features that allow users to post, submit, publish,
              display or transmit to other users or other persons (hereinafter,
              &quot;post&quot;) content or materials (collectively, &quot;User
              Submissions&quot;) on or through the website. All User Submissions
              must comply with the content standards as set out herein above in
              Prohibited Uses. Any User Submissions you post to the website will
              be considered non-confidential and non-proprietary. All of your
              User Submissions do and will comply with these Terms of Use.
              <br />
              By providing any User Submissions on the website, you own and
              control all rights in User Submissions and grant us and our
              affiliates and service providers, and each of their and our
              respective licensees, successors and assigns the right to use,
              reproduce, modify, perform, display, distribute and otherwise
              disclose to third parties any such material.
              <br />
              You understand and acknowledge that you are responsible for any
              User Submissions you submit or contribute, and you, not the
              Organization, have full responsibility for such content, including
              its legality, reliability, accuracy and appropriateness.
            </p>
            <h5>Termination</h5>
            <p>
              We have the right to (i) remove or refuse to post any User
              Submissions for any or no reason in our sole discretion; (ii) take
              any action with respect to any User Submissions that we deem
              appropriate in our sole discretion, if we believe that such User
              Submissions violate the Terms of Use, infringe any intellectual
              property right or other rights of any person or entity, or could
              create liability for the Organization; (iii) terminate or suspend
              your access to all or part of the website for any violation of
              these Terms of Use.
              <br />
              You agree to hold harmless the Organization from any claims
              resulting from any action during or as a result of its
              investigations and from any actions taken as a consequence of
              investigations by either the organization/such parties or law
              enforcement authorities with respect to your User Submissions, and
              agree to waive any claims against the Organization resulting from
              any such actions where your User Submissions are found to be in
              violation of these Terms of Use.
              <br />
            </p>
            <h5>No Liability of Information</h5>
            <p>
              Information provided on or through the website is made available
              solely for general information purposes. We do not warrant the
              accuracy, completeness or usefulness of any information on the
              website. We disclaim all liability and responsibility arising from
              any reliance placed on such materials by you or any other visitor
              to the website, or by anyone who may be informed of any of its
              contents.
              <br />
              This website may include content provided by third parties,
              including materials provided by other users, bloggers and
              third-party licensors, syndicators, aggregators and/or reporting
              services. All statements and/or opinions expressed in these
              materials, and all articles and responses to questions and other
              content, other than the content provided by the Organization, are
              solely the opinions and the responsibility of the person or entity
              providing those materials. We are not responsible, or liable to
              you or any third party, for the content or accuracy of any
              materials provided by any third parties.
              <br />
            </p>
            <h5>Other Links</h5>
            <p>
              If the website contains links to other websites and resources
              provided by third parties, these links are provided for your
              convenience only. This includes links contained in advertisements,
              including banner advertisements and sponsored links. Please be
              aware that we are not responsible for the privacy practices, or
              content, of these websites. We encourage our users to be aware
              when they leave our website and to read the privacy statements of
              these websites. You should evaluate the security and
              trustworthiness of any other website connected to this website or
              accessed through this website yourself, before disclosing any
              personal information to them. We have no control over the contents
              of those websites or resources, and accept no responsibility for
              them or for any loss or damage that may arise from your use of
              them.{" "}
            </p>
            <h5>Non transferability</h5>
            <p>
              You agree that your account on the website is non-transferable and
              any rights to your ID or contents with your said account
              automatically terminate upon your death.
            </p>
            <h5>Disclaimer of Warranties and Liability</h5>
            <p>
              You acknowledge that we cannot and do not guarantee that content
              available for downloading from the website will be free of
              viruses. You are responsible for implementing sufficient
              procedures and checks to satisfy your particular requirements for
              anti-virus protection and accuracy of data input and output, and
              for maintaining a backup to our website for recovery of lost data.
              <br />
              We will not be liable for any loss or damage caused by a
              distributed denial-of-service attack, viruses or other
              technologically harmful material that may infect your computer
              equipment, computer programs, data or other proprietary material
              due to your use of the website or any services or items obtained
              through the website or to your downloading of any material posted
              on it, or on any website linked to it.
              <br />
              Your use of the website is at your own risk, and its content and
              any services or items obtained through the website are provided on
              an &quot;as is&quot; and &quot;as available&quot; basis.
            </p>
            <h5>Limitation on Liability</h5>
            <p>
              In no event will the Organization, or its employees, agents,
              officers or directors be liable for damages of any kind, under any
              legal theory, arising out of or in connection with your use, or
              inability to use, the website, any linked to it, any content on
              the website or such other websites or any services or items
              obtained through the website or such other websites, including any
              direct, indirect, special, incidental, consequential or punitive
              damages, including but not limited to, personal injury, pain and
              suffering, emotional distress, loss of revenue, loss of profits,
              loss of business or anticipated savings, loss of use, loss of
              goodwill, loss of data, and whether caused by tort (including
              negligence), breach of contract or otherwise, even if foreseeable.
            </p>
            <h5>Indemnification</h5>
            <p>
              You agree to defend, indemnify and hold harmless the Organization,
              as well as its respective officers, directors, employees,
              contractors, agents, licensors, suppliers, successors and assigns
              from and against any claims, liabilities, damages, investigations,
              judgments, awards, losses, costs, expenses or fees (including
              reasonable attorneys' fees) arising out of or relating to your
              violation of these Terms of Use or your use of the website,
              including, but not limited to, your User Submissions, any use of
              the website's content, services and products other than as
              expressly authorized in these Terms of Use or your use of any
              information obtained from the website.
            </p>
            <h5>Governing Law and Jurisdiction</h5>
            <p>
              All matters relating to the website and these Terms of Use and any
              dispute or claim arising therefrom or related thereto (in each
              case, including non-contractual disputes or claims), shall be
              governed by and construed in accordance with the internal laws of
              India without giving effect to any choice or conflict of law
              provision or rule (whether of India or any other jurisdiction).
              <br />
              Any legal suit, action or proceeding arising out of, or related
              to, these Terms of Use or the website shall be instituted
              exclusively in India, although we retain the right to bring any
              suit, action or proceeding against you for breach of these Terms
              of Use in your country of residence or any other relevant country
              if appropriate. You waive any and all objections to the exercise
              of jurisdiction over you by such courts and to venue in such
              courts.
            </p>
            <h5>Force Majeure</h5>
            <p>
              We shall have no liability to you for any interruption or delay in
              access to the website irrespective of the cause.
            </p>
            <h5>{configJSON.TermsConditions.WaiverAndSeverability}</h5>
            <p>
              No waiver of by the Organization of any term or condition set
              forth in these Terms of Use shall be deemed a further or
              continuing waiver of such term or condition or a waiver of any
              other term or condition, and any failure of the Organization to
              assert a right or provision under these Terms of Use shall not
              constitute a waiver of such right or provision.
              <br />
              If any provision of these Terms of Use is held by a court or other
              tribunal of competent jurisdiction to be invalid, illegal or
              unenforceable for any reason, such provision shall be eliminated
              or limited to the minimum extent such that the remaining
              provisions of the Terms of Use will continue in full force and
              effect.
            </p>
            <h5>{configJSON.TermsConditions.EntireAgreement}</h5>
            <p>{configJSON.TermsConditions.UserOfPrivacyPolicy}</p>

            <h5>{configJSON.TermsConditions.YourCommentsAndConcerns}</h5>
            <p>
              {configJSON.TermsConditions.OperatedBy}
              <br />
              {configJSON.TermsConditions.AllFeedback}{" "}
              <a href="mailto:admin@hapchi.in">admin@hapchi.in</a>.
            </p>
            <p>{configJSON.TermsConditions.ThanksForVisiting}</p>
          </span>
        )}
      </DialogContent>
    </Dialog>
  );
};
// Customizable Area End

// Customizable Area Start
// Customizable Area End
