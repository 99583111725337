import React from "react";
// Customizable Area Start
import SidebarLayout from "../../SidebarLayout.web";
import { Box, styled } from "@material-ui/core";
import MaterialDetailsController, {
  Props
} from "./MaterialDetailsController.web";
import PageContentLayout from "../../PageContentLayout.web";
import { Document, Page } from "react-pdf";
// Customizable Area End

export default class MaterialDetails extends MaterialDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { numPages } = this.state;
    return (
      // Customizable Area Start
      <>
        <SidebarLayout {...this.props}>
          <PageContentLayout
            className="boxHide"
            goBack={this.goBack}
            data-test-id="goBackTestId"
          >
            <IframeStyle>
              <Document
                className="documentStyle"
                file={this.state.modifiedPdfUrl}
                data-test-id="modifiedPdfTestId"
                onLoadSuccess={this.onDocumentLoadSuccess}
                renderMode="canvas"
                externalLinkTarget="_self"
              >
                {Array.from(new Array(numPages), (pagenumber, numPageIndex) => (
                  <Page
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    key={`page_${numPageIndex + 1}`}
                    pageNumber={numPageIndex + 1}
                  />
                ))}
              </Document>
              <Box
                data-test-id="disableStyle"
                className="boxStyle"
                onContextMenu={this.disableContextMenu}
              />
            </IframeStyle>
          </PageContentLayout>
        </SidebarLayout>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const IframeStyle = styled(Box)({
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  "& .boxHides": {
    position: "absolute",
    height: "50px",
    bottom: "0px",
    width: "100%",
    background: "#d6dad6"
  },
  "& .documentStyle": {
    height: "100vh",
    overflowX: "auto"
  }
});
// Customizable Area End
