// Menu class
/*
Vertical single level menu

It is called with an object containing the following properties:

  parentDiv : name of a div, or the div itself
  idDivMenu : id which will be attributed to the menu div (child of parentDiv)
  title : text for title of menu. remains visible when menu closed
  lineOffset : vertical position of 1st line of menu
  lineStep : vertical distance between top of two menu lines
  lines : Array. Each element of this array is an object with two properties :
          - text : text line
          - func : "onclick" callback associated with line

*/
// Customizable Area Start
class Menu {
  list: any[];
  divMenu: HTMLDivElement;

  constructor(params: {
    parentDiv: any;
    idDivMenu: any;
    title: any;
    lineOffset: any;
    lineStep: any;
    lines: any;
  }) {
    let parentDiv = params.parentDiv;
    if (typeof parentDiv == 'string')
      parentDiv = document.getElementById(parentDiv);
    // div menu
    let divMenu = document.createElement('div');
    divMenu.setAttribute('id', params.idDivMenu);
    // title
    let dt = document.createElement('div');
    dt.classList.add('title');
    dt.appendChild(document.createTextNode(params.title));
    let that = this;
    dt.addEventListener('click', () => that.display());
    divMenu.appendChild(dt);
    this.list = [];
    for (let k = 0; k < params.lines.length; ++k) {
      dt = document.createElement('div');
      dt.classList.add('line');
      dt.appendChild(document.createTextNode(params.lines[k].text));
      dt.style.top = params.lineOffset + k * params.lineStep + 'px';
      dt.addEventListener('click', params.lines[k].func);
      divMenu.appendChild(dt);
      this.list.push(dt);
    }
    divMenu.style.height =
      params.lineOffset +
      params.lines.length * params.lineStep +
      'px';
    this.divMenu = divMenu;
    parentDiv.appendChild(divMenu);
  }

  collapse() {
    this.divMenu.classList.remove('open');
  }

  display() {
    this.divMenu.classList.add('open');
  }
}

export default Menu;
// Customizable Area End
