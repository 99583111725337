import React from "react";

// Customizable Area Start
import EmailVerify from "./EmailVerify.web";
import OtpPage from "./OtpPage.web";
import PasswordChanged from "./PasswordChanged.web";
import CreatePassword from "./CreatePassword.web";
import ForgotPassword, { Props } from "./ForgotPasswordController";
import { AppBar, Toolbar, InputAdornment, IconButton, Box, Button, TextField, Grid, Container, Typography } from "@material-ui/core";

import {
  logo,
  right,
  left,
  step1Active,
  step2Active,
  step2Inactive,
  step3Active,
  step3Inactive,
  step4Active,
  step4Inactive,
} from "./assets";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
// Customizable Area End
// Customizable Area Start
interface stpeIconProps {
  completed: boolean;
  active: boolean;
  error: boolean;
  icon: number;
}

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 30,
    left: "calc(-50% + 30px)",
    right: "calc(50% + 30px)"
  },
  active: {
    "& $line": {
      borderColor: "#0056A3"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#0056A3"
    }
  },
  line: {
    borderColor: "#D4D4D4",
    borderTopWidth: 2
  }
})(StepConnector) as any;

export const StepIcon = (props: stpeIconProps) => {
  let icon = <></>;
  switch (props.icon) {
    case 2:
      icon = <img src={(props.active || props.completed ? step2Active : step2Inactive)} />
      break;
    case 3:
      icon = <img src={(props.active || props.completed ? step3Active : step3Inactive)} />
      break;
    case 4:
      icon = <img src={(props.active || props.completed ? step4Active : step4Inactive)} />
      break;
    default:
      icon = <img src={step1Active} alt="" />;
  }
  return <>{icon}</>;
};

// Customizable Area End
export default class Signup extends ForgotPassword {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFormElements = () => {
    return (
      <Stepper
        style={webStyles.stepper}
        activeStep={this.state.step}
        connector={<QontoConnector />}
        alternativeLabel
      >
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Enter Email ID</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Enter OTP</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Create New Password</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Password Changed</StepLabel>
        </Step>
      </Stepper>
    );
  };

  renderFormStep = () => {
    const { stepOne } = webStyles;
    const updateEmail = (email: string) => {
      this.setState({ email });
    }
    const emailVerifyData = {
      updateEmail: updateEmail,
      handleSubmit: this.doEmailVerify,
      email: this.state.email,
      emailError: this.state.emailError,
    }
    const otpPageData = {
      handleSubmit: this.doOtpVerify,
      email: this.state.email,
      otpError:this.state.otpError,
      resendOtp: this.resendOtp,
      otpSent: this.state.otpSent,
    }

    const updateNewPass = (newPass: string) => {
      this.setState({ newPass });
    }
    const confirmPass = (confirmPass: string) => {
      this.setState({ confirmPass });
    }
    const createPassword = {
      handleSubmit: this.createPassword,
      updateNewPass: updateNewPass,
      confirmPass: confirmPass,
      passMismatchError: this.state.passMismatchError,
      passLengthError:this.state.passLengthError,
      enablePasswordForgotField: this.state.enablePasswordForgotField,
      handleClickForgotShowPassword: this.handleClickForgotShowPassword,
      enableConfirmPassForgotField:this.state.enableConfirmPassForgotField,
      handleClickForgotShowConfirmPass: this.handleClickForgotShowConfirmPass,
      passExists: this.state.passExists
    }
    return (
      <Container>
        <Box style={stepOne}>
          {this.state.step === 0 && (
            <EmailVerify {...emailVerifyData} />
          )}
          {this.state.step === 1 && (
            <OtpPage {...otpPageData} />
          )}
          {this.state.step === 2 && (
            <CreatePassword {...createPassword} />
          )}
          {this.state.step === 3
            && (
              <PasswordChanged navigation={undefined} />
            )}
        </Box>
      </Container>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { signupWrapper, stepContentWrapper, stepBox } = webStyles;
    return (
      <div style={signupWrapper}>
        <AppBar
          style={{
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.0760489)',
            backgroundColor: "#F0F4F6",
            padding: '0',
            backgroundImage: `url(${left}),url(${right})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top left ,top right',
          }}
          className="signup-appBarContainer"
        >
          <Toolbar>
              <Box style={{height:'96px', display:'flex',alignItems:'center'}}>
                <img style={{width:'171px',height:'56px'}} src={logo} alt="Logo" />
              </Box>
          </Toolbar>
        </AppBar>
        <Container>
          <Box style={stepBox}>{this.renderFormElements()}</Box>
        </Container>
        <div style={stepContentWrapper}>{this.renderFormStep()}</div>
      </div>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyles = {
  signupWrapper: { position: "relative" as "relative" },
  chooseAccountBgimgLeft: {
    position: "absolute" as "absolute",
    width: "400px",
    bottom: "0",
    left: "0",
    zIndex: -1
  },
  chooseAccountBgimgRight: {
    position: "absolute" as "absolute",
    width: "400px",
    bottom: "0",
    right: "0",
    zIndex: -1
  },
  stepper: { background: "transparent" },
  stepContentWrapper: { marginBottom: "136px" },
  stepBox: {
    margin: '120px auto 0 ',
    maxWidth: '800px',
  },
  stepOne: {
    maxWidth: '700px',
    margin: 'auto',
  },
  checkIconActive: {
    color: '#27ae60',
    width: '50px',
    fontSize: '33px',
  }
};
// Customizable Area End