import React, { useEffect, useState } from 'react';
// Customizable Area Start
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import { Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import UnpinModal from './UnpinModal.web';
import moment from 'moment';
import 'moment-timezone';
import { apiCall } from '../../../landingpage/src/utils';
import { configJSON } from './SeekHelpController';
// Customizable Area End
// Customizable Area Start
export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  lastMsg: string;
  test?: boolean;
  testArray?: any;
}
// Customizable Area End
const useStyles = makeStyles({
  // Customizable Area Start
  /*  */

  date: {
    color: "#7D7D7D",
    position: "relative",
  },
  /*  */

  userName: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  crossIcon: {
    backgroundColor: "#3B3B3B",

    borderRadius: "5px",
    padding: "0.2rem",
    fontWeight: "bold",
    color: "white",
    cursor: "pointer",
  },
  /*  */

  pinIcon: {
    backgroundColor: "#3B3B3B",
    padding: "0.2rem",
    color: "white",
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
  },

  /*  */

  pinnedIcon: {
    padding: "0.2rem",
    backgroundColor: "#0056A5",
    color: "white",
    borderRadius: "5px",
    fontWeight: "bold",
  },
  avatar: {
    color: blue[600],
    backgroundColor: blue[100],
  },
  dialogTitle: {
    backgroundColor: "#0056A5",
    color: "white",
  },
  /*  */

  listItemBox: {
    margin: "15px",
    backgroundColor: "#E8E8E8",
    borderRadius: "5px",
  },
  /*  */

  listItem: {
    display: "flex",
    justifyContent: "space-between",
  },

  listItemText: {
    paddingBottom: "10px",
    marginLeft: "4.6rem",
    display: "flex",
  },
  content: {
    gap: "20px",
    display: "flex",
    width: "395px",
  },
  icons: {
    display: "flex",
    marginLeft: "20px",
    gap: "10px",
    justifyContent: "flex-end",
  },
  // Customizable Area End
});
// Customizable Area Start
export const dateShow = (date: any) => {
  const indianTime = moment.unix(date).tz("Asia/Kolkata");
  const formattedDate = indianTime.format("Do MMM YYYY, h:mm A");
  return <div>{formattedDate}</div>;
};
// Customizable Area End
function PinModal(props: SimpleDialogProps) {
  // Customizable Area Start
  const classes = useStyles();
  const { onClose, open } = props;
  const [unpinnedModalOpen, setUnpinnedModalOpen] = useState(false);
  const [unpinIt, setUnpinIt] = useState(configJSON.SeekHelp.Unpinit);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [pinMessages, setPinMessages] = useState<any>(
    props.testArray ? props.testArray : []
  );

  const getPinMessages = async () => {
    let user_id = "";
    let user_role: any = "";
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let admin_id = params.get(configJSON.SeekHelp.AdminId);
    if (admin_id) {
      user_id = admin_id.replace("admin_", "");
      user_role = configJSON.SeekHelp.SuperAdmin;
    } else {
      user_id = JSON.parse(`${localStorage.getItem(configJSON.SeekHelp.UserDetails)}`).id;
      user_role = localStorage.getItem(configJSON.SeekHelp.Role);
    }
    const header = {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("user_token"),
    };

    const res = await apiCall(
      "get",
      `${configJSON.SeekHelp.ListPinMsgEndPoint}?user_id=${user_id}&role=${user_role}`,
      header
    );
    if (res) {
      localStorage.setItem(configJSON.SeekHelp.PinMessages, JSON.stringify(res.pinned_messages));

      setPinMessages(res.pinned_messages);
    }
  };
  useEffect(() => {
    getPinMessages();
  }, [props.open, unpinnedModalOpen]);

  const handleUnpinned = () => {
    setUnpinnedModalOpen(true);
    setUnpinIt("unpinIt");
  };

  const handleCloseUnpinned = () => {
    setUnpinnedModalOpen(false);
  };

  React.useEffect(() => {
    const currentPinMessage = localStorage.getItem("currentPinMessage");
    const initialSelectedMessage = currentPinMessage
      ? JSON.parse(currentPinMessage)
      : null;
    setSelectedMessage(initialSelectedMessage);
  }, []);

  const handleItemClick = (message: any) => {
    setSelectedMessage(message);
    localStorage.setItem(configJSON.SeekHelp.CurrentPinMessage, JSON.stringify(message));
  };

  const handleRemoveData = (ele: any, pin_id: number) => {
    handleUnpinned();
    handleItemClick({"pin_id":pin_id, ...ele });
    if (props.test) {
      getPinMessages();
    }
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle className={classes.dialogTitle}>{configJSON.SeekHelp.PinnedMessages}</DialogTitle>
      <List>
        {!pinMessages || !pinMessages.length ? (
          <div>{configJSON.SeekHelp.NoDataFound}</div>
        ) : (
          pinMessages.map(({ data: ele, pin_id }: any, index: number) => (
            <Box key={index} className={classes.listItemBox}>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  {ele?.avtar ? (
                    <Avatar className={classes.avatar}>
                      <img src={ele?.avtar} alt={ele?.sender} />
                    </Avatar>
                  ) : (
                    <Avatar>{ele?.sender.charAt(0).toUpperCase()}</Avatar>
                  )}
                </ListItemAvatar>
                <Box className={classes.content}>
                  <Box className={classes.userName}>{ele?.sender}</Box>
                  <Box className={classes.date}>{dateShow(ele?.date)}</Box>
                </Box>
                <Box className={classes.icons}>
                  <Box
                    data-testid="handleUnpinMsg"
                    className={classes.crossIcon}
                    onClick={() => handleRemoveData(ele, pin_id)}
                  >
                    <CloseIcon />
                  </Box>
                </Box>
              </ListItem>
              <ListItemText
                primary={
                  <div>
                    {ele?.content ? (
                      ele.content
                    ) : (
                      <a href={ele?.fileUrl}>{ele?.fileName}</a>
                    )}
                  </div>
                }
                className={classes.listItemText}
              />
            </Box>
          ))
        )}
      </List>
      <UnpinModal
        open={unpinnedModalOpen}
        onClose={handleCloseUnpinned}
        unpinit={unpinIt}
        removeMessage={selectedMessage}
        onPinIt={function(): void {
          throw new Error("Function not implemented.");
        }}
      />
    </Dialog>
  );
  // Customizable Area End
}

export default PinModal;

// Customizable Area Start
// Customizable Area End
