import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
// Customizable Area Start

import { CometChatNavBar } from "./CometChatNavBar";
import { CometChatMessages } from "../Messages";

import { CometChatContextProvider } from "../../util/CometChatContext";
import * as enums from "../../util/enums.js";
import { theme } from "../../resources/theme";
import Translator from "../../resources/localization/translator";

import { unifiedStyle, unifiedSidebarStyle, unifiedMainStyle } from "./style";
// Customizable Area End
class CometChatUI extends React.Component {
	// Customizable Area Start
	loggedInUser = null;
	// Customizable Area End

	constructor(props) {
		super(props);
		// Customizable Area Start
		this.state = {
			sidebarview: false,
			clearCount:'',
		};

		this.navBarRef = React.createRef();
		this.contextProviderRef = React.createRef();
		// Customizable Area End
	}

	// Customizable Area Start
	componentDidMount() {
		if (
			this.props.chatWithUser.length === 0 
			// this.props.chatWithGroup.length === 0
		) {
			this.toggleSideBar();
		}
	}

	navBarAction = (action, type, item) => {
		switch (action) {
			case enums.ACTIONS["ITEM_CLICKED"]:
				this.itemClicked(item, type);
				break;
			case enums.ACTIONS["TOGGLE_SIDEBAR"]:
				this.toggleSideBar();
				break;
			default:
				break;
		}
	};

	itemClicked = (item, type) => {
		this.contextProviderRef.setTypeAndItem(type, item);
		this.toggleSideBar();
	};

	actionHandler = (action, item, count, ...otherProps) => {
		switch (action) {
			case enums.ACTIONS["TOGGLE_SIDEBAR"]:
				this.toggleSideBar();
				break;
			case enums.GROUP_MEMBER_SCOPE_CHANGED:
			case enums.GROUP_MEMBER_KICKED:
			case enums.GROUP_MEMBER_BANNED:
				this.groupUpdated(action, item, count, ...otherProps);
				break;
			default:
				break;
		}
	};

	toggleSideBar = () => {
		const sidebarview = this.state.sidebarview;
		this.setState({ sidebarview: !sidebarview });
	};

	clearUnreadMessage = (clear) => {
		this.setState({ clearCount: clear });
	};

/**
	 If the logged in user is banned, kicked or scope changed, update the chat window accordingly
	 */
	 groupUpdated = (key, message, group, options) => {
		switch (key) {
			case enums.GROUP_MEMBER_BANNED:
			case enums.GROUP_MEMBER_KICKED: {
				if (
					this.contextProviderRef.state.type ===
						CometChat.ACTION_TYPE.TYPE_GROUP &&
					this.contextProviderRef.state.item.guid === group.guid &&
					options.user.uid === this.loggedInUser.uid
				) {
					console.log('key, message, group, options',key, message, group, options);
					this.contextProviderRef.setItem({});
					this.contextProviderRef.setType("");
				}
				break;
			}
			case enums.GROUP_MEMBER_SCOPE_CHANGED: {
				if (
					this.contextProviderRef.state.type ===
						CometChat.ACTION_TYPE.TYPE_GROUP &&
					this.contextProviderRef.state.item.guid === group.guid &&
					options.user.uid === this.loggedInUser.uid
				) {
					const newObject = Object.assign(
						{},
						this.contextProviderRef.state.item,
						{ scope: options["scope"] }
					);
					this.contextProviderRef.setItem(newObject);
					this.contextProviderRef.setType(CometChat.ACTION_TYPE.TYPE_GROUP);
				}
				break;
			}
			default:
				break;
		}
	};
	// Customizable Area End
	render() {
		// Customizable Area Start
		let messageScreen = (
			<CometChatMessages
				sendNotificationTotrainerOnMail={this.props.sendNotificationTotrainerOnMail}
				theme={this.props.theme}
				lang={this.props.lang}
				_parent='unified'
				actionGenerated={this.actionHandler}
				clearUnreadMessage={this.clearUnreadMessage}
			/>
		);

		return (
			<CometChatContextProvider
				ref={(el) => (this.contextProviderRef = el)}
				user={this.props.chatWithUser}
				// group={this.props.chatWithGroup}
				// language={this.props.lang}
			>
				<div
					css={unifiedStyle(this.props)}
					className='cometchat cometchat--unified'
					dir={Translator.getDirection(this.props.lang)}
				>
					<div
						css={unifiedSidebarStyle(this.state, this.props)}
						className='unified__sidebar'
					>
						<CometChatNavBar
						   clearCount={this.state.clearCount}
						   clearUnreadMessage={this.clearUnreadMessage}
						   selectUserForChat={this.props.selectUserForChat}
						   selectedUser={this.props.selectedUser}
						   isAdmin={this.props.isAdmin}
						   userRole={this.props.userRole}
							ref={(el) => (this.navBarRef = el)}
							theme={this.props.theme}
							actionGenerated={this.navBarAction}
						/>
					</div>
					<div
						css={unifiedMainStyle(this.state, this.props)}
						className='unified__main'
					>
						{messageScreen}
					</div>
					{/* <CometChatIncomingCall
						theme={this.props.theme}
						lang={this.props.lang}
						actionGenerated={this.actionHandler}
					/> */}
					{/* <CometChatIncomingDirectCall
						theme={this.props.theme}
						lang={this.props.lang}
						actionGenerated={this.actionHandler}
					/> */}
				</div>
			</CometChatContextProvider>
		);
		// Customizable Area End
	}
}

// Customizable Area Start
// Specifies the default values for props:
CometChatUI.defaultProps = {
	lang: Translator.getDefaultLanguage(),
	theme: theme,
	chatWithUser: "",
	// chatWithGroup: "",
};

CometChatUI.propTypes = {
	lang: PropTypes.string,
	theme: PropTypes.object,
	chatWithUser: PropTypes.string,
	// chatWithGroup: PropTypes.string,
};

export { CometChatUI };
// Customizable Area End
