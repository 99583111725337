export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgProfile = require("../assets/profile.svg");
export const left = require("../assets/topBarLeft.png");
export const right = require("../assets/topBarRight.png");
export const logo = require("../assets/hapchiMainLogo.svg");
export const mainLogo = require("../assets/mainlogo.png");
export const school = require("../assets/school-building.svg");
export const trainer = require("../assets/teacher.png");
export const student = require("../assets/student.png");
export const parent = require("../assets/parent.png");
export const step1Active = require("../assets/step-1-active.png");
export const step2Active = require("../assets/step-2-active.png");
export const step3Active = require("../assets/step-3-active.png");
export const step4Active = require("../assets/step-4-active.png");
export const step2Inactive = require("../assets/step-2-inactive.png");
export const step3Inactive = require("../assets/step-3-inactive.png");
export const step4Inactive = require("../assets/step-4-inactive.png");
export const successfullyApprove = require("../assets/successfully-approve.webp");
export const mailVerify = require("../assets/mail-verify.png");
export const arrowDown = require("../assets/arrow-down.png");
export const profileAdIcon = require("../assets/profile_copy.png");
export const messageIcon = require("../assets/message.png");
export const lockIcon = require("../assets/lock.png");
export const calendarIcon = require("../assets/calendar.png");
export const blueBox1 = require("../assets/blueBox1.png");
export const blueBox2 = require("../assets/blueBox2.png");
export const chooseAccountBL = require("../assets/chooseAccountBL.png");
export const chooseAccountBR = require("../assets/chooseAccountBR.png");
export const schoolIcon = require('../assets/schoolIcon.png')
export const lock = require('../assets/lock.png')
export const profileIcon = require('../assets/profile_copy.png')
export const maleAndFemale = require('../assets/maleandfemale.png')
export const uploadIcon = require('../assets/upload-arrow.png')
export const avatar1 = require('../assets/avatar1.png')
export const avatar2 = require('../assets/avatar2.png')
export const avatar3 = require('../assets/avatar3.png')
export const avatar4 = require('../assets/avatar4.png')
export const avatar5 = require('../assets/avatar5.png')
export const closeFile = require('../assets/closeFile.png')
export const mobileIcon = require('../assets/mobileicon.png')
export const uploadIconAadhar = require('../assets/uploadIcon.png')

