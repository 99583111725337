import React from "react";
// Customizable Area Start
import { Box, Grid, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import UtilityInfo from "../../../components/src/UtilityInfo.web";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ReadMore from "../../../components/src/ReadMore";
import {ImpactDetails} from './LandingPageController'
// Customizable Area End
const configJSON = require("./config");
const featureImg = require("./assets");
const howHapchiHelp = configJSON.LandingPageContent.howHapchiHelps;

// Customizable Area Start
interface HowSchool {
  headerText: string;
  contentText: string;
  fontSize: string;
  color: string;
  secondColor: string;
  paragraphFontSize: string;
}

const howSchool: HowSchool = {
  headerText: howHapchiHelp.headerText,
  contentText: howHapchiHelp.contentText,
  fontSize: "48px",
  color: "#052B62",
  secondColor: "#5D5A6F",
  paragraphFontSize: "16px"
};
const useStyles = makeStyles((theme ) => ({

  box: {
    background: "#E0EFFF",
    border: " 2px solid #FFFFFF",
    boxShadow: "0px 2px 6px rgba(0, 12, 52, 0.159883)",
    borderRadius: "20px",
    padding: "40px 12px",
    position: "relative",
    margin:'15px',
  },
  cardTopSpace: {
    marginTop: "20px"
  },
  cardHeading: {
    fontSize: "15px",
    fontWeight: 500,
    color: "#052B62",
    marginBottom: "10px",
    display: "-webkit-box",
    lineClamp: 3,
    boxOrient: "vertical",
    overflow: "hidden",
    minHeight: "45px"
  },
  cardPara: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#052B62",
    mixBlendMode: "normal",
    opacity: 0.74,
    lineClamp: 3,
    boxOrient: "vertical",
    overflow: "hidden"
  },
  textColor: {
    color: "#052b62"
  },
  cardBox: {
    padding: "0 20px",
    textAlign: "center"
  },
  workHeading: {
    fontWeight: 600,
    fontSize: "48px",
    lineHeight: "72px",
    color: "#052B62",
    position: "relative",
    height: "100%",
    display: "flex",
    alignItems: "center",
    "&::after": {
      content: '" "',
      width: "2px",
      display: "block",
      maxHeight: "205px",
      height: "205px",
      position: "absolute",
      background: "#052B62",
      top: 0,
      right: "43px",
      [theme.breakpoints.down("sm")]: {
        display:'none',
      }
    }
   
  },
  whiteBox: {
    width: "110px",
    margin: "0 auto 10px"
  },
  impactTopSpace: {
    margin: "70px 0"
  },
  borderTop: {
    borderTop: "2px solid #052B62"
  },
  impactHeading: {
    fontSize: "32px",
    fontWeight: 700,
    color: "#052B62",
    marginTop: "10px"
  },
  impactPara: {
    fontSize: "24px",
    color: "#052B62",
    textTransform: "uppercase"
  },
  hapchiFamilySection: {
    background:
      "linear-gradient(32deg, rgba(1,86,162,1) 0%, rgba(75,144,205,1) 56%, rgba(179,219,255,1) 100%)",
    padding: "120px 0"
  },
  imageBox: {
    position: "absolute",
    top: "-21px"
  },
  fullWidthImage:{
    width:'100%',
    [theme.breakpoints.down("sm")]: {
      display:'none',
    }
  },
  fullWidthImageTwo:{
    width:'100%',
    [theme.breakpoints.up("md")]: {
      display:'none',
    }
  }
}));
const schoolsCards = [
  {
    heading: "Partner with India's first Child Safety Digital platform",
    paragraphs:
      "Be the pioneer changemakers in creating a safer school environment.",
    featureImg: featureImg.feature3
  },
  {
    heading: "Customised school Programs",
    paragraphs:
      "Programs that have been curated and tailored to meet your unique needs.",
    featureImg: featureImg.feature2
  },
  {
    heading: "Unique pedagogy",
    paragraphs:
      "Leveraging innovative methodologies with a blend of online and offline intervention programs for a fun and engaging learning experience.",
    featureImg: featureImg.feature1
  },
  {
    heading: "Impactful outcome",
    paragraphs:
      "Programs developed with measurable outcomes and impact reports for best results.",
    featureImg: featureImg.feature4
  }
];

const HowHapchiHelps = (props:{impactDetails:ImpactDetails[]}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const {impactDetails}=props
  return (
    <>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={6}>
          <Box className={classes.fullWidthImage}>
            {" "}
            <img src={featureImg.teacher} width={"100%"} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <UtilityInfo {...howSchool} />
          <Box  className={classes.fullWidthImageTwo}>
            {" "}
            <img src={featureImg.teacher} width={"100%"} />
          </Box>
          <Typography variant="body2" className={classes.textColor}>
            “Your safety concerns, our holistic personalised solutions.”
          </Typography>
          <Grid container  className={classes.cardTopSpace}>
            {schoolsCards.map((item, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={6} lg={6}>
                  {" "}
                  <Box className={classes.box}>
                    <Box className={classes.imageBox}>
                      <img src={item.featureImg} width="52px" height="52px" />
                    </Box>
                    <Typography className={classes.cardHeading}>
                      {item.heading}
                    </Typography>
                    <Typography variant="body1" className={classes.cardPara}>
                      <ReadMore sliceLength={50}>{item.paragraphs}</ReadMore>
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid container  className={classes.impactTopSpace}>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <Box>
            <Typography className={classes.workHeading}>
              The Impact :
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <Grid container>
            {impactDetails.map((impactItem, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Box className={classes.cardBox}>
                    <Box className={classes.whiteBox}>
                      <img src={impactItem.attributes.image_url} width={"100%"} height={"110px"}/>
                    </Box>
                    <Box className={classes.borderTop}>
                      <Typography className={classes.impactHeading}>
                        {`${impactItem.attributes.value}+`}
                      </Typography>
                      <Typography className={classes.impactPara}>
                        {impactItem.attributes.name}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
// Customizable Area End
export default HowHapchiHelps;
