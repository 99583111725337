import React from "react";
// Customizable Area Start
import { Grid, Box, Typography } from "@material-ui/core";
const { missionImage } = require("../assets");
const configJSON = require("../config");
import { makeStyles } from "@material-ui/core/styles";
// Customizable Area End
const missionText = configJSON.AboutUsPageContent.mission;

// Customizable Area Start
const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: "40px",
    fontWeight: 600,
    color: "#052B62"
  },
  content: {
    fontSize: "16px",
    color: "#5D5A6F",
    opacity: "0.74",
    padding: "15px 0"
  },
  imageBox: {
    display: "flex",
    marginLeft: "auto",
    maxWidth: "350px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "30px",
      justifyContent: "flex-start"
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "30px",
      justifyContent: "flex-start"
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "30px",
      marginLeft: "0"
    }
  }
}));

const Mission = () => {
  const classes = useStyles();
  return (
    <Box sx={{ pt: "100px" }}>
      <Grid container>
        <Grid item xs={12} md={7}>
          <Box>
            <Typography className={classes.heading}>
              {missionText.headerText}
            </Typography>
            <Typography variant="body1" className={classes.content}>
              {missionText.contentText}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box className={classes.imageBox}>
            <img src={missionImage} width="100%" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
// Customizable Area End
export default Mission;
