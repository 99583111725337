import React from 'react'
// Customizable Area Start
import PageContentLayout from "../../PageContentLayout.web";
import SidebarLayout from "../../SidebarLayout.web";
import TrainerMpowerYouMatterController, { S } from "./TrainerMpowerYouMatterController.web";
import { Box, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommonPagination from '../../../../../components/src/Pagination.web';
import CommonSelect from '../../CommonComponents/CommonSelect.web';
import { editPenBlue, editPenGrey, sheetBlue, sheetGrey } from '../../assets';
import Loader from '../../../../../components/src/Loader.web';
import commonStyles from '../../commonStyles.web';
// Customizable Area End
const configJSON = require("../../config.js");
// Customizable Area Start
export const useStyles = makeStyles({
    pageWrapper: {
        padding: '32px 24px 24px 24px'
    },
    evaluatedStatusCell: {
        '&.MuiGrid-item': {
            color: '#008A00',
            margin: 'auto'
        }
    },
    completeStatusCell: {
        '&.MuiGrid-item': {
            color: '#FF9300',
            margin: 'auto'
        }
    },
    pendingStatusCell: {
        '&.MuiGrid-item': {
            color: 'red',
            margin: 'auto'
        }
    },
    dropDownWrapper: {
        marginBottom: '32px'
    },
    iconButton: {
        '&.MuiIconButton-root': {
            padding: "0",
            marginRight: '12px'
        }
    },
    iconButtonRight: {
        '&.MuiIconButton-root': {
            padding: "0",
        }
    }
})

const renderIcon = (name: string) => {
    switch (name) {
        case configJSON.TrainerMPowerYouMatter.Edit: return <img src={editPenBlue} alt="" />;
        case configJSON.TrainerMPowerYouMatter.EditDisable: return <img src={editPenGrey} alt="" />;
        case configJSON.TrainerMPowerYouMatter.Show: return <img src={sheetBlue} alt="" />
        case configJSON.TrainerMPowerYouMatter.ShowDisable: return <img src={sheetGrey} alt="" />
    }
}

interface MpowerYouMatterDashboardPageProps {
    isChecksDashboard: boolean;
    isYouMatter: boolean;
    states: S;
    data: any;
    pageCount: number;
    mpowerYouMatterId: any;
    handlePage: (page: number) => void;
    goTo: (module: string, params: Object) => void;
    handleFilterChange: (event: React.ChangeEvent<{ value: any, name: any }>) => void;
    clearFilter: (name: any, type: any) => void;
}

export const MpowerYouMatterDashboardPage = (props: MpowerYouMatterDashboardPageProps) => {
    const classes = useStyles();
    const commonClasses = commonStyles({});

    const isMpowerChecksDashboard = props.isChecksDashboard && !props.isYouMatter;
    const isYouMatterCheckDashboard = props.isChecksDashboard && props.isYouMatter;

    let tableHeader = configJSON.TrainerMPowerYouMatter.DefaultTableHeader
    if (!props.isYouMatter) {
        tableHeader = configJSON.TrainerMPowerYouMatter.MPowerTableHeader;
    }
    if (isMpowerChecksDashboard) {
        tableHeader = configJSON.TrainerMPowerYouMatter.MPowerCheckTableHeader
    }
    if (isYouMatterCheckDashboard) {
        tableHeader = configJSON.TrainerMPowerYouMatter.YouMatterCheckTableHeader
    }
    const schoolFilterList = configJSON.formatDropdownList(props.states.filterSchoolList);
    const sectionFilterList = configJSON.formatDropdownList(props.states.filterSectionList);
    const courseFilterList = configJSON.formatDropdownList(props.states.filterCourseList);

    const renderActionBtns = (attributes: any, name: string) => {
        if (name === configJSON.TrainerMPowerYouMatter.Sheet) {
            return renderIcon(iconDisable(name, attributes) ? configJSON.TrainerMPowerYouMatter.ShowDisable : configJSON.TrainerMPowerYouMatter.Show)
        } else {
            return renderIcon(iconDisable(name, attributes) ? configJSON.TrainerMPowerYouMatter.EditDisable : configJSON.TrainerMPowerYouMatter.Edit)
        }
    }

    const handleOnClickActionButtons = (type: string, studentId: any) => {
        if (type === configJSON.TrainerMPowerYouMatter.Sheet) {
            props.goTo(props.isYouMatter ? configJSON.TrainerMPowerYouMatter.TrainerYouMatterEvaluation : configJSON.TrainerMPowerYouMatter.TrainerMpowerEvaluation, { mpowerYouMatterId: props.mpowerYouMatterId, studentId: studentId })
        } else {
            props.goTo(configJSON.TrainerMPowerYouMatter.TrainerMpowerUpdateEvaluation, { mpowerYouMatterId: props.mpowerYouMatterId, studentId: studentId })
        }
    }

    const iconDisable = (type: string, attributes: any) => {
        if (type === configJSON.TrainerMPowerYouMatter.Sheet) {
            return attributes.status === configJSON.TrainerMPowerYouMatter.Published || attributes.status === configJSON.TrainerMPowerYouMatter.EvaluatedInLower || attributes.status === null;
        } else {
            return attributes.status === configJSON.TrainerMPowerYouMatter.Published || attributes.status === configJSON.TrainerMPowerYouMatter.Submitted || attributes.status === null || props.isYouMatter;
        }
    }

    const handleRowClick = (id: any, school: string, grade: string) => {
        const gradeData = grade.split('-');
        const gradeName = gradeData[0].trim();
        const sectionName = gradeData[1].trim();
        props.goTo(props.isYouMatter ? configJSON.TrainerMPowerYouMatter.TrainerYouMatterCheckDashoard : configJSON.TrainerMPowerYouMatter.TrainerMpowerCheckDashoard, { mpowerYouMatterId: id, schoolName: school, gradeName: gradeName, sectionName: sectionName })
    }

    const showScores = (attributes: any) => {
        return (attributes.status !== null && attributes.status !== configJSON.TrainerMPowerYouMatter.Published) ? `${Number(attributes.score)} / ${Number(attributes.total_score)}` : '-'
    }

    const getStatusClasses = (attributes: any) => {
        const elsePendingCell = props.isChecksDashboard ? classes.pendingStatusCell : classes.completeStatusCell;
        const elseCompletedAndEvaluated = attributes.status === configJSON.TrainerMPowerYouMatter.Submitted ? classes.completeStatusCell : classes.evaluatedStatusCell;
        return attributes.status === configJSON.TrainerMPowerYouMatter.Pending ? elsePendingCell : elseCompletedAndEvaluated;
    }

    const ifNullShowDash = (item: any) => {
        return item || '-';
    }

    const renderCellData = (ifCondition: any, elseCondition: any) => {
        return props.isChecksDashboard ? ifCondition : elseCondition;
    }

    const isYouMatterAndNotCheckDashboard = props.isYouMatter && !isYouMatterCheckDashboard
    return <>
        <Box className={classes.pageWrapper} data-test-id='box' >
            {!props.mpowerYouMatterId && <Grid container className={classes.dropDownWrapper} spacing={2}>
                <Grid item lg={4} xl={4} md={4} sm={4} xs={12}>
                    <CommonSelect
                        data-test-id='course'
                        handleChange={props.handleFilterChange}
                        onClear={() => props.clearFilter(configJSON.TrainerMPowerYouMatter.CourseFilter, configJSON.TrainerMPowerYouMatter.Filter)}
                        name={configJSON.TrainerMPowerYouMatter.CourseFilter}
                        placeholder={configJSON.TrainerMPowerYouMatter.SelectCourse}
                        value={props.states.courseFilter}
                        menuList={courseFilterList} />
                </Grid>
                <Grid item lg={4} xl={4} md={4} sm={4} xs={12}>
                    <CommonSelect
                        data-test-id='school'
                        handleChange={props.handleFilterChange}
                        onClear={() => props.clearFilter(configJSON.TrainerMPowerYouMatter.SchoolFilter, configJSON.TrainerMPowerYouMatter.Filter)}
                        name={configJSON.TrainerMPowerYouMatter.SchoolFilter}
                        placeholder={configJSON.TrainerMPowerYouMatter.SelectSchool}
                        value={props.states.schoolFilter}
                        menuList={schoolFilterList} />
                </Grid>
                <Grid item lg={4} xl={4} md={4} sm={4} xs={12}>
                    <CommonSelect
                        data-test-id='section'
                        handleChange={props.handleFilterChange}
                        onClear={() => props.clearFilter(configJSON.TrainerMPowerYouMatter.SectionFilter, configJSON.TrainerMPowerYouMatter.Filter)}
                        name={configJSON.TrainerMPowerYouMatter.SectionFilter}
                        placeholder={configJSON.TrainerMPowerYouMatter.SelectGrade}
                        value={props.states.sectionFilter}
                        menuList={sectionFilterList} />
                </Grid>
            </Grid>}
            <Box className={commonClasses.tableWrapper} >
                <Grid container className={commonClasses.tableHead}>
                    {tableHeader.map((element: any) => (<Grid key={element} item xs={true} className={commonClasses.cell} >{element}</Grid>))}
                </Grid>
                <Grid>
                    {props.data?.length > 0 ? props.data?.map((row: any) => {
                        const attributes = row.attributes;
                        return <Grid key={row.id} data-test-id="row" container className={commonClasses.tableRow} onClick={() => !props.isChecksDashboard ? handleRowClick(Number(row.id), attributes.school, attributes.grade) : {}} >
                            <Grid item xs={true} className={commonClasses.cell}>{renderCellData(attributes.student_name, attributes.name)}</Grid>
                            <Grid item xs={true} className={commonClasses.cell}>{renderCellData(attributes.mpower_check_title, attributes.course_name)}</Grid>
                            <Grid item xs={true} className={commonClasses.cell}>{renderCellData(ifNullShowDash(attributes.submitted_date), attributes.school)}</Grid>
                            <Grid item xs={true} className={commonClasses.cell}>{renderCellData(showScores(attributes), attributes.grade)}</Grid>
                            {(isYouMatterAndNotCheckDashboard) && <>
                                <Grid item xs={true} className={commonClasses.cell}>{attributes.start_date}</Grid>
                                <Grid item xs={true} className={commonClasses.cell}>{attributes.due_date}</Grid>
                            </>}
                            <Grid item xs={true} className={getStatusClasses(attributes)}> <span style={{ textTransform: 'capitalize' }}>{attributes.status}</span></Grid>
                            {isMpowerChecksDashboard && <Grid item xs={true} className={commonClasses.cell}> {attributes?.badge?.data?.attributes?.image ? <img style={webStyles.badgeImg} src={attributes?.badge?.data?.attributes?.image} alt="" /> : '-'}</Grid>}
                            {props.isChecksDashboard && <Grid item xs={true} className={commonClasses.cell}>
                                <IconButton className={classes.iconButton} disabled={iconDisable(configJSON.TrainerMPowerYouMatter.Sheet, attributes)} data-test-id='sheet' onClick={() => handleOnClickActionButtons(configJSON.TrainerMPowerYouMatter.Sheet, Number(row?.id))} >{renderActionBtns(attributes, configJSON.TrainerMPowerYouMatter.Sheet)}</IconButton>
                                {!props.isYouMatter && <IconButton className={classes.iconButtonRight} disabled={iconDisable(configJSON.TrainerMPowerYouMatter.Pen, attributes)} data-test-id='pen' onClick={() => handleOnClickActionButtons(configJSON.TrainerMPowerYouMatter.Pen, Number(row?.id))}>{renderActionBtns(attributes, configJSON.TrainerMPowerYouMatter.Pen)} </IconButton>}
                            </Grid>}
                        </Grid>
                    }) : configJSON.TrainerMPowerYouMatter.NoRecords}
                </Grid>
            </Box >
            <CommonPagination data-test-id='pagination' page={props.states.page} handlePage={props.handlePage} pageCount={props.pageCount} />
        </Box >
    </>
}
// Customizable Area End
export default class MpowerYouMatterDashboard extends TrainerMpowerYouMatterController {
    // Customizable Area Start
    async componentDidMount() {
        const path = window.location.pathname;
        const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath);
        const [mpowerYouMatterId, schoolName, gradeName, sectionName] = this.getUrlParams();
        const schoolInfo = { schoolName, gradeName, sectionName };
        this.getMpowerDashboard(this.state.courseFilter, this.state.schoolFilter, this.state.sectionFilter, this.state.page, isYouMatter, mpowerYouMatterId, schoolInfo);
        this.getFilterDropdownData('', this.state.courseFilter, this.state.schoolFilter)
    }

    getUrlParams = () => {
        const mpowerYouMatterId = this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.MPowerYouMatterId)
        const schoolName = this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.ApiSchoolName);
        const gradeName = this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.ApiGradeName);
        const sectionName = this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.ApiSectionName)
        return [mpowerYouMatterId, schoolName, gradeName, sectionName];
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<S>): void {
        const path = window.location.pathname;
        const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath);
        const [mpowerYouMatterId, schoolName, gradeName, sectionName] = this.getUrlParams();
        const schoolInfo = { schoolName, gradeName, sectionName };
        const isCourseOrSchoolFilterChanged = prevState.courseFilter !== this.state.courseFilter || prevState.schoolFilter !== this.state.schoolFilter

        if (isCourseOrSchoolFilterChanged || prevState.sectionFilter !== this.state.sectionFilter || prevState.page !== this.state.page) {
            this.getMpowerDashboard(this.state.courseFilter, this.state.schoolFilter, this.state.sectionFilter, this.state.page, isYouMatter, mpowerYouMatterId, schoolInfo);
        }
        if (isCourseOrSchoolFilterChanged) {
            this.getFilterDropdownData(prevState.courseFilter, this.state.courseFilter, this.state.schoolFilter)
        }
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const path = window.location.pathname;
        const isYouMatter = path.includes(configJSON.TrainerMPowerYouMatter.YouMatterPath)
        const isChecksDashboard = path.includes('checks-dashboard')
        const pageCount = this.state.totalPages;
        const mpowerYouMatterId = this.props.navigation.getParam(configJSON.TrainerMPowerYouMatter.MPowerYouMatterId);
        return <>
            <SidebarLayout {...this.props} >
                <PageContentLayout
                    goBack={isChecksDashboard ? () => this.goBack() : null}
                    addMpowerYouMatter={isChecksDashboard ? false : () => this.goTo(isYouMatter ? configJSON.TrainerMPowerYouMatter.CreateYouMatter : configJSON.TrainerMPowerYouMatter.TrainerMpowerGuidelines)}
                    title={isYouMatter ? configJSON.TrainerMPowerYouMatter.StudentsYouMatter : configJSON.TrainerMPowerYouMatter.StudentsMPower}
                >
                    {this.state.isLoading ? <Loader loading={this.state.isLoading} /> : null}
                    <MpowerYouMatterDashboardPage
                        data-test-id='dashboard-page'
                        handleFilterChange={this.handleDropdownChange}
                        data={this.state.dashboardData}
                        states={this.state}
                        isYouMatter={isYouMatter}
                        isChecksDashboard={isChecksDashboard}
                        goTo={this.goTo}
                        handlePage={this.handlePage}
                        pageCount={pageCount}
                        mpowerYouMatterId={mpowerYouMatterId}
                        clearFilter={this.handleClearSelect}
                    />
                </PageContentLayout>
            </SidebarLayout>
        </>
        // Customizable Area End
    }
}

const webStyles = {
    badgeImg: { width: '40px', height: '40px' }
}