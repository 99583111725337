import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Grid
} from "@material-ui/core";
import PageContentLayout from "../PageContentLayout.web";
import SidebarLayout from "../SidebarLayout.web";
import Loader from "../../../../components/src/Loader.web";
import SchoolFeedbackController from "./SchoolFeedbackController"
import { CourseFeedback } from "../FeedBack/Feedback.web";
import CommonTextField from "../CommonComponents/CommonTextField.web";
import CommonSelect from "../CommonComponents/CommonSelect.web";
import InfoModal from "../CommonComponents/InformationModal.web";
// Customizable Area End
const configJSON = require("../config.js");

// Customizable Area Start
// Customizable Area End
export default class SchoolFeedBack extends SchoolFeedbackController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { usersData, validationError } = this.state;
    const formattedCourseList = this.state.selectCourse?.map((element: any) => {
      return {
        id: element.attributes.id,
        title: element.attributes.title,
        value: element.attributes.id
      }
    })
    return (
      <SidebarLayout {...this.props}>
        <PageContentLayout title={configJSON.SchoolFeedback.ReviewAndFeedback}>
          {this.state.isLoading ? (
            <Loader loading={this.state.isLoading} />
          ) : null}

          <Box style={webStyle.mindMasteryPageContent}>
            <Box>
              <Typography style={webStyle.cardTitle}>
                School Feedback
              </Typography>
              <Typography variant="body2" style={webStyle.description}>
                {configJSON.SchoolFeedback.PlsProvideFeedback}
              </Typography>
            </Box>
            <Box mt={4}>
              <form onSubmit={this.handleSubmit}>
                <Grid container spacing={2} style={{ marginBottom: '20px' }} >
                  <Grid item xs={12}>
                    <CommonTextField
                      labelFontSize={'18px'}
                      mb={'0px'}
                      placeholder={configJSON.SchoolFeedback.EnterYourName}
                      name={configJSON.SchoolFeedback.SchoolName}
                      label={`${configJSON.SchoolFeedback.SchoolNameLabel}*`}
                      value={usersData?.school_name}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CommonSelect
                      data-test-id='course'
                      mb={'24px'}
                      labelFontSize={'18px'}
                      placeholder={configJSON.SchoolFeedback.SelectCourse}
                      label={`${configJSON.SchoolFeedback.CourseName}*`}
                      error={!!validationError}
                      onClear={this.handleClearDropDown}
                      value={this.state.selectedOption}
                      menuList={formattedCourseList}
                      handleChange={this.handleChangeCourse}
                    />
                    {validationError && (
                      <Typography variant="body2" style={{ color: "red", fontSize: "12px", marginTop: '10px' }}>
                        Please select course
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <CommonTextField
                      labelFontSize={'18px'}
                      mb={'0px'}
                      placeholder={configJSON.SchoolFeedback.EmailPlaceholder}
                      name={configJSON.SchoolFeedback.SchoolName}
                      label={`${configJSON.SchoolFeedback.Email}*`}
                      value={usersData?.email}
                    />
                  </Grid>
                </Grid>
                <CourseFeedback
                  courseId={this.state.courseId}
                  isLoading={this.state.isLoading}
                  course_feedback={this.state.course_feedback}
                  handleAnswerChange={this.handleAnswerChange}
                />

                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"end"}
                  mt={4}
                  style={{ gap: "50px" }}
                >
                  <Button style={webStyle.cancel} onClick={this.handleCancel}>{configJSON.ButtonTexts.Cancel}</Button>
                  <Button type="submit" data-test-id="submitbtn" style={webStyle.submit}>{configJSON.ButtonTexts.Submit}</Button>
                </Box>
              </form>
            </Box>
            <InfoModal title={this.state.modalType === configJSON.ModalTypes.Success ? 'Thank You!!' : 'Alert'} open={this.state.openModal} closeModal={this.handleClose} modalType={this.state.modalType} info={this.state.message} />
          </Box>
        </PageContentLayout>
      </SidebarLayout>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
const webStyle = {
  // Customizable Area Start
  cancel: {
    width: "150px",
    border: "1px solid #0056A2",
    color: '#0056A2',
    textTransform: 'capitalize',
    padding: "10px",
    borderRadius: '8px'
   } as React.CSSProperties,
  diaTitle: {
    color: "#0056A3",
    textAlign: "center",
    fontWeight: 600,
    fontSize: "24px",
  } as React.CSSProperties,
  diaContent: {
    textAlign: "center",
  } as React.CSSProperties,

  required: {
    color: "red",
    marginTop: "10px",
  } as React.CSSProperties,

  label: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    color: "#000",
    lineHeight: "35px",
  },

  submit: {
    width: "150px",
    background: "#0056A3",
    color: "#fff",
    padding: "10px",
    borderRadius: '8px',
    textTransform: "capitalize",
  } as React.CSSProperties,

  mindMasteryPageContent: {
    padding: "32px",
  },

  cardTitle: {
    marginBottom: "10px",
    color: "#0056A3",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "30px",
  },
  description: {
    fontSize: "16px",
    color: "grey",
  },
  emojiIcon: {
    padding: "0px",
  },
  emojiRating: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  } as React.CSSProperties,
  startRating: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
  } as React.CSSProperties,
  // Customizable Area End
};
// Customizable Area End
