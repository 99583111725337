// Customizable Area Start
import Side from "../Side";
import Point from "../Point";
import Puzzle from "../Puzzle";

class Piece {
  ts: Side;
  rs: Side;
  bs: Side;
  ls: Side;
  kx: any;
  ky: any;
  theDiv: any;
  theCanv: any;
  ctx: any;

  constructor(kx: any, ky: any) {
    this.ts = new Side(); // top side
    this.rs = new Side(); // right side
    this.bs = new Side(); // bottom side
    this.ls = new Side(); // left side
    this.kx = kx;
    this.ky = ky;

    // Create a canvas context when the Piece is constructed
    this.theCanv = document.createElement("canvas");
    this.ctx = this.theCanv.getContext("2d");
  }

  /* 
    draw path for one piece
    shiftx and shifty used for shadow effect
    does from beginPath to closePath, but not actually draw : no stroke nor fill nor clip here
  */
  drawPath(
    ctx: CanvasRenderingContext2D,
    shiftx: number,
    shifty: number,
    withoutBeginPath: boolean
  ) {
    if (withoutBeginPath !== true) ctx.beginPath();

    this.ts.drawPath(ctx, shiftx, shifty, false, false); // top side
    this.rs.drawPath(ctx, shiftx, shifty, false, true); // right side
    this.bs.drawPath(ctx, shiftx, shifty, true, true); // bottom side
    this.ls.drawPath(ctx, shiftx, shifty, true, true); // left
  }

  /*
    creates and returns a div for an individual piece, with a canvas with shadow, and a part of the picture
    canvas and div are three times the average size of pieces
    puzzle object given in parameters provides required elements
    scale and x and y offsets allow to scale and truncate picture to actual size
  */
  createDivPiece(
    puzzle: { dx: number; dy: number; image: any },
    scale: number,
    offsx: number,
    offsy: number
  ) {
    let ctx, shiftx, shifty;
    this.theDiv = document.createElement("div");
    this.theDiv.style.width = 3 * puzzle.dx + "px";
    this.theDiv.style.height = 3 * puzzle.dy + "px";
    this.theDiv.style.position = "absolute";

    this.theCanv = document.createElement("canvas");
    this.theCanv.width = 3 * puzzle.dx;
    this.theCanv.height = 3 * puzzle.dy;

    // origine shifting for path drawing
    shiftx = puzzle.dx * (this.kx - 1);
    shifty = puzzle.dy * (this.ky - 1);

    ctx = this.ctx = this.theCanv.getContext("2d");
    this.drawPath(ctx, shiftx, shifty, false);

    ctx.clip();

    let sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight;
    if (this.kx === 0) {
      sx = (0 * puzzle.dx) / scale + offsx; // won't work on safari if offsx < 0
      dWidth = 2 * puzzle.dx;
      dx = puzzle.dx;
    } else {
      sx = ((this.kx - 1) * puzzle.dx) / scale + offsx;
      dWidth = 3 * puzzle.dx;
      dx = 0;
    }
    sWidth = dWidth / scale;
    if (this.ky === 0) {
      sy = (0 * puzzle.dy) / scale + offsy; // won't work on safari if offsy < 0
      dHeight = 2 * puzzle.dy;
      dy = puzzle.dy;
    } else {
      sy = ((this.ky - 1) * puzzle.dy) / scale + offsy;
      dHeight = 3 * puzzle.dy;
      dy = 0;
    }
    sHeight = dHeight / scale;

    ctx.drawImage(
      puzzle.image,
      sx,
      sy,
      sWidth,
      sHeight,
      dx,
      dy,
      dWidth,
      dHeight
    );
    // shadow effect
    // depends on size of pieces

    let shift = 1,
      thickness = 2;
    if (puzzle.dx > 100 && puzzle.dy > 100) {
      shift = 2;
      thickness = 5;
    } else if (puzzle.dx > 50 && puzzle.dy > 50) {
      shift = 1.5;
      thickness = 3;
    }

    this.drawPath(ctx, shiftx + shift, shifty + shift, false);
    ctx.lineWidth = thickness;
    ctx.strokeStyle = "rgba(0, 0, 0, 0.3)";
    ctx.stroke();
    this.drawPath(ctx, shiftx - shift, shifty - shift, false);
    ctx.lineWidth = thickness;
    ctx.strokeStyle = "rgba(255, 255, 255, 0.3)";
    ctx.stroke();
    this.drawPath(ctx, shiftx, shifty, false); // path used for selection of the piece

    /* place of the piece at the beginning of the game */
    this.moveTo(
      new Point(
        (this.kx - 1) * puzzle.dx + Puzzle.MARGIN1,
        (this.ky - 1) * puzzle.dy + Puzzle.MARGIN1
      )
    );

    this.theDiv.appendChild(this.theCanv);

    return this.theDiv;
  }

  // returns position of current piece
  where() {
    const x = parseFloat(this.theDiv.style.left);
    const y = parseFloat(this.theDiv.style.top);
    return new Point(x, y);
  }

  /* 
    move piece to a given location
    onePoint if location of top lefthand corner of div (far beyond visible par of piece)
  */
  moveTo(onePoint: Point) {
    this.theDiv.style.top = onePoint.y + "px";
    this.theDiv.style.left = onePoint.x + "px";
  }

  // true if given (mouse) coordoninates are inside the piece
  insidePiece(x: number, y: number) {
    let styl = getComputedStyle(this.theDiv);
    let xloc = x - parseFloat(styl.left); // 'local' x and y
    let yloc = y - parseFloat(styl.top);

    return this.ctx.isPointInPath(xloc, yloc);
  }
}

export default Piece;
// Customizable Area End
