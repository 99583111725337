import React from 'react';
// Customizable Area Start
// import { Stepper, Step } from 'react-form-stepper';
// Customizable Area End
import SignupPageController, { Props } from './SignupController.web';

// Customizable Area Start
import { AppBar, Toolbar, IconButton, Box, Grid } from '@material-ui/core';

import {
  mainLogo,
  step1Active,
  step2Active,
  step2Inactive,
  step3Active,
  step3Inactive,
  step4Active,
  step4Inactive,
  chooseAccountBL,
  chooseAccountBR,
  left,
  right,
} from "./assets";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { withStyles } from "@material-ui/core/styles";
import StepConnector from "@material-ui/core/StepConnector";
import ChooseAccount from "./ChooseAccount.web";
import CreateAccount from "./CreateAccount.web";
import CreateAccountForSchool from "./CreateAccountForSchool.web";

import VerifyEmail from "./VerifyEmail.web";
import AccountCreationSuccess from "./AccountCreationSuccess.web";
import ProfileImageUpload from "./ProfileimageUpload.web";
import { TermsAndConditionsPopup } from "./TermsConditionsPopup.web";
import AadharImageUpload from "./AadharImageUpload.web";
// Customizable Area End
// Customizable Area Start
interface StepIconProps {
  completed: boolean;
  active: boolean;
  error: boolean;
  icon: number;
}

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 30,
    left: "calc(-50% + 30px)",
    right: "calc(50% + 30px)",
  },
  active: {
    "& $line": {
      borderColor: "#0056A3",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#0056A3",
    },
  },
  line: {
    borderColor: "#D4D4D4",
    borderTopWidth: 2,
  },
})(StepConnector) as any;

const StepIcon = (props: StepIconProps) => {
  switch (props.icon) {
    case 2:
      return props.active || props.completed ? (
        <img src={step2Active} alt="" />
      ) : (
        <img src={step2Inactive} alt="" />
      );

    case 3:
      return props.active || props.completed ? (
        <img src={step3Active} alt="" />
      ) : (
        <img src={step3Inactive} alt="" />
      );

    case 4:
      return props.active || props.completed ? (
        <img src={step4Active} alt="" />
      ) : (
        <img src={step4Inactive} alt="" />
      );

    default:
      return <img src={step1Active} alt="" />;
  }
};
// Customizable Area End
export default class Signup extends SignupPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderFormElements = () => {
    return (
      <Stepper
        style={webStyles.stepper}
        activeStep={this.state.step}
        connector={<QontoConnector />}
        alternativeLabel
      >
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Choose an Account</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Create an Account</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Verify Email ID</StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>Account Created</StepLabel>
        </Step>
      </Stepper>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderFormStep = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {this.state.step === 0 && (
            <ChooseAccount
              accountType={this.state.accountType}
              handleAccountType={this.handleSelectAccountType}
            />
          )}
          {}
          {this.state.step === 1 &&
            this.state.accountType !== "School" &&
            this.state.accountType !== "Trainer" &&
            this.state.accountType !== "Parents" && (
              <CreateAccount
                genderList={this.state.genderList}
                schools={this.state.schoolList}
                classList={this.state.classList}
                handleDOB={this.handleDOB}
                handleSubmit={this.handleSubmitCreateAccount}
                states={this.state}
                handleTnCPopup={this.togglePopup}
                handleChangeSelect={this.handleChangeSelectMenu}
                handleChange={this.handleFormFieldChange}
                handleOpenDropZone={this.handleOpenImageDropZone}
                handleClickShowPwd={this.handleClickShowPwd}
                handleClickShowConfirmPwd={this.handleClickShowConfirmPwd}
                handlePwdChange={this.handlePwdChange}
                disableFutureDates={this.disableFutureDates}
              />
            )}
          {this.state.step === 1 &&
            (this.state.accountType == "School" ||
              this.state.accountType == "Trainer" ||
              this.state.accountType == "Parents") && (
              <CreateAccountForSchool
                genderList={this.state.genderList}
                isSchool={this.state.accountType == "School" ? true : false}
                isParent={this.state.accountType == "Parents" ? true : false}
                schools={this.state.schoolList}
                classList={this.state.classList}
                handleDOB={this.handleDOB}
                handleSubmit={this.handleSubmitCreateAccountForSchool}
                states={this.state}
                handleTnCPopup={this.togglePopup}
                handleChangeSelect={this.handleChangeSelectMenu}
                handleChange={this.handleFormFieldChange}
                handleOpenDropZone={this.handleOpenImageDropZone}
                handleOpenAadharImageDropZone={
                  this.handleOpenAadharImageDropZone
                }
                handleClickShowPwd={this.handleClickShowPwd}
                handleNext={this.handleNext}
                handleClickShowConfirmPwd={this.handleClickShowConfirmPwd}
                setState={this.setState}
                handlePwdChange={this.handlePwdChange}
              />
            )}
          {this.state.step === 2 && (
            <VerifyEmail
              email={this.state.email}
              hasError={this.state.hasError}
              getOtpArray={this.getOtpArray}
              handleInputOtp={this.handleInputOtp}
              resendOTP={this.handleResendOTPHandler}
              handleSubmit={this.verifyOTPSubmitHandler}
              emailOtp={this.state.emailOtp}
              otpResentMessage={this.state.otpResent}
              otpFieldRef={this.otpFieldRef}
              clearOTP={this.handleClearOTP}
            />
          )}
          {this.state.step === 3 && (
            <AccountCreationSuccess handleSubmit={this.handleRedirectToLogin} />
          )}
        </Grid>
      </Grid>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <div style={webStyles.signupWrapper}>
          <AppBar
            position="static"
            style={webStyles.appBarContainer}
            color="transparent"
          >
            <Toolbar>
              <Box style={{width: "18%", height:"96px",display:'flex',alignItems:'center'}}>
                <img src={mainLogo} alt="logo" />
              </Box>
            </Toolbar>
          </AppBar>
          {this.renderFormElements()}
          <div style={webStyles.stepContentWrapper}>
            {this.renderFormStep()}
          </div>
          <img
            src={chooseAccountBL}
            style={webStyles.chooseAccountBgimgLeft}
            alt=""
          />
          <img
            src={chooseAccountBR}
            style={webStyles.chooseAccountBgimgRight}
            alt=""
          />
          <TermsAndConditionsPopup
            open={this.state.showPopup}
            handleCancel={this.togglePopup}
            showPrivacyPolicy={this.state.showPrivacyPolicy}
          />
          <ProfileImageUpload
            states={this.state}
            handleDone={this.handleImageUploadDone}
            handleCancel={this.handleCancelUpload}
            handleOnDrop={this.handleUploadProfilePhoto}
            handleCloseDropZone={this.handleCloseImageDropZone}
          />
          <AadharImageUpload
            states={this.state}
            handleDone={this.handleAadharImageUploadDone}
            handleCancel={this.handleCancelAdharUpload}
            handleOnDrop={this.handleUploadAadharPhoto}
            handleCloseDropZone={this.handleCloseAadharImageDropZone}
          />
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  signupWrapper: { position: "relative" as "relative" },
  appBarContainer: {
    padding: "0 80px 0 0",
    backgroundPosition: "top left, top right",
    backgroundColor: "#F0F4F6 !important",
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.0760489)',
    backgroundImage: `url(${left}),url(${right})`,
    backgroundRepeat: 'no-repeat',
  },
  chooseAccountBgimgLeft: {
    position: "absolute" as "absolute",
    width: "400px",
    bottom: "0",
    left: "0",
    zIndex: -1,
  },
  chooseAccountBgimgRight: {
    position: "absolute" as "absolute",
    width: "400px",
    bottom: "0",
    right: "0",
    zIndex: -1,
  },
  stepper: { background: "transparent" },
  stepContentWrapper: { marginBottom: "136px" },
};
// Customizable Area End