import React from 'react'
// Customizable Area Start
import MaterialController, { S } from "./MaterialController.web";
import SidebarLayout from '../../SidebarLayout.web';
import PageContentLayout from '../../PageContentLayout.web';
import MaterialList, { ListItemProps } from './MaterialList.web';
import { Box, Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { blueDownArrow } from '../../assets';
import CommonPagination from '../../../../../components/src/Pagination.web';
// Customizable Area End
const configJSON = require("../../config.js");

const useStyles = makeStyles({
    // Customizable Area Start
    accordion: {
        '&.MuiAccordion-root': {
            boxShadow: 'none'
        },
        '&.MuiAccordion-root:before': {
            display: 'none'
        }
    },
    accordionSummary: {
        '& .MuiAccordionSummary-content': {
            padding: '0',
            minHeight: 'auto',
            display: 'flex',
            alignItems: 'center',
            margin: '7px 0',
            background: '#FFF',
        },
        '&.MuiAccordionSummary-root': {
            borderRadius: '8px',
            border: '0.2px solid #e3e3e3',
            padding: '0px 20px',
            boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)'
        }
    },
    accordionDetails: {
        '&.MuiAccordionDetails-root': {
            display: 'block',
            padding: '10px 0 0 0'
        }
    },
    pageWrapper: {
        padding: "32px 24px 24px 24px",
    },
    // Customizable Area End
})

// Customizable Area Start
export const ExpandIcon = () => {
    return <img data-test-id='img' src={blueDownArrow} alt="" />
}

interface SessionMaterialPageProps {
    states: S,
    pageCount: number;
    handleChangeAccordion: (panel: string) => ((event: React.ChangeEvent<{}>, expanded: boolean) => void) | undefined;
    handlePage: (page: number) => void;
    isParentsMaterial: boolean;
}

interface SessionWiseMaterial {
    attributes: {
        name: string;
        parenting_materials: ListItemProps[];
        training_materials: ListItemProps[];
    };
}


export const SessionMaterialPage = (props: SessionMaterialPageProps) => {
    const classes = useStyles();
    const { handleChangeAccordion, pageCount, states, handlePage, isParentsMaterial } = props;
    let isMaterialPage = window.location.pathname !== "/parent/guide";
    return <>
        <Box className={classes.pageWrapper} data-test-id='box'>
            <Grid container spacing={2} style={{ marginBottom: '40px' }}>
                {states?.sessionWiseMaterialPageData?.data?.map((element: SessionWiseMaterial, index: number) => {
                    const Attributes = element.attributes;
                    const attachments = isParentsMaterial ? Attributes.parenting_materials : Attributes.training_materials
                    return <>
                        <Grid xs={12} item>
                            <Accordion data-test-id='accordion' className={classes.accordion} expanded={props.states.accordionState === `${index + 1}`} onChange={handleChangeAccordion(`${index + 1}`)}>
                                <AccordionSummary className={`${classes.accordionSummary}`} expandIcon={<ExpandIcon />} >
                                    {Attributes.name}
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    {attachments?.length > 0 ?
                                        attachments.map((element: any, id: any) => {
                                            return <MaterialList key={element} data={element} isMaterialPage={isMaterialPage} />;
                                        }) : <Typography style={{ paddingLeft: '20px' }}> No Attachment</Typography>
                                    }
                                </AccordionDetails>
                            </Accordion >                                           
                        </Grid>
                    </>
                })}
            </Grid>
            <CommonPagination
                data-test-id='pagination'
                page={states.page}
                handlePage={handlePage}
                pageCount={pageCount}
            />   
            </Box>
    </>
}
// Customizable Area End

export default class SessionMaterial extends MaterialController {
    // Customizable Area Start
    async componentDidMount() {
        const courseId = Number(this.props.navigation.getParam('courseId'))
        const isParentsMaterial = window.location.pathname.includes('parent');
        this.getSessionWiseMaterial(courseId, isParentsMaterial);
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<S>): void {
        if (prevState.page !== this.state.page) {
            const courseId = Number(this.props.navigation.getParam('courseId'));
            const isParentsMaterial = window.location.pathname.includes('parent');
            this.getSessionWiseMaterial(courseId, isParentsMaterial);
        }
    }

    render() {
        const isParentsMaterial = window.location.pathname.includes('parent');
        const pageCount = this.state.sessionWiseMaterialPageData?.page_options?.total_pages;
        return <>
            <SidebarLayout {...this.props} >
                <PageContentLayout data-test-id='page-layout' title={isParentsMaterial ? configJSON.Material.ParentMaterial : configJSON.Material.TrainingMaterial} goBack={() => this.goTo(isParentsMaterial ? 'ParentMaterial' : 'TrainerMaterial')} >
                    <SessionMaterialPage
                        data-test-id='main-page'
                        handleChangeAccordion={this.handleChangeAccordion}
                        pageCount={pageCount}
                        states={this.state}
                        handlePage={this.handlePage}
                        isParentsMaterial={isParentsMaterial}
                    />
                </PageContentLayout>
            </SidebarLayout>
        </>
    }
    // Customizable Area End
}
