import React from "react";
// Customizable Area Start
import MPowerController, { Props, S } from "./MPowerController";
import MpowerGuidelines from "./MpowerGuidelines.web";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Typography } from "@material-ui/core";
import SidebarLayout from "../SidebarLayout.web";
import { playMpower } from "../assets";
import PageContentLayout from "../PageContentLayout.web";
import CommonPagination from "../../../../components/src/Pagination.web";
// Customizable Area End
const configJSON = require("../config.js");

// Customizable Area Start
// Customizable Area End


export default class MPower extends MPowerController {
  // Customizable Area Start

  async componentDidMount() {
    this.getMpowerPageData();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevState.page !== this.state.page) {
      this.getMpowerPageData()
    }
  }

  getSuperPowerData = (super_power: any) => {
    if (super_power) {
      return (
        <>
          <img style={{ width: '35px' }} src={super_power.attributes.image} alt="super power" />
        </>
      );
    } else {
      return "-";
    }
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      tableHeader,
      tableHeadCell,
      boxPadding
    } = tableStyle;

    const TableHeading = configJSON.MPower.TableHeader;

    const guidelinesData = {
      guidelines: this.state.guidelines,
      sections: this.state.sections,
      handleClickSessionStart: this.handleClickSessionStart,
    };
    return (
      <SidebarLayout {...this.props}>
        <PageContentLayout title={`MPower Check ${this.state.headMessage && ' - ' + this.state.headMessage}`} subtitle={"Skill & Competency Check"} goBack={this.state.mpowerSection === 2 ? this.handlerBackEvent : null} handleFormDropDown={this.navigateToForms} data-test-id='page-layout' >
          {this.state.mpowerSection === 1 && (
            <Box style={boxPadding}>
              <TableContainer>
                <Table aria-label="customized table" style={tableStyle.table}>
                  <TableHead>
                    <TableRow style={tableHeader}>
                      {TableHeading.map((val: string) => {
                        return (
                          <TableCell style={tableHeadCell} key={val}>
                            {val}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.check_list.map((listData) => (
                      <TableRow key={listData.id} style={tableStyle.tableRow}>
                        <TableCell align="left">{listData.attributes.session_topic}</TableCell>
                        <TableCell align="left">{listData.attributes.end_date}</TableCell>
                        <TableCell align="left" style={{ textTransform: 'capitalize' }} >{listData.attributes.status}</TableCell>
                        <TableCell align="left"><Typography>{this.getSuperPowerData(listData.attributes.super_power)}</Typography>
                        </TableCell><TableCell align="center">
                          <img
                            style={{ cursor: "pointer" }}
                            data-test-status={
                              listData.attributes.status
                            }
                            data-test-id="showGuidelines"
                            onClick={() =>
                              this.showGuidelines(
                                listData.id,
                                listData.attributes.status,
                                listData.attributes.session_topic
                              )
                            }
                            src={
                              listData.attributes.status === configJSON.MPower.Published
                                ? playMpower
                                : null
                            }
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <CommonPagination handlePage={this.handlePage} page={this.state.page} pageCount={this.state.pageCount} />
            </Box>
          )}
          {this.state.mpowerSection === 2 && (
            <MpowerGuidelines {...guidelinesData} />
          )}
        </PageContentLayout>
      </SidebarLayout>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const tableStyle = {
  table: {
    borderSpacing: "0 10px",
    borderCollapse: "initial",
    borderRadius: "20px",
    padding: "1px",
    marginBottom: '30px'
  } as React.CSSProperties,
  tableRow: {
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    background: "#fff",
    borderRadius: "10px",
    outline: "1px solid #ddd",
  } as React.CSSProperties,
  headerBox: {
    background:
      "linear-gradient(70deg, rgba(1,86,162,1) -36.53%, rgba(75,144,205,1) 66%, rgba(179,219,255,1) 146%)",
    borderRadius: "12px 12px 0px 0px",
    padding: "16px 24px",
    display: "flex",
    alignItems: "center",
  },
  mainBox: {
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: "12px",
    maxWidth: "1108px",
  },
  boxPadding: {
    padding: "24px",
  },
  mainTable: {
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
  },
  tableHeader: {
    backgroundColor: "0056A3",
    color: "#fff",
    borderRadius: "10px",
    "&:lastChild": {
      borderRadius: " 0 10px 10px 0",
    },
  },
  tableHeadCell: {
    color: "#fff",
    "&:lastChild": {
      borderRadius: " 0 10px 10px 0",
    },
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
};
// Customizable Area End
