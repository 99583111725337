import React from 'react'
// Customizable Area Start
import SidebarLayout from "../../SidebarLayout.web";
import { Box, Grid, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CommonPagination from '../../../../../components/src/Pagination.web';
import SchoolTimelyReportController, { configJSON } from './SchoolTimelyReportController.web';
import { sheetBlue } from "../../assets"
import "../../Trainer/StudentsReport/EditStudentReport.css";
import { useStyles } from "../../Trainer/StudentsReport/StudentsReportList.web";
import PageContentLayout from '../../PageContentLayout.web';
import commonStyles from '../../commonStyles.web';
import CommonSelect from '../../CommonComponents/CommonSelect.web';

const useStylesNew = makeStyles({
    tableHead: {
        '&.MuiGrid-container': {
            background: '#0056A3',
            borderRadius: '8px',
            color: 'white',
            fontFamily: 'Poppins',
            fontWeight: 700,
            fontSize: '1.25vw',
            lineHeight: '1.875vw',
            justifyContent: 'flex-start',
            padding: '1.389vw 1.667vw',
            marginBottom: '16px'
        }
    },
    headerCellStudentReport: {
        '&.MuiGrid-item': {
            padding: '0 0.694vw',
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkitLineClamp': 2,
            '-webkitBoxOrient': 'vertical',
            textOverflow: 'ellipsis',
            wordBreak: 'break-word',
            fontSize: "1.319vw"
        },
        '&.MuiGrid-grid-xs-true': {
            flexBasis: '33%',
            flexGrow: "0"
        }
    },
    tableHeaderExtraPadding: {
        '&:nth-child(2)': {

            padding: "0 0 0 10vw"
        }
    }
})

export const SchoolTimelyReportPage = (props: any) => {
    const classes = useStyles();
    const newClasses = useStylesNew();
    const commonClasses = commonStyles({});
    const nuberedPagesSchTpr = props.dataSchTpr?.count / configJSON.pageElementCount;
    let tableHeaderSchTpr = configJSON.TimelyProgress.TableHeaderSchTpr;

    return <>
        <Box className={classes.pageWrapper}>
            <Grid container className={classes.dropDownWrapper} style={{ marginBottom: '20px' }} spacing={2}>
                <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
                    <CommonSelect
                        data-test-id="commonSelectSchTpr"
                        handleChange={(eventSchTpr: any) => props.handleDropDownValueChange(eventSchTpr, configJSON.TimelyProgress.YearValueSchTpr)}
                        value={props.selectMenuValues.yearValueSchTpr}
                        onClear={() => props.clearFilter('selectYearSchTpr', configJSON.TimelyProgress.YearValueSchTpr)}
                        placeholder={'Select Academic Year'}
                        menuList={props.filterOptions.yearFilterOptionSchTpr}
                    />
                </Grid>
                <Grid item lg={6} xl={6} md={6} sm={6} xs={12}>
                    <CommonSelect
                        data-test-id="commonSelectSchTpr"
                        handleChange={(eventSchTpr: any) => props.handleDropDownValueChange(eventSchTpr, configJSON.TimelyProgress.CourseValueSchTpr)}
                        value={props.selectMenuValues.courseValueSchTpr}
                        onClear={() => props.clearFilter('selectCourseSchTpr', configJSON.TimelyProgress.CourseValueSchTpr)}
                        placeholder={'Select Course'}
                        menuList={props.filterOptions.courseFilterOptionSchTpr}
                    />
                </Grid>
            </Grid>
            <Box className={commonClasses.tableWrapper} >
                <Box>
                    <Grid container className={commonClasses.tableHead}>
                        {tableHeaderSchTpr.map((elementSchTpr: any) => (<Grid key={elementSchTpr} item xs={true} className={`${commonClasses.cell} ${newClasses.tableHeaderExtraPadding}`} >{elementSchTpr}</Grid>))}
                    </Grid>
                    <Grid>
                        {props.dataSchTpr?.reports?.map((rowSchTpr: any) => {
                            const attributesSchTpr = rowSchTpr.attributes;
                            return <Grid key={rowSchTpr.id} container className={commonClasses.tableRow} >
                                <Grid item xs={true} className={commonClasses.cell}>{attributesSchTpr.mind_mastery_course.name}</Grid>
                                <Grid style={{ padding: "0 0 0 10vw" }} item xs={true} className={commonClasses.cell}>{attributesSchTpr.academic_year}</Grid>
                                <Grid style={{ textAlign: "end" }} item xs={true} className={`${commonClasses.cell} ${classes.editIconsContainer} editIconsSr`} >
                                    <IconButton className={commonClasses.iconButton}>
                                        <a href={attributesSchTpr.pdf_url} target="_blank" rel="noopener noreferrer">
                                            <img
                                                data-test-id="pdfIconSchTpr"
                                                src={sheetBlue} />
                                        </a>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        })}
                    </Grid>
                </Box>
            </Box>
            <CommonPagination page={props.pageNoSchTpr} handlePage={props.handlePageChangeSchTpr} pageCount={nuberedPagesSchTpr < 1 || !nuberedPagesSchTpr ? 1 : Math.ceil(nuberedPagesSchTpr)} />
        </Box>
    </>
}
// Customizable Area End
export default class SchoolTimelyReport extends SchoolTimelyReportController {
    // Customizable Area Start
    render() {
        return <>
            <SidebarLayout {...this.props} >
                <PageContentLayout
                    title={configJSON.TimelyProgress.TimelyProgressReport}
                    goBack={this.goBack}>
                    <SchoolTimelyReportPage
                        clearFilter={this.clearFilter}
                        dataSchTpr={this.state.timelyProgressReportData}
                        goTo={this.goTo}
                        handleDropDownValueChange={this.handleDropDownValueChangeSchTpr}
                        selectMenuValues={this.state.dropDownValuesSchTpr}
                        filterOptions={this.state.filterOptionsSchTpr}
                        pageNoSchTpr={this.state.pageNoSchTpr}
                        handlePageChangeSchTpr={(pageSchTpr: any) => this.handlePageChangeSchTpr(pageSchTpr)}
                    />
                </PageContentLayout>
            </SidebarLayout>
        </>
    }
    // Customizable Area End
}
