import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { apiCall } from "../utils";
// Customizable Area Start
interface TeamAttributes {
  id: number;
  description: string;
  role: string;
  photo_url: string;
  full_name: string;
}

interface TeamData {
  id: string;
  type: string;
  attributes: TeamAttributes;
}

interface TeamCategory {
  data: TeamData[];
}

interface OurTeamsData {
  our_teams: TeamCategory;
  our_experts: TeamCategory;
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  teamsData: OurTeamsData;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AboutUsPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [];

    this.state = {
      teamsData: {
        our_teams: { data: [] },
        our_experts: { data: [] },
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getTeamsData();
  }

  async getTeamsData() {
    const header = {
      "Content-Type": configJSON.exampleApiContentType
    };
    const res = await apiCall(
      configJSON.validationApiMethodType,
      configJSON.getTeamsInfo,
      header
    );
    if (res) {
      this.setState({ teamsData: res });
      return true;
    } else {
      return false;
    }
  }

  // Customizable Area End
}
