import React from "react";
// Customizable Area Start
import {
    Typography,
    IconButton,
    Link,
    Box,
    FormControl,
    FormLabel,
    TextField,
    Theme,
    makeStyles
} from "@material-ui/core";

import PrimaryButton from "../../../components/src/PrimaryButtonCommonComponent.web";
import { mailVerify } from './assets'
import { OTP_LENGTH } from "./SignupController.web";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End
const configJSON = require("./config");
// Customizable Area Start
interface VerifyEmailProps {
    getOtpArray: () => [];
    hasError: boolean;
    otpResentMessage: string;
    handleInputOtp: (e: any, index: number) => void;
    otpFieldRef: any[]
    handleSubmit: () => void;
    resendOTP: () => void;
    email: string;
    emailOtp: string;
    clearOTP: () => void;
}

const useStyles = makeStyles<Theme, VerifyEmailProps>((theme: Theme) => ({
    verifyAccWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column" as "column",
        alignItems: "center"
    },
    error: { color: "red" },
    inputAdornment: {
        width: "48px",
        height: "48px",
        display: "flex",
        justifyContent: "center"
    },
    formHeaderLabel: {
        color: "#0056A3",
        fontSize: "40px",
        fontWeight: 900,
        [theme.breakpoints.down(600)]: {
            fontSize: '29px',
          },
        margin: "24px auto"
    },
    subTitleLabel: {
        color: "#0056A3",
        fontSize: "20px",
        fontWeight: 500,
        margin: "-12px auto"
    },
    boldLabel: {
        fontWeight: 700,
    },
    emailVerificationImage: {
        width: "200px",
        margin: "24px auto"
    },
    otpVerificationForm: {
        display: "flex !important",
        width: "100% !important",
        maxWidth: "430px !important",
        columnGap: "10px !important",
        [theme.breakpoints.down(600)]: {
            columnGap: "0px !important",
            marginLeft: "6px"
        }

    },
    otpVerificationFormGrid: {
        maxWidth: "min-content !important",
        margin: "0 4px !important",
        display: "flex",
        "& button": {
            margin: 'auto !important',
            padding: '8px !important',
            color: '#fff !important',
            background: (props:any) => {
                if (props.hasError) {
                    return '#EC242C !important';
                } else if (props.emailOtp.length === OTP_LENGTH) {
                    return '#20BF55 !important';
                } else {
                    return "rgb(224, 224, 224)";
                }
            },
        }
    },
    otpVerificationFormField: {
        height: '15px !important',
        width: '15px !important',
        [theme.breakpoints.up(600)]: {
            height: '36px !important',
            width: '32px !important',
        },
        border: (props: any) => props.emailOtp.length === OTP_LENGTH && !props.hasError ? '1px solid #066CD2 !important' : '1px solid #ccc',
        borderRadius: '5px',
        fontFamily: 'Poppins',
        textAlign: "center" as "center",
        fontSize: '24px',
        fontWeight: 600,
        lineHeight: '36px',
        color: '#3B3B3B',
        boxShadow: (props: any) => props.emailOtp.length === OTP_LENGTH && !props.hasError ? '0px 10px 16px rgba(165, 176, 194, 0.15) !important' : ''
    },
    message: {
        display: 'flex',
        flexDirection: 'row' as "row",
        flexBasis: 'max-content',
        width: '86%',
        justifyContent: (props: any) => props.hasError ? 'space-between !important' : 'flex-end'
    },
    resendOtpBox: {
        alignSelf: 'flex-end',
        '& a': {
            float: 'right',
            color: '#0051a1',
            fontSize: '12px',
        }
    },
    createStudentAccSubmit: {
        display: 'block',
        "& button": {
            width: "fit-content",
            textTransform: 'initial !important',
            float: 'right',
            marginRight: '87px',
            borderRadius: '12px',
            marginTop: '70px',
            padding: '13px 28px',
            left: '15%',
            [theme.breakpoints.down(600)]: {
                left: "30%"
            },
            transform: 'translate(-42%,0)',
            color: '#fff',
            backgroundColor: '#0056A3',
            "& :hover": {
                color: '#fff',
                backgroundColor: '#0056A3 !important',
            }
        },
        "& button:hover": {
            color: '#fff',
            backgroundColor: '#0056A3 !important',
        }
    }


}));
const VerifyEmail = (props: VerifyEmailProps) => {
    const webStyles = useStyles(props);
    return <Box className={webStyles.verifyAccWrapper}>
        <FormControl>
            <FormLabel className={webStyles.formHeaderLabel}>
                {configJSON.VerifyEmail.VerifyYourEmailID}
            </FormLabel>
            <Typography variant="h6" align="center" className={webStyles.subTitleLabel}>{configJSON.VerifyEmail.EnterOtpMsg}: <span className={webStyles.boldLabel}>{props.email}</span> <br /> {configJSON.VerifyEmail.ToVerifyMsg}</Typography>
            <img className={webStyles.emailVerificationImage} src={mailVerify} alt="Mail Verify" />
            <div className={webStyles.otpVerificationForm}>
                {props.getOtpArray()?.map((digit: any, idx: any) => {
                    return (
                        <div
                            className={webStyles.otpVerificationFormGrid}
                            key={idx}
                        >
                            <TextField
                                onChange={(e: any) => props.handleInputOtp(e, idx)}
                                margin="dense"
                                variant="outlined"
                                error={props.hasError}
                                value={digit}
                                inputRef={(ref) => props.otpFieldRef[idx] = ref}
                                inputProps={{
                                    className: webStyles.otpVerificationFormField
                                }}
                            />
                        </div>
                    )
                })}
                <div className={webStyles.otpVerificationFormGrid}>
                    <IconButton>
                        {props.hasError
                            ? <CloseIcon onClick={()=>props.clearOTP()} />
                            : <CheckIcon />}
                    </IconButton>
                </div>
            </div>
            <div className={webStyles.message}>
                {props.hasError && <div>
                    <Typography variant="caption" color="error">{props.emailOtp.length? configJSON.VerifyEmail.WrongOTPEntered:"Enter the OTP"}</Typography>
                </div>}
                <div className={webStyles.resendOtpBox}>
                    {
                        props.otpResentMessage ? <Typography variant='caption' color='textSecondary'>{props.otpResentMessage}</Typography>
                            : <Link color="inherit" onClick={props.resendOTP}>
                                {configJSON.VerifyEmail.OTPNotReceived} <b>{configJSON.VerifyEmail.Resend}</b>
                            </Link>
                    }
                </div>
            </div>
            <div className={webStyles.createStudentAccSubmit}>
                <PrimaryButton className="verify" onClick={props.handleSubmit} variant="contained" color="primary">
                    {configJSON.VerifyEmail.VerifyAccount}
                </PrimaryButton>
            </div>
        </FormControl>
    </Box>
}
// Customizable Area End
export default VerifyEmail;
