import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
// Customizable Area Start
import PropTypes from "prop-types";
import { CometChat } from "@cometchat-pro/chat";

import {
	CometChatAvatar,
	CometChatBadgeCount,
	CometChatUserPresence,
} from "../../Shared";
import { CometChatConversationListActions } from "../CometChatConversationListActions";

import * as enums from "../../../util/enums.js";
import {
	checkMessageForExtensionsData,
	getTimeStampForLastMessage,
} from "../../../util/common";
import { CometChatContext } from "../../../util/CometChatContext";

import { theme } from "../../../resources/theme";
import Translator from "../../../resources/localization/translator";

import {
	listItem,
	itemThumbnailStyle,
	itemDetailStyle,
	itemRowStyle,
	itemNameStyle,
	itemLastMsgStyle,
} from "./style";
// Customizable Area End
class CometChatConversationListItem extends React.PureComponent {
	// Customizable Area Start
	static contextType = CometChatContext;
	// Customizable Area End
	constructor(props) {
		super(props);
		// Customizable Area Start
		this.state = {
			lastMessage: "",
			lastMessageTimestamp: "",
			enableUnreadCount: false,
			isHovering: false,
			enableHideDeletedMessages: false,
		};
		// Customizable Area End
	}

	// Customizable Area Start

	componentDidMount() {
		const message = this.getLastMessage();
		const timestamp = this.getLastMessageTimestamp();
		this.enableUnreadCount();
		this.enableHideDeletedMessages();

		this.setState({ lastMessage: message, lastMessageTimestamp: timestamp });
	}

	componentDidUpdate(prevProps) {
		const previousItem = JSON.stringify(prevProps.conversation);
		const currentItem = JSON.stringify(this.props.conversation);
		// console.log("currentItem",currentItem)

		if (previousItem !== currentItem) {
			const message = this.getLastMessage();
			const timestamp = this.getLastMessageTimestamp();

			this.setState({ lastMessage: message, lastMessageTimestamp: timestamp });
		}

		this.enableUnreadCount();
		this.enableHideDeletedMessages();
	}

	getLastMessage = () => {
		if (
		  !this.props.hasOwnProperty("conversation") ||
		  !this.props.conversation.hasOwnProperty("lastMessage")
		) {
		  return ""; // Return empty string instead of false
		}
	  
		const lastMessage = this.props.conversation.lastMessage;
	  
		if (lastMessage.hasOwnProperty("deletedAt")) {
		  if (this.state.enableHideDeletedMessages) {
			return ""; // Return empty string instead of false
		  } else {
			return this.getMessageTextForDeletedMessage(lastMessage);
		  }
		}
	  
		switch (lastMessage.category) {
		  case CometChat.CATEGORY_MESSAGE:
			return this.getMessage(lastMessage);
		  case CometChat.CATEGORY_CALL:
			return this.getCallMessage(lastMessage);
		  case CometChat.CATEGORY_ACTION:
			return this.getActionMessage(lastMessage);
		  case CometChat.CATEGORY_CUSTOM:
			return this.getCustomMessage(lastMessage);
		  default:
			return ""; // Return empty string instead of false
		}
	  };
	  
	  getMessageTextForDeletedMessage = (lastMessage) => {
		if (this.props.loggedInUser.uid === lastMessage.sender.uid) {
		  return `${Translator.translate(
			"YOU_DELETED_THIS_MESSAGE",
			this.context.language
		  )}`;
		} else {
		  return `${Translator.translate(
			"THIS_MESSAGE_DELETED",
			this.context.language
		  )}`;
		}
	  };

	getLastMessageTimestamp = () => {
		if (this.props.hasOwnProperty("conversation") === false) {
			return false;
		}

		if (this.props.conversation.hasOwnProperty("lastMessage") === false) {
			return false;
		}

		if (
			this.props.conversation.lastMessage.hasOwnProperty("sentAt") === false &&
			this.props.conversation.lastMessage.hasOwnProperty("_composedAt") ===
				false
		) {
			return false;
		}

		let timestamp =
			this.props.conversation.lastMessage._composedAt ||
			this.props.conversation.lastMessage.sentAt;
		timestamp = getTimeStampForLastMessage(timestamp, this.context.language);

		return timestamp;
	};

	getCustomMessage = (lastMessage) => {
		let message = null;
		const sender =
			this.props.loggedInUser.uid !== lastMessage.sender.uid
				? `${lastMessage.sender.name}: `
				: ``;

		switch (lastMessage.type) {
			case enums.CUSTOM_TYPE_POLL:
				{
					const pollMessage = Translator.translate(
						"CUSTOM_MESSAGE_POLL",
						this.context.language
					);
					message =
						lastMessage.receiverType === CometChat.RECEIVER_TYPE.GROUP
							? `${sender} ${pollMessage}`
							: `${pollMessage}`;
				}
				break;
			case enums.CUSTOM_TYPE_STICKER:
				{
					const stickerMessage = Translator.translate(
						"CUSTOM_MESSAGE_STICKER",
						this.context.language
					);
					message =
						lastMessage.receiverType === CometChat.RECEIVER_TYPE.GROUP
							? `${sender} ${stickerMessage}`
							: `${stickerMessage}`;
				}
				break;
			case enums.CUSTOM_TYPE_DOCUMENT:
				{
					const docMessage = Translator.translate(
						"CUSTOM_MESSAGE_DOCUMENT",
						this.context.language
					);
					message =
						lastMessage.receiverType === CometChat.RECEIVER_TYPE.GROUP
							? `${sender} ${docMessage}`
							: `${docMessage}`;
				}
				break;
			case enums.CUSTOM_TYPE_WHITEBOARD:
				{
					const whiteboardMessage = Translator.translate(
						"CUSTOM_MESSAGE_WHITEBOARD",
						this.context.language
					);
					message =
						lastMessage.receiverType === CometChat.RECEIVER_TYPE.GROUP
							? `${sender} ${whiteboardMessage}`
							: `${whiteboardMessage}`;
				}
				break;
			case enums.CUSTOM_TYPE_MEETING:
				{
					const meetingMessage = Translator.translate(
						"VIDEO_CALL",
						this.context.language
					);
					message = `${sender} ${meetingMessage}`;
				}
				break;
			default:
				break;
		}
		return message;
	};

	getTextMessage = (message) => {
		let messageText = message.text;

		//xss extensions data
		const xssData = checkMessageForExtensionsData(message, "xss-filter");
		if (xssData && xssData.hasOwnProperty("sanitized_text")) {
			messageText = xssData.sanitized_text;
		}

		//datamasking extensions data
		const maskedData = checkMessageForExtensionsData(message, "data-masking");
		if (
			maskedData &&
			maskedData.hasOwnProperty("data") &&
			maskedData.data.hasOwnProperty("sensitive_data") &&
			maskedData.data.hasOwnProperty("message_masked") &&
			maskedData.data.sensitive_data === "yes"
		) {
			messageText = maskedData.data.message_masked;
		}

		//profanity extensions data
		const profaneData = checkMessageForExtensionsData(
			message,
			"profanity-filter"
		);
		if (
			profaneData &&
			profaneData.hasOwnProperty("profanity") &&
			profaneData.hasOwnProperty("message_clean") &&
			profaneData.profanity === "yes"
		) {
			messageText = profaneData.message_clean;
		}

		return messageText;
	};

	getMessage = (lastMessage) => {
		const senderName = this.getSenderName(lastMessage);
		const messageType = this.getMessageType(lastMessage);
	  
		const message =
		  lastMessage.receiverType === CometChat.RECEIVER_TYPE.GROUP
			? `${senderName} ${messageType}`
			: `${messageType}`;
	  
		return message;
	  };
	  
	  getSenderName = (lastMessage) => {
		const loggedInUserUid = this.props?.loggedInUser?.uid;
		const senderUid = lastMessage?.sender?.uid;
	  
		return loggedInUserUid !== senderUid ? `${lastMessage?.sender?.name}: ` : "";
	  };
	  
	  getMessageType = (lastMessage) => {
		switch (lastMessage.type) {
		  case CometChat.MESSAGE_TYPE.TEXT:
			return this.getTextMessageType(lastMessage);
		  case CometChat.MESSAGE_TYPE.MEDIA:
			return Translator.translate("MEDIA_MESSAGE", this.context.language);
		  case CometChat.MESSAGE_TYPE.IMAGE:
			return Translator.translate("MESSAGE_IMAGE", this.context.language);
		  case CometChat.MESSAGE_TYPE.FILE:
			return Translator.translate("MESSAGE_FILE", this.context.language);
		  case CometChat.MESSAGE_TYPE.VIDEO:
			return Translator.translate("MESSAGE_VIDEO", this.context.language);
		  case CometChat.MESSAGE_TYPE.AUDIO:
			return Translator.translate("MESSAGE_AUDIO", this.context.language);
		  case CometChat.MESSAGE_TYPE.CUSTOM:
			return Translator.translate("CUSTOM_MESSAGE", this.context.language);
		  default:
			return "";
		}
	  };
	  
	  getTextMessageType = (lastMessage) => {
		return this.getTextMessage(lastMessage);
	  };	  

	getCallMessage = (lastMessage) => {
		let message = null;
		const sender =
			this.props.loggedInUser.uid !== lastMessage.sender.uid
				? `${lastMessage.sender.name}: `
				: ``;

		switch (lastMessage.type) {
			case CometChat.MESSAGE_TYPE.VIDEO:
				{
					const videoMessage = Translator.translate(
						"VIDEO_CALL",
						this.context.language
					);
					message =
						lastMessage.receiverType === CometChat.RECEIVER_TYPE.GROUP
							? `${sender} ${videoMessage}`
							: `${videoMessage}`;
				}
				break;
			case CometChat.MESSAGE_TYPE.AUDIO:
				{
					const audioMessage = Translator.translate(
						"AUDIO_CALL",
						this.context.language
					);
					message =
						lastMessage.receiverType === CometChat.RECEIVER_TYPE.GROUP
							? `${sender} ${audioMessage}`
							: `${audioMessage}`;
				}
				break;
			default:
				break;
		}

		return message;
	};

	getActionMessage = (message) => {
		if (
		  !message.hasOwnProperty("actionBy") ||
		  !message.hasOwnProperty("actionOn") ||
		  !message.actionBy.hasOwnProperty("name") ||
		  !message.actionOn.hasOwnProperty("name")
		) {
		  return null;
		}
	  
		const byEntity = message.actionBy;
		const onEntity = message.actionOn;
		const byString = byEntity.name;
		const forString =
		  message.action !== CometChat.ACTION_TYPE.MEMBER_JOINED &&
		  message.action !== CometChat.ACTION_TYPE.MEMBER_LEFT
			? onEntity.name
			: "";
	  
		switch (message.action) {
		  case CometChat.ACTION_TYPE.MEMBER_ADDED:
			return this.generateActionMessage(
			  byString,
			  "ADDED",
			  forString,
			  this.context.language
			);
		  case CometChat.ACTION_TYPE.MEMBER_JOINED:
			return this.generateActionMessage(
			  byString,
			  "JOINED",
			  "",
			  this.context.language
			);
		  case CometChat.ACTION_TYPE.MEMBER_LEFT:
			return this.generateActionMessage(
			  byString,
			  "LEFT",
			  "",
			  this.context.language
			);
		  case CometChat.ACTION_TYPE.MEMBER_KICKED:
			return this.generateActionMessage(
			  byString,
			  "KICKED",
			  forString,
			  this.context.language
			);
		  case CometChat.ACTION_TYPE.MEMBER_BANNED:
			return this.generateActionMessage(
			  byString,
			  "BANNED",
			  forString,
			  this.context.language
			);
		  case CometChat.ACTION_TYPE.MEMBER_UNBANNED:
			return this.generateActionMessage(
			  byString,
			  "UNBANNED",
			  forString,
			  this.context.language
			);
		  case CometChat.ACTION_TYPE.MEMBER_SCOPE_CHANGED: {
			if (
			  message.hasOwnProperty("data") &&
			  message.data.hasOwnProperty("extras") &&
			  message.data.extras.hasOwnProperty("scope") &&
			  message.data.extras.scope.hasOwnProperty("new")
			) {
			  const newScope = message.data.extras.scope.new;
			  return this.generateActionMessage(
				byString,
				"MADE",
				`${forString} ${Translator.translate(newScope, this.context.language)}`,
				this.context.language
			  );
			}
			break;
		  }
		  default:
			break;
		}
	  
		return null;
	  };
	  
	  generateActionMessage = (byString, actionType, forString, language) => {
		return `${byString} ${Translator.translate(
		  actionType,
		  language
		)} ${forString}`;
	  };
	  

	toggleTooltip = (event, flag) => {
		const elem = event.target;

		const scrollWidth = elem.scrollWidth;
		const clientWidth = elem.clientWidth;

		if (scrollWidth <= clientWidth) {
			return false;
		}

		if (flag) {
			elem.setAttribute("title", elem.textContent);
		} else {
			elem.removeAttribute("title");
		}
	};

	enableUnreadCount = () => {
		this.context.FeatureRestriction.isUnreadCountEnabled()
			.then((response) => {
				/**
				 * Don't update state if the response has the same value
				 */
				if (response !== this.state.enableUnreadCount) {
					this.setState({ enableUnreadCount: response });
				}
			})
			.catch((error) => {
				if (this.state.enableUnreadCount !== false) {
					this.setState({ enableUnreadCount: false });
				}
			});
	};

	enableHideDeletedMessages = () => {
		this.context.FeatureRestriction.isHideDeletedMessagesEnabled()
			.then((response) => {
				if (response !== this.state.enableHideDeletedMessages) {
					this.setState({ enableHideDeletedMessages: response });
				}
			})
			.catch((error) => {
				if (this.state.enableHideDeletedMessages !== false) {
					this.setState({ enableHideDeletedMessages: false });
				}
			});
	};

	handleMouseHover = (toggleFlag) => {
		if (toggleFlag && !this.state.isHovering) {
			this.setState({ isHovering: true });
		} else if (!toggleFlag && this.state.isHovering) {
			this.setState({ isHovering: false });
		}
	};

	// Customizable Area End
	render() {
		// Customizable Area Start
		let presence = null;
		if (
			this.props.conversation.conversationType === CometChat.RECEIVER_TYPE.USER
		) {
			const status = this.props.conversation.conversationWith.status;
			presence = (
				<CometChatUserPresence
					status={status}
					borderColor={this.props.theme.borderColor.primary}
				/>
			);
		}

		let avatar = null;
		if (
			this.props.conversation.conversationType === CometChat.RECEIVER_TYPE.USER
		) {
			avatar = (
				<CometChatAvatar user={this.props.conversation.conversationWith} />
			);
		} else if (
			this.props.conversation.conversationType === CometChat.RECEIVER_TYPE.GROUP
		) {
			avatar = (
				<CometChatAvatar group={this.props.conversation.conversationWith} />
			);
		}

		let unreadCount = null;
		if (this.state.enableUnreadCount) {
			unreadCount = (
				<CometChatBadgeCount
					count={this.props.conversation.unreadMessageCount}
				/>
			);
		}

		let toolTipView = null;
		if (this.state.isHovering) {
			toolTipView = (
				<CometChatConversationListActions
					{...this.props}
					conversation={this.props.conversation}
				/>
			);
		}
	const handleName = (user) =>{
		const event = new CustomEvent('NOTIFICATION_ADD', { 
			detail: {
			  title:user.name,
			  status:user.status === 'online'
			}
		  });
		  window.dispatchEvent(event);
	}
		return (
			<div
				css={listItem(this.props)}
				className='list__item'
				onMouseEnter={() => this.handleMouseHover(true)}
				onMouseLeave={() => this.handleMouseHover(true)}
				onClick={() => {this.props.handleClick(this.props?.conversation)
					handleName(this.props?.conversation?.conversationWith)}}
			>
				<div css={itemThumbnailStyle()} className='list__item__thumbnail'>
					{avatar}
					{presence}
				</div>
				<div
					css={itemDetailStyle()}
					className='list__item__details'
					dir={Translator.getDirection(this.context.language)}
				>
					<div css={itemRowStyle()} className='item__details_block_one'>
						<div
							css={itemNameStyle(this.props)}
							className='item__details__name'
							onMouseEnter={(event) => this.toggleTooltip(event, true)}
							onMouseLeave={(event) => this.toggleTooltip(event, false)}
						>
							{this.props.conversation.conversationWith.name}
						</div>
					</div>
					<div css={itemRowStyle()} className='item__details_block_two'>
						<div
							css={itemLastMsgStyle(this.props)}
							className='item__details__last-message'
							onMouseEnter={(event) => this.toggleTooltip(event, true)}
							onMouseLeave={(event) => this.toggleTooltip(event, false)}
						>
						</div>
						{unreadCount}
					</div>
				</div>
				{toolTipView}
			</div>
		);
		// Customizable Area End
	}
}
// Customizable Area Start
// Specifies the default values for props:
CometChatConversationListItem.defaultProps = {
	theme: theme,
	loggedInUser: null,
	conversation: {
		conversationWith: {},
	},
};

CometChatConversationListItem.propTypes = {
	theme: PropTypes.object,
	loggedInUser: PropTypes.shape(CometChat.User),
	conversation: PropTypes.shape(CometChat.Conversation),
};

export { CometChatConversationListItem };
// Customizable Area End
