import React, { useEffect, useState } from "react";
// Customizable Area Start
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
  Link,
  Box
} from "@material-ui/core";
import { Menu as MenuIcon, ArrowBackIos } from "@material-ui/icons";
import {
  makeStyles,
  useTheme,
  Theme,
  createTheme
} from "@material-ui/core/styles";
import _ from "lodash";
import MobileNavItems from "./MobileNavItems.web";
import PinModal from "../../blocks/dashboard/src/SeekHelpModule/PinModal.web";
const navigationImg = require("./assets");
import { Link as LinkDash } from 'react-router-dom';
import "./nav.css";
let config = require("../../framework/src/config");
// Customizable Area End
const theme = createTheme({
  // Customizable Area Start
  palette: {
    primary: {
      main: "#000000"
    },
    secondary: {
      main: "#000000"
    }
  }
  // Customizable Area End
});

const useStyles = makeStyles((theme: Theme) => ({
  // Customizable Area Start
  root: {
    backgroundColor: "#f9f9f9"
  },
  appBarContainer: {
    height:"96px",
    display:"flex",
    justifyContent:"center",
    padding:"0px 15px 0px 30px",
    boxShadow: "none",
    backgroundColor: "#F0F4F6",
    backgroundImage: `url(${navigationImg.left}),url(${navigationImg.right})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left ,top right",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#052B62"
  },
  logoWrapper:{
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down("md")]: {
      flexGrow: 1,
      justifyContent: 'space-between'
    },
  },
  mainLogoWrapper: {
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
  },
  logoimageMobile: {
    height: 40,
    margin: "auto",
    transform: "translateX(-30px)",
    cursor: "pointer"
  },
  logoimagePin:{
    height: 35,
    margin: "3px 0px 0px 0px",
    paddingRight: "5px",
    cursor: "pointer" 
  },
  title: {
    flexGrow: 1
  },
  menuContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  flexNavItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  flexNavItemSeek: {
    flexGrow:1,
    [theme.breakpoints.down("sm")]: {
      flexGrow: "inherit"
    }
  },
  arrowBack: { position: 'relative', left: '5px', color: "white" },
  arrowIcon: { backgroundColor: "#1067B5", padding: "0.6rem", marginTop: "10px", borderRadius: "5px" },
  header: { display: "flex", gap: '10px', padding: '5px', alignItems: "center", justifyContent: 'flex-start' },
  userDataName: {
    color: "#1067B5",
    marginTop: "5px",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  onlineIcon: {
    backgroundColor: "#32A52D",
    border: "none",
    position: "relative",
    top: "2px",
    height: "20px",
    width: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  NavItem: {
    padding: "0 10px",
    "&:last-child": {
      padding: "0 0"
    }
  },
  linkOptinal: {
    cursor: "pointer",
    fontWeight: 500,
    padding: "0 15px",
    fontSize: "15px",
    position: "relative",
    background: "-webkit-linear-gradient(45deg, #0076E0 0%, #003651 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent"
  },
  link: {
    cursor: "pointer",
    padding: "0 15px",
    fontSize: "15px",
    position: "relative",
    background: "-webkit-linear-gradient(45deg, #0076E0 0%, #003651 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: 600,
    display: "block",
    "&::after": {
      content: '" "',
      background: "transparent",

      position: "absolute",
      width: "96%",
      display: "block",
      height: "50px",
      border: " solid 4px #000",
      borderColor: "#084B91 transparent transparent transparent",
      borderRadius: "173px/100px 100px 0 0",
      left: "0"
    }
  },
  active: {
    cursor: "pointer",

    background: "-webkit-linear-gradient(48.57deg, #09549F 0%, #000428 94.5%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "18px"
  },
  loginBtn: {
    fontSize: "12px",
    background: "#052B62",
    borderRadius: "5px",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      background: "#052B62"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  loginBtnInMenu: {
    fontSize: "12px",
    background: "#052B62",
    borderRadius: "5px",
    color: "#fff",
    textTransform: "capitalize",
    "&:hover": {
      background: "#052B62"
    }
  },
  socialBox: {
    display: "flex",
    alignItems: "center",
    lineHeight: "10px",
    [theme.breakpoints.down("md")]: {
      flexGrow: 1,
      justifyContent: "flex-end"
    },
    [theme.breakpoints.down("sm")]: {
      order: 3,
    }
  },
  iconsSpace: {
    paddingRight: "10px"
  },
  socialIcons: {
    padding: "0 5px",
    textDecoration: "none"
  },
  navLinks: {
    color: "#212121",
    textDecoration: "none"
  },
  subMenuItem: {
    fontSize: "16px"
  },
  subMenuIcon: {
    paddingLeft: "10px"
  },
  subMenu: {
    borderRadius: "5px"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  subNested: {
    paddingLeft: theme.spacing(10)
  },
  navbarImageLogo:{
    minWidth: "18%",
    [theme.breakpoints.down("sm")]: {
      order: 2,
    }
  }
  // Customizable Area End
}));
// Customizable Area Start
const MenuNames = [
  {
    title: "Home",
    location: "/"
  },
  {
    title: "About Us",
    location: "/aboutus"
  },
  {
    title: "Programs",
    location: "/programs"
  },
  {
    title: "Resources",
    location: "/resources",
  },
  {
    title: "Partnership",
    location: "/partnership"
  },
  {
    title: "Contact Us",
    location: "/#contact-us"
  }
];
const programSubMenus = [
  "/programs",
  "/programs/parent",
  "/programs/educator",
  "/programs/custom",
  "/programs/bud",
  "/programs/bloom",
  "/programs/blossom"
];
const seekHelpHeader = [
  '/student/seek-help',
  '/trainer/queries',
  '/parent/assistance',
];

const socialIcon = [
  { link: "https://www.instagram.com/hapchiin/", socialImage: navigationImg.instagram },
  {
    link: "https://www.linkedin.com/company/33300252/admin/",
    socialImage: navigationImg.linkedin
  }
];
// Customizable Area End
function Navigation() {
  // Customizable Area Start
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openPinMOdal, setOpenPinModal] = useState(false);
  const [userNameData,setUserNameData] = useState('')
  const [userStatus,setUserStatus] = useState(false);

  const openPin = () => {
    setOpenPinModal(true)
  }

  const closePin = () => {
    setOpenPinModal(false)
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [subAnchorEl, setSubAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [subMenuIsOpen, setSubMenuIsOpen] = React.useState("none");
  const [isAdmin, setIsAdmin] = React.useState(false);

  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    handleSubMenuClose();
  };

  const handleSubMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setSubAnchorEl(event.currentTarget);
    setSubMenuIsOpen("");
  };
  const handleSubMenuClose = () => {
    setSubAnchorEl(null);
    setSubMenuIsOpen("none");
  };
  React.useEffect(() => {
    function watchScroll() {
      window.addEventListener("scroll", handleSubMenuClose);
    }
    watchScroll();
    return () => {
      window.removeEventListener("scroll", handleSubMenuClose);
    };
  });


  React.useEffect(() => {
    let search = window.location.search;
   let params = new URLSearchParams(search);
   let admin_id = params.get('admin_id');
   setIsAdmin(!!admin_id)
   
  },[]);

  const activePath = [
    `/details/${window.location.pathname.split('/').pop()}`
  ]
  function handleAddNotification(e: any) {
    setUserNameData(e?.detail?.title)
    setUserStatus(e?.detail.status) 
  }
  
  window.addEventListener('NOTIFICATION_ADD', handleAddNotification);

  return (
    <>
      <AppBar position="static" className={classes.appBarContainer}>
          <Toolbar
            style={{
              padding: "0",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            {isMobile &&  !seekHelpHeader.includes(window.location.pathname) ? (
              <>
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenu}
                >
                  <MenuIcon />
                </IconButton>
                <img
                  src={navigationImg.hapchiLogo}
                  alt="Logo"
                  className={classes.logoimageMobile}
                />
                <Link href={"/LoginForm"}>
                  <Button className={classes.loginBtn}>
                    Sign In
                  </Button>
                </Link>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                  }}
                  open={open}
                  onClose={handleClose}
                >
                  <MobileNavItems handleClose={handleClose} />
                  <MenuItem>
                  <Link href={"/LoginForm"}>
                    <Button fullWidth className={classes.loginBtnInMenu}>
                      Sign In
                    </Button>
                    </Link>
                  </MenuItem>
                  <MenuItem>
                    <Box className={classes.iconsSpace}>
                      { seekHelpHeader.includes(window.location.pathname)
                        ?
                        <>
                          {/* <img
                            src={navigationImg.pinIcon}
                            alt="Logo"
                            className={(window.location.pathname === "/student/seek-help" ||
                            window.location.pathname === "/trainer/queries")?classes.logoimagePin:classes.logoimageMobile}
                            onClick={openPin}
                          /> */}
                        </>
                        : <>
                          {socialIcon.map((val, index) => {
                            return (
                              <Link
                                target="_blank"
                                href={val.link}
                                key={index}
                                className={classes.socialIcons}
                              >
                                {" "}
                                <img
                                  src={val.socialImage}
                                  width="18px"
                                  height="18px"
                                />
                              </Link>
                            );
                          })}
                        </>
                      }

                      
                    </Box>
                  </MenuItem>
                </Menu>
              </>
            ) : (
             
                <Box className={classes.menuContainer}>
                  <Box className={classes.logoWrapper}>
                    <Box className={classes.mainLogoWrapper}>
                        <img
                            src={navigationImg.logo}
                            alt="Logo"
                            />
                    </Box>
                    <Box className={ seekHelpHeader.includes(window.location.pathname) ? classes.flexNavItemSeek : classes.flexNavItem}>
                      {
                        seekHelpHeader.includes(window.location.pathname) ?
                          
                            <div className={classes.header}
                          
                            >
                              <div className={classes.arrowIcon}>
                                <span
                                  className={classes.arrowBack}>
                                {isAdmin?<a href={config.baseURL}><ArrowBackIos style={{ color: 'white' }} /></a> :<LinkDash to={'/dashboard'}> <ArrowBackIos style={{ color: 'white' }} /></LinkDash>}
                                </span>
                              </div>
                            {
                              userNameData ?  
                              <>
                              <div className={classes.userDataName}>@ {userNameData}</div>
                              { userStatus && (
                                <div className={classes.onlineIcon} ></div>
                              )}</>
                              : null
                            }
                            </div>
                        
                          : MenuNames.map((val, index) => {
                            return (
                              <Box key={val.title}>
                                {val.title === "Programs" ? (
                                  <div>
                                    <Box
                                      onMouseOver={handleMenu}
                                    >
                                      <Link
                                        className={classes.active}
                                        color="primary"
                                        href={val.location}
                                      >
                                        <Typography
                                          className={
                                            programSubMenus.includes(location.pathname)
                                              ? classes.link
                                              : classes.linkOptinal
                                          }
                                        >
                                          {" "}
                                          {val.title}
                                        </Typography>
                                      </Link>
                                    </Box>
                                    <Box onMouseLeave={handleClose}>
                                      <Menu
                                        onWheel={handleClose}
                                        MenuListProps={{ onMouseLeave: handleClose }}
                                        id="simple-menu"
                                        className={classes.subMenu}
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}

                                        getContentAnchorEl={null}
                                        anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left"
                                        }}
                                        disableScrollLock={true}
                                      >


                                        <MenuItem
                                        onMouseEnter={handleSubMenuClick}
                                          aria-controls="simple-menu"
                                          aria-haspopup="true"
                                          onClick={handleSubMenuClick}
                                          className={`${classes.subMenuItem} ${classes.active
                                            }`}
                                          color="primary"
                                          
                                        >
                                          School Safety Program{" "}
                                          <span className={classes.subMenuIcon}>
                                            <img src={navigationImg.subMenuArrow} />
                                          </span>
                                          
                                          </MenuItem> 
                                          <div style={{position:"absolute", top:"0", left:"243px", background:"white", width:"max-content", padding:"0.5em", display: `${subMenuIsOpen}`}}>
                                            <div style={{padding:"0.5em"}}><Link
                                                className={`${classes.subMenuItem} ${classes.active
                                                  }`}
                                                color="primary"
                                                href="/programs/bud"
                                              >
                                                Bud [3 to 8 Years]
                                              </Link></div>
                                              <div style={{padding:"0.5em"}}><Link
                                                className={`${classes.subMenuItem} ${classes.active
                                                  }`}
                                                color="primary"
                                                href="/programs/bloom"
                                              >
                                                Bloom [8 to 11 Years]
                                              </Link></div>
                                              <div style={{padding:"0.5em"}}><Link
                                                className={`${classes.subMenuItem} ${classes.active
                                                  }`}
                                                color="primary"
                                                href="/programs/blossom"
                                                >
                                                  Blossom [11 to 16 years]
                                              </Link></div>
                                          
                                          </div>                           
                                          <MenuItem onMouseEnter={handleSubMenuClose} onClick={handleClose}>
                                          <Link
                                            className={`${classes.subMenuItem} ${classes.active
                                              }`}
                                            color="primary"
                                            href="/programs"
                                          >
                                            Digital Platform
                                          </Link>
                                        </MenuItem>

                                        <MenuItem onClick={handleClose}>
                                          <Link
                                            className={`${classes.subMenuItem} ${classes.active
                                              }`}
                                            color="primary"
                                            href="/programs/parent"
                                          >
                                            Parents Program{" "}
                                          </Link>
                                        </MenuItem>

                                        <MenuItem onClick={handleClose}>
                                          <Link
                                            className={`${classes.subMenuItem} ${classes.active
                                              }`}
                                            color="primary"
                                            href="/programs/educator"
                                          >
                                            Educators Program{" "}
                                          </Link>
                                        </MenuItem>
                                        <MenuItem onClick={handleClose}>
                                          <Link
                                            className={`${classes.subMenuItem} ${classes.active
                                              }`}
                                            color="primary"
                                            href="/programs/custom"
                                          >
                                            Create Your Own Program
                                          </Link>
                                        </MenuItem>

                                      </Menu>

                                    </Box>
                                  </div>
                                ) : (
                                  <Link
                                    className={classes.active}
                                    color="primary"
                                    href={val.location}
                                  >
                                    <Typography
                                      className={
                                        val.location === location.pathname || (activePath.includes(location.pathname) && val.title === "Shop")
                                          ? classes.link
                                          : classes.linkOptinal
                                      }
                                    >
                                      {" "}
                                      {val.title}
                                    </Typography>
                                  </Link>
                                )}
                              </Box>
                            );
                          })}
                    </Box>
                  </Box>
                  <Box className={classes.socialBox}>
                    <Box className={classes.iconsSpace}>
                      {
                         seekHelpHeader.includes(window.location.pathname) ? <>
                          <img
                            src={navigationImg.pinIcon}
                            alt="Logo"
                            className={ classes.logoimagePin }
                            onClick={openPin}
                          />
                        </>
                          : socialIcon.map((val, index) => {
                            return (
                              <Link
                                target="_blank"
                                href={val.link}
                                key={index}
                                className={classes.socialIcons}
                              >
                                {" "}
                                <img
                                  src={val.socialImage}
                                  width="18px"
                                  height="18px"
                                />
                              </Link>
                            );
                          })}
                    </Box>

                    { 
                      ( seekHelpHeader.includes(window.location.pathname)) ?
                        <>
                        </>
                        :
                        <Link href={"/LoginForm"}>
                          <Button className={classes.loginBtn}>Sign In</Button>
                        </Link>
                    }

                  </Box>
                </Box>
            
            )}
          </Toolbar>
      </AppBar>
      <PinModal open={openPinMOdal} onClose={closePin} lastMsg={""} />
    </>
  );
  // Customizable Area End
}

export default Navigation;