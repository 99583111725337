import React from 'react'
// Customizable Area Start
// Customizable Area End
const configJSON = require("../../config.js");

// Customizable Area Start
// Customizable Area End

const VideoViewMaterial = () => {
  // Customizable Area Start
  const urlParams = new URLSearchParams(window.location.search);
  const videoUrl: any = urlParams.get(configJSON.Material.VideoUrl);
  return <video autoPlay={true} width="100%" height="480" controls>
    <source
      type={configJSON.Material.VideoMp4}
      src={videoUrl}
    />
  </video>
  // Customizable Area End
}

export default VideoViewMaterial